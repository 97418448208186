import * as angular from 'angular';
import * as moment from "moment";
import {saveAs} from 'file-saver';
import * as $ from "jquery";
import {scanStationCtrlObj} from './ScanStationCtrlObj';
import {ColourScheme} from './ColourScheme.interface';
import {DataFiltering} from './DataFiltering.interface';
import {AllDataInterface} from './AllData.interface';
import FieldRulesToAdd from "../../components/field-rule/model/fieldRulesToAdd";
import SignaturePad from 'signature_pad/src/signature_pad';
import {type} from "os";
import {trigger} from "@angular/animations";

'use strict';

angular.module('scanStationCtrl')
    .controller('scanStationController', ScanStationController);

ScanStationController.$inject = ['$scope', 'scanStation', 'Auth', '$state', '$templateCache', '$window',
    '$interval', '$filter', 'User', '$sce', 'Data', 'FilterByFieldService', 'ScanStationService',
    'PieChartField', 'InvalidEmailExceptionFactory', 'fieldParameterFactory', 'DataFromStationFieldsFactory',
    'fieldService', 'fieldValidation', 'SuperuserService', '$rootScope', 'Configuration', 'RolesAndPermissionsFactory',
    'fieldRuleValidationFactory', 'FormFactory', 'CrossBlocDataUpdatesFieldFactory',
    'status', 'SubBlocFieldFactory', 'LinkFieldFactory', 'MindeeFactory', 'MailAlertFactory',
    'FileBuilderFactory', 'FormValidator', 'MessageService', 'DateService', 'pageState', 'BlocworxGatewayFactory', 'ScanStationHelperFactory',
    'ExternalBlocDataFactory', 'linkField', 'SCS', 'AppSettings', 'MathsFactoryBLCXAU', '$timeout', 'GridService', 'ResultsBoxService', 'AngularScanStationService', '$compile'];

function ScanStationController($scope, scanStation, Auth, $state, $templateCache, $window,
                               $interval, $filter, User, $sce, Data, FilterByFieldService, ScanStationService,
                               PieChartField, InvalidEmailExceptionFactory, fieldParameterFactory, DataFromStationFieldsFactory,
                               fieldService, fieldValidation, SuperuserService, $rootScope, Configuration, RolesAndPermissionsFactory,
                               fieldRuleValidationFactory, FormFactory, CrossBlocDataUpdatesFieldFactory,
                               status, SubBlocFieldFactory, LinkFieldFactory, MindeeFactory, MailAlertFactory,
                               FileBuilderFactory, FormValidator, MessageService, DateService, pageState, BlocworxGatewayFactory, ScanStationHelperFactory,
                               ExternalBlocDataFactory, linkField, SCS, AppSettings, MathsFactoryBLCXAU, $timeout, GridService, ResultsBoxService, AngularScanStationService, $compile) {

    let vm = this;

    vm.$onInit = async function () {
        vm.appSettings = AppSettings;

        vm.maxTimeoutOnScanStation = 1000;
        MessageService.new();
        vm.linkDataService = linkField;
        vm.updateLaterOn = undefined;
        vm.reloadData = false;
        vm.auth = Auth;
        vm.fieldService = fieldService;
        vm.userData = await vm.auth.isLoggedIn();
        vm.scanStationService = scanStation;
        vm.scs = SCS;
        vm.id = $state.params.stationId;
        vm.jobID = $state.params.id;
        vm.stateParams = $state.params;
        vm.modalStatus = 'off';
        vm.dataToAdd = {};
        vm.filesToAdd = {};
        vm.form = FormValidator;
        vm.prompt = false;
        vm.blocErrorMessage = false;
        vm.showErrorMessage = false;
        vm.yellowPrompt = false;
        vm.focusFormFirst = false;
        vm.focusFormSecond = false;
        vm.submitForm = false;
        vm.preventFormSubmission = false;
        vm.focusWhere = '';
        vm.error = false;
        vm.showUndo = false;
        vm.updating = false;
        vm.takePhotoStatus = 'closed';
        vm.stateName = $state.current.name;
        vm.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
        vm.submittedStatus = 1;
        vm.hiddenDataToPopulate = {};
        vm.updating = false;
        vm.userSearchKey = [];
        vm.returnedStationID = false;
        vm.userEmail = "";
        vm.showAlertFieldPopUp = false;
        vm.scanStationEditMode = false;
        vm.increaseTimeFieldInterval = false;
        vm.totalsField = {};
        vm.timeDiffFields = {};


        vm.resultBoxArray = {};
        vm.resultBoxHeaderData = {};
        vm.resultBoxData = {};


        vm.subformArray = {};
        vm.dataToFormArray = [];
        vm.domIndexes = {};
        vm.filterByFields = {};
        vm.preventRuleCall = [];
        vm.uniqueModuleSlug = $state.params.uniqueModuleSlug;
        vm.currentSelectedFieldParameters = {};
        vm.tokenRef = localStorage.getItem('satellizer_token');
        vm.fieldIdToUpdateOrder = [];

        vm.scanStationObj = new scanStationCtrlObj();
        vm.scanStationObj.andGateParamArray = {};
        vm.scanStationObj.showDatePickerField = [];
        vm.scanStationObj.showDateInput = [];
        vm.scanStationObj.id = $state.params.stationId;
        vm.scanStationObj.jobID = $state.params.id;
        vm.scanStationObj.stateParams = $state.params;
        vm.scanStationObj.stateName = $state.current.name;
        vm.scanStationObj.cartolyticsCustomerID = $window.localStorage.getItem('cartolyticsCustomerID');
        vm.scanStationObj.restarted = false;

        vm.currentTarget = false;

        vm.fieldRulesToAdd = [];
        vm.editNewRuleCase = false;
        vm.fieldRulesToDelete = [];
        vm.existingFileRulesDisplay = [];

        vm.showFormceptionPopup = false;

        vm.fieldsToDelete = [];
        vm.fieldsToUpdate = [];

        vm.scanStationObj.selectedSOPFiles = [];

        vm.scanStationObj.selectedRolesForLoadingUsers = [];

        vm.stationDataForDropDown = [];

        vm.searchResult = [];

        vm.showMoreDataBox = false;
        vm.scanStationFieldDesignOptions = [];
        vm.scanStationObj.selectedRolesForLoadingUsers = [];
        vm.actions = false;
        vm.editRule = false;
        vm.superUser = SuperuserService;
        let randomValue = Configuration.getRandomValue();
        let version = Configuration.getVersion();
        vm.editFieldsUrl = 'views/bloc-editor-html-files/edit-create-fields.html?v=' + version + 'rand=' + randomValue;
        vm.fieldRulesParameters = 'views/bloc-editor-html-files/field-rules-parameters.html?v=' + version + 'rand=' + randomValue;
        vm.gridPreview = 'views/bloc-editor-html-files/grid-preview.html?v=' + version + 'rand=' + randomValue;
        vm.mainBlocSettings = 'views/bloc-editor-html-files/main-bloc-settings.html?v=' + version + 'rand=' + randomValue;
        vm.uniqueSlugs = 'views/bloc-editor-html-files/unique-slugs.html?v=' + version + 'rand=' + randomValue;
        vm.blocLayoutAndVisibilitySettings = 'views/bloc-editor-html-files/bloc-layout-and-visibility-settings.html?v=' + version + 'rand=' + randomValue;
        vm.dataDisplaySettings = 'views/bloc-editor-html-files/data-display-settings.html?v=' + version + 'rand=' + randomValue;
        vm.manageBlocScheduler = 'views/bloc-editor-html-files/manage-bloc-scheduler.html?v=' + version + 'rand=' + randomValue;
        vm.userRestrictedDataSettings = 'views/bloc-editor-html-files/user-restricted-data-settings.html?v=' + version + 'rand=' + randomValue;
        vm.mailAlertSettings = 'views/bloc-editor-html-files/mail-alert-settings.html?v=' + version + 'rand=' + randomValue;
        vm.mailAlertSettings = 'views/bloc-editor-html-files/mail-alert-settings.html?v=' + version + 'rand=' + randomValue;
        vm.manageDocExportTemplates = 'views/bloc-editor-html-files/manage-doc-export-templates.html?v=' + version + 'rand=' + randomValue;
        vm.manageDataUploadingSettings = 'views/bloc-editor-html-files/manage-data-uploading-settings.html?v=' + version + 'rand=' + randomValue;
        vm.userRoleAccess = 'views/bloc-editor-html-files/user-role-access.html?v=' + version + 'rand=' + randomValue;
        vm.specialSystemBlocFeatures = 'views/bloc-editor-html-files/special-system-bloc-features.html?v=' + version + 'rand=' + randomValue;
        vm.revisionsAndDuplicates = 'views/bloc-editor-html-files/revisions-and-duplicates.html?v=' + version + 'rand=' + randomValue;
        vm.functionalBehaviourSettings = 'views/bloc-editor-html-files/functional-behaviour-settings.html?v=' + version + 'rand=' + randomValue;
        vm.lookupDataSettings = 'views/bloc-editor-html-files/lookup-data-settings.html?v=' + version + 'rand=' + randomValue;
        vm.recordDuplicationSettings = 'views/bloc-editor-html-files/record-duplication-settings.html?v=' + version + 'rand=' + randomValue;
        vm.htmlBlocSettings = 'views/bloc-editor-html-files/html-bloc-settings.html?v=' + version + 'rand=' + randomValue;

        vm.colourSchemeArray = {};

        vm.showLoadMoreData = false;
        vm.showMoreSingleDataBox = false;
        vm.filterData = false;
        vm.specificFieldSearch = false;

        // time picker function & settings
        vm.newDate = new Date();
        vm.date = new Date();

        vm.inlineOptions = {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: false
        };

        vm.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2020, 5, 22),
            minDate: new Date(),
            startingDay: 1
        };

        vm.format = 'dd/MM/yyyy';
        vm.altInputFormats = ['d!/M!/yyyy'];

        vm.popup1 = {
            opened: []
        };

        vm.scanStationObj.parseResultsBoxFieldsArray = {};
        vm.scanStationObj.parseBoxResults = {};
        vm.scanStationObj.parseResultsBoxArray = {};
        vm.removedEmailAddress = [];

        vm.dataAddSuccess = 0;

        vm.dataToFormStations = [];
        vm.dataToFormSearchData = [];
        vm.dataToFormSearchResults = [];
        vm.jsonDataForDataToFormFields = [];

        if (Auth.tokenExists()) {

            await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'main-query');
            await vm.getScanStationRules(vm.scanStationObj.id);

            if (typeof ($state.params.stationDataID) != 'undefined') {
                vm.scanStationObj.stationDataID = $state.params.stationDataID;
                vm.scanStationObj.isInEditMode = true;
                vm.scanStationObj.isInViewMode = false;
            } else {
                vm.scanStationObj.isInEditMode = false;
                vm.scanStationObj.isInViewMode = false;
            }

            if (vm.stateName === 'module-edit-form' || vm.scanStationObj.stateName === 'module-form' || vm.scanStationObj.stateName === 'bloc') {
                let refresh = true;
                vm.scanStationObj.jobID = vm.jobID = await Data.getStaticJobID($state.params.uniqueModuleSlug, refresh);
            }

            // TODO tidy this up, most states are not being used anymore
            if ((typeof ($state.params.stationDataID) === 'undefined' && vm.stateName === 'scan-station')
                || vm.stateName === 'module-form' || (vm.stateName === 'bloc' && $state.params.stationDataID == null) || vm.stateName == 'edit-bloc' || (vm.stateName === 'scan-station' && $state.params.stationDataID == null) || vm.stateName === 'scan-station-with-data') {

                // if we have a filtering query in place we have no reason to ever call getData, especially not when the bloc is during the loading period
                // TODO we need an official function to determine when our bloc is in a state of filtering
                if (vm.dataFilteringData == null || (vm.dataFilteringData != null && ((vm.dataFilteringData.filterDataToQuery != null && vm.dataFilteringData.filterDataToQuery.length == 0) || vm.dataFilteringData.filterDataToQuery == null))) {
                    await vm.getData(vm.scanStationObj.id);
                }

                if (vm.scanStationObj.stationDetails.single_entry_form === 1
                    || vm.scanStationObj.stationDetails.populate_latest_data === 1
                    || vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields === 1) {

                    if (vm.scanStationObj.dataToAdd == null) {
                        vm.scanStationObj.dataToAdd = {};
                    }

                    await vm.getDataFromLatestRecord();
                }

                if (vm.scanStationObj.stationDetails.uses_automatic_saving === 1) {
                    if (vm.scanStationObj.liveData != null && vm.scanStationObj.liveData[0] != null && vm.scanStationObj.liveData[0].submitted_status == 0) {
                        await vm.getDataFromLatestRecord();
                    }
                }

                vm.scanStationEditMode = false;
            } else {
                try {
                    vm.scanStationEditMode = true;
                    let singleEntryExistingData = await scanStation.getSingleDataEntryForStationEdit($state.params.stationDataID,
                        vm.stateParams.publicFormKey, vm.stateParams.publicFormValue);

                    vm.singleEntryExistingData = singleEntryExistingData.data.data;

                    angular.forEach(vm.scanStationObj.scanStationFields, function(value) {
                        vm.scanStationObj.dataToAdd[value.field_slug] = vm.singleEntryExistingData[value.field_slug];
                    });

                    if (!$scope.$$phase) {$scope.$applyAsync();};

                } catch (e) {
                    console.log(e);
                }
            }

            await vm.getStationUsers(vm.scanStationObj.id);
            await vm.getSOPSignedForUser();

            if (vm.scanStationObj.stateName === 'scan-station' || vm.scanStationObj.stateName === 'public-form') {
                document.querySelector('video').addEventListener('play', function() {
                });
            }

            vm.toggleMin();

            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            let afterTomorrow = new Date();
            afterTomorrow.setDate(tomorrow.getDate() + 1);
            vm.events = [
                {
                    date: tomorrow,
                    status: 'full'
                },
                {
                    date: afterTomorrow,
                    status: 'partially'
                }
            ];

            /**
             * TODO: Replace this
             * @deprecated: Hardcoded code here
             */
            if (vm.scanStationObj.jobID == 815) {
                vm.troyUserDataForDropDown = [];
                vm.troyUserData = [];
                vm.troySearchResult = [];
            }

            if (!vm.checkInIFrame()) {
                vm.scanStationObj.jsonFields = $state.params.jsonFields;
            }

            await vm.getAntiStaticSignedForUser();

            $scope.$on('savingDataBroadcast', function () {
                vm.savingData = Data.savingData;
            });

            $scope.$on('dataForDataFilteringCount', function () {
                if (vm.filterData) {
                    vm.upperLimit = Data.updatedData;
                }
            });

            $('input').on('focusin', (item: any) => {
                vm.currentTarget = item.currentTarget.id;
            });
        }

        if (vm.scanStationObj.stationDetails.showPopUpWhenExitingBloc && vm.scanStationObj.stateName != 'edit-bloc') {
            pageState.startEventListenersForPageChange();
        }

        if (vm.scanStationObj.stateName == 'html-bloc') {
            vm.compileCustomHtml();
        }

        const urlParams = new URLSearchParams(window.location.search);
        const recordId = urlParams.get('recordId');
        const scanStationId = urlParams.get('scanStationId');

        // If both parameters exist in the URL, open the record for editing (they will exist if user clicks icon from results box)
        if (recordId && scanStationId) {
            await vm.autoShowMoreRecord(recordId);
            console.log("Opened show more")
        } else if (recordId) {
            await vm.autoEditRecord(recordId);
            console.log("Opened editing")
        }
    };

    /**
     * Function is called when user clicks icon to open record for editing on results box.
     * Allows to edit record.
     *
     * @param recordId
     */
    vm.autoEditRecord = async function (recordId) {
        try {
            vm.scanStationObj.isInEditMode = true;

            const rowIndex = null;
            const currentRow = null;

            await vm.setFieldsEditEntryApply(currentRow, rowIndex, recordId);

        } catch (error) {
            console.error("Error fetching record:", error);

        }
    };

    /**
     * Function is called when user clicks icon to open record for editing on results box.
     * Allows to view record under "show more"
     *
     * @param recordId
     */
    vm.autoShowMoreRecord = async function (recordId) {
        try {
            vm.scanStationObj.isInViewMode = false;
            vm.scanStationObj.showForm = true;
            vm.scanStationObj.isInEditMode = false;
            vm.showMoreSingleDataBox = true;

            const rowIndex = null;
            const currentRow = null;

            await vm.setFieldsEditEntryApply(currentRow, rowIndex, recordId);

        } catch (error) {
            console.error("Error fetching record:", error);

        }
    };

    /**
     * This will validate if an input is a json or not.
     *
     * @param input
     */
    vm.isJson = (input) => {
        try {
            JSON.parse(input);
            return true;
        } catch (error) {
            return false;
        }
    }


    /**
     * This method will be responsible for triggering the background data update, that
     * will be checked the submittedStatus argument on the ScanStation or ScanStationObject.
     * @param dataToAdd
     * @param scanStationID
     * @param data
     */
    vm.triggerBackgroundDataUpdate = async function (dataToAdd, scanStationID, data) {
        try {
            if (vm.scanStationObj.isInEditMode != true && data != null) {
                if (vm.scanStationObj.stationDetails.uses_automatic_saving === 1) {
                    vm.submittedStatus = 0;
                    vm.scanStationObj.submittedStatus = 0;
                    await vm.AddData(vm.scanStationObj.dataToAdd, scanStationID);
                }
            }

        } catch (e) {
            console.log(e);
        }
    };

    vm.checkIfUserExists = async function (dataToAdd) {
        try {
            let userEmail = '';
            vm.userExists = false;

            if (vm.stationDetails.is_profile_station === 1 || vm.scanStationObj.stationDetails.is_profile_station === 1) {

                angular.forEach(vm.scanStationObj.scanStationFields, function (stationFields) {
                    if ((stationFields.field_type === 'cartolytics-add-user-email')) {
                        angular.forEach(dataToAdd, function (data, dataIndex) {
                            if (dataIndex === stationFields.field_slug && stationFields.field_type === 'cartolytics-add-user-email') {
                                userEmail = data;
                            }
                        })
                    }
                });

                let response = await User.checkIfUserExists(userEmail);
                if (response.data.result) {
                    alert("The email is already registered");
                    vm.userExists = true;
                    return true;
                }

            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.buildRuleDataToCheck = async () => {
        angular.forEach(vm.scanStationObj.scanStationFields, async function (field, index) {
            if (field.status == 1) {

                // remove fields that are not supposed to be checked due to the conditional visibility

                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, field);

                let isPreventedDuringEdit = (vm.scanStationObj.isInEditMode == true && vm.scanStationObj.scanStationFieldIdsToField[field.id] != null && vm.scanStationObj.scanStationFieldIdsToField[field.id].preventActionsFromTriggeringWhileEditing == true);

                if ((isVisibleFromShowCondition || field.conditionalVisibilityVisualOnly == 'yes') && !isPreventedDuringEdit) {

                    let data = (field.field_type === 'file' && vm.scanStationObj.filesToAdd[field.field_slug] !== undefined)
                        ? vm.scanStationObj.filesToAdd[field.field_slug]['name']
                        : vm.scanStationObj.dataToAdd[field.field_slug];

                    vm.ruleDataToCheck.push({
                        field_id: field.id,
                        data: data,
                        index: index
                    });
                }
            }
        });
    }


    vm.triggerRulesAndWaitFor1Second = async function (field) {
        return new Promise(resolve => {
            vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            setTimeout(() => {
                resolve('1 seconds');
            }, 100);
        });
    }

    /**
     * This will be responsible to control the action of save data into a scan station.
     * @param data
     */
    vm.saveButtonAction = async (data) => {
        try {

            if (vm.filterData === true) {
                vm.filterDataOptions = data.dataFilteringData || null;
            }

            // This is the scan station data
            let scanStationData = {
                scanStationID: vm.scanStationObj.id,
                dataToAdd: vm.scanStationObj.dataToAdd,
                jobID: vm.scanStationObj.jobID
            };

            // This will validate the form
            await vm.form.validate(scanStationData);

            // This will run only if the form is validated!
            await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, vm.scanStationObj.id);

        } catch (exception) {
            console.log(exception);

            // Serialize the entire exception safely
            const serializedException = JSON.stringify(exception, Object.getOwnPropertyNames(exception));

            // Store exception with timestamp in local storage
            const errorLog = {
                timestamp: new Date().toISOString(),
                exception: serializedException
            };

            if (exception.data && exception.data.errors) {
                exception.data.errors.forEach(function (error) {
                    MessageService.addErrorMessage(error);
                });
            }
        }

    };

    vm.checkAllRulesThenAddData = async function (dataToAdd, scanStationID) {
        try {
            vm.ruleDataToCheck = [];
            await vm.buildRuleDataToCheck();

            if (vm.scanStationObj.isInEditMode != true) {
                vm.scanStationObj.stationDataID = null;
            }

            let allFieldsData = vm.getAllFieldsData();

            console.log(allFieldsData);

            await scanStation.getMultipleRules(vm.ruleDataToCheck, vm.scanStationObj.stationDataID, allFieldsData);

            await vm.AddData(dataToAdd, scanStationID);

            vm.ruleCheckingInProgress = false;

            status.successfulScan();

            $rootScope.$broadcast('cleanMindeeFiles', null);

            vm.scanStationObj.showForm = (vm.scanStationObj.stationDetails.closeFormAfterSubmit == 1) ? false : true;

        } catch (res) {

            vm.scrollTop();
            console.log(res)
            let metaData = res.data.metaData;
            let responseData = res.data.responseData;
            let failedResponseType = res.data.failedResponseType;


            if (metaData !== undefined && metaData.populateOtherFieldDataFromRuleFailure != 'undefined') {
                angular.forEach(metaData.populateOtherFieldDataFromRuleFailure, function (value, index) {
                    if (typeof vm.scanStationObj.scanStationFieldIdsToField[index] != 'undefined') {
                        vm.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                        vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                    }
                });
            }

            if (metaData !== undefined && metaData.populateOtherFieldDataFromRulePassing != 'undefined') {
                angular.forEach(metaData.populateOtherFieldDataFromRulePassing, function (value, index) {
                    if (typeof vm.scanStationObj.scanStationFieldIdsToField[index] != 'undefined') {
                        vm.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                        vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[index].field_slug] = value;
                    }
                });
            }

            if (responseData !== undefined && responseData.populate_fields != 'undefined') {
                angular.forEach(responseData.populate_fields, function (value, index) {
                    vm.dataToAdd[index] = value;
                    vm.scanStationObj.dataToAdd[index] = value;
                });
            }

            if (failedResponseType !== null) {
                if (failedResponseType == 'Red Warning Reject') {
                    vm.prompt = res.data.error;
                    let failedFieldId = res.data.failedFieldRule.field_id;
                    let fieldIndex = vm.scanStationObj.scanStationFieldIdsToField[failedFieldId].fieldIndex;
                    await vm.triggerPrompt(fieldIndex, res.data.failedFieldRule.prevent_clearing_value_on_failure);
                } else {
                    if (failedResponseType == 'Yellow Warning Accept') {
                        vm.yellowPrompt = res.data.error;
                        let failedFieldId = res.data.failedFieldRule.field_id;
                        let fieldIndex = vm.scanStationObj.scanStationFieldIdsToField[failedFieldId].fieldIndex;
                        await vm.triggerYellowPrompt(fieldIndex, true);
                    }
                }
            }

        } finally {
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    };

    /**
     * This will be looping though the scan station fields, it will check if the
     * soundFieldToCheck exist and contain value = 1 (meaning it is turned it on), otherwise
     * it will be returning false.
     *
     * @param soundFieldToCheck
     */
    vm.shouldPlaySound = (soundFieldToCheck) => {

        let play = false;

        angular.forEach(vm.scanStationObj.scanStationFields, function (field, key) {
            if (field[soundFieldToCheck] !== undefined && field[soundFieldToCheck] === 1) {
                play = true
            }
        });
        return play;
    }

    vm.setTransitionScreenData = function () {
        try {
            if ((typeof (vm.scanStationObj.stationDataID) === 'undefined'
                && vm.stateName === 'scan-station') || vm.stateName === 'module-form' || vm.stateName == 'bloc') {
                vm.savingDataMsg = 'Submitting Data';
            } else {
                vm.savingDataMsg = 'Updating Data';
            }
            if (vm.scanStationObj.stationDetails.popup_on_data_submission === 1) {
                // FIXME: This sounds wrong
                vm.savingDataMsg = vm.savingDataMsg;
            }


        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This will be responsible to process an action of adding data, and this will
     * be prevented on AddData.
     *
     * @param dataToAdd
     * @param scanStationID
     */
    vm.processData = async (dataToAdd, scanStationID) => {

        for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
            let field = vm.scanStationObj.scanStationFields[i];
            // this has a similar name to triggerRulesBeforeSubmissionOfForm, but this is correctly happening before the
            // form submits, the other is happening after, there is a change that needs to be done for the one later on to rename it

            if (field.triggerRulesBeforeProcessSubmitData == 1) {
                await vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }
        }

        let metaData;
        vm.savingData = true;
        vm.setTransitionScreenData();
        vm.returnedStationID = false;
        metaData = null;

        if (vm.scanStationObj.isInEditMode) {
            metaData = {};
            metaData.stationDataID = vm.scanStationObj.stationDataID;
        }

        // when editing data inside the results

        if (vm.scanStationObj.inDataEditRowId != null) {
            metaData = {};
            metaData.stationDataID = vm.scanStationObj.inDataEditRowId;
        }


        // Process Mail Alert Fields on Submit

        if (vm.scanStationObj.mailAlertFieldsOnSubmit.length > 0 && !vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let mailAlertFieldsOnSubmitToKeep = [];

            angular.forEach(vm.scanStationObj.mailAlertFieldsOnSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    mailAlertFieldsOnSubmitToKeep.push(fieldId);
                }
            });

            if (mailAlertFieldsOnSubmitToKeep.length > 0) {
                let actionResponses = await MailAlertFactory.triggerActions(vm.scanStationObj.id, mailAlertFieldsOnSubmitToKeep, dataToAdd);
            }

        }

        // Process Blocworx Gateway Fields on Submit

        if (vm.scanStationObj.blocworxGatewayFieldsOnSubmit.length > 0 && !vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let blocworxGatewayFieldsOnSubmitToKeep = [];

            angular.forEach(vm.scanStationObj.blocworxGatewayFieldsOnSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    blocworxGatewayFieldsOnSubmitToKeep.push(fieldId);
                }
            });

            let blocworxGatewayFieldsOnSubmitToKeepLength = blocworxGatewayFieldsOnSubmitToKeep.length;

            for (let i = 0; i < blocworxGatewayFieldsOnSubmitToKeepLength; i++) {
                let response = await BlocworxGatewayFactory.triggerActions(blocworxGatewayFieldsOnSubmitToKeep[i], vm.scanStationObj.dataToAdd, vm.scanStationObj.filesToAdd);
                $rootScope.$broadcast('triggerPostBlocworxGatewayCall', {
                    fieldId: blocworxGatewayFieldsOnSubmitToKeep[i],
                    response: response
                });
                // we need to force a delay to allow the rules to process
                // TODO find a better way to call component controllers from this controller but also have async/await, $broadcast doesnt have this.
                await scanStation.delay(1000);
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }


        }

        // Process Mail Alerts Fields When Editing
        if (vm.scanStationObj.mailAlertFieldsWhenEditing.length > 0 && vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let mailAlertFieldsWhenEditingToKeep = [];

            angular.forEach(vm.scanStationObj.mailAlertFieldsWhenEditing, function (fieldId, index) {
                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    mailAlertFieldsWhenEditingToKeep.push(fieldId);
                }
            });

            if (mailAlertFieldsWhenEditingToKeep.length > 0) {
                let actionResponses = await MailAlertFactory.triggerActions(vm.scanStationObj.id, mailAlertFieldsWhenEditingToKeep, dataToAdd);
            }

        }

        // Process Blocworx Gateway Fields When Editing
        if (vm.scanStationObj.blocworxGatewayFieldsWhenEditing.length > 0 && vm.scanStationObj.isInEditMode) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let blocworxGatewayFieldsWhenEditingToKeep = [];

            angular.forEach(vm.scanStationObj.blocworxGatewayFieldsWhenEditing, function (fieldId, index) {
                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    blocworxGatewayFieldsWhenEditingToKeep.push(fieldId);
                }
            });

            let blocworxGatewayFieldsWhenEditingToKeepLength = blocworxGatewayFieldsWhenEditingToKeep.length;

            for (let i = 0; i < blocworxGatewayFieldsWhenEditingToKeepLength; i++) {
                let response = await BlocworxGatewayFactory.triggerActions(blocworxGatewayFieldsWhenEditingToKeep[i], vm.scanStationObj.dataToAdd, vm.scanStationObj.filesToAdd);
                $scope.$broadcast('triggerPostBlocworxGatewayCall', {
                    fieldId: blocworxGatewayFieldsWhenEditingToKeep[i],
                    response: response
                });
                // TODO find a better way to call component controllers from this controller but also have async/await, $broadcast doesnt have this.
                await scanStation.delay(1000);
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

        }

        // we still need the full dataset for things like cross blocs etc after submitting
        let dataToAddCopy = JSON.parse(JSON.stringify(dataToAdd));

        // strip data from dataToAdd where have decided not to save it
        angular.forEach(vm.scanStationObj.fieldsThatDontSaveData, function (fieldSlug, index) {
            delete dataToAdd[fieldSlug];
        });

        let data = await scanStation.AddData(dataToAdd, scanStationID, 1, vm.scanStationObj.jobID, vm.scanStationObj.filesToAdd, vm.scanStationObj.submittedStatus, metaData);

        // process Cross Bloc Data Fields on Submit
        if (vm.scanStationObj.crossBlocDataUpdateFieldsOnDataSubmit.length > 0) {

            // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
            // want to apply the action or not

            let crossBlocDataUpdateFieldsOnDataSubmitToKeep = [];
            angular.forEach(vm.scanStationObj.crossBlocDataUpdateFieldsOnDataSubmit, function (fieldId, index) {
                let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                if (isVisibleFromShowCondition) {
                    crossBlocDataUpdateFieldsOnDataSubmitToKeep.push(fieldId);
                }
            });

            let responseData = [];
            let actionResponses = await CrossBlocDataUpdatesFieldFactory.triggerActions(crossBlocDataUpdateFieldsOnDataSubmitToKeep, dataToAddCopy, data.data.returnedStationID);
            angular.forEach(crossBlocDataUpdateFieldsOnDataSubmitToKeep, function (fieldId) {
                responseData[vm.scanStationObj.scanStationFieldIdsToField[fieldId].field_slug] = actionResponses.data.data['fieldId_' + fieldId];
            });
        }


        if (Object.keys(data.data.autogenerateReplacements).length > 0) {
            AngularScanStationService.applyAutogenerateReplacements(data.data.autogenerateReplacements);
        }

        if(vm.scanStationObj.fileBuilderFields.length > 0) {
            $rootScope.$broadcast('addedScanStationData', {
                scanStationID: scanStationID,
                scanStationDataID: data.data.returnedStationID,
                dataToAdd: dataToAdd,
                jobID: vm.scanStationObj.jobID
            });
        }

        vm.savingData = false;

        // This is the place that will be changing to submitted status to 1, meaning that was successful
        // added the data, so we can also remove css and messages with jquery
        if (vm.scanStationObj.submittedStatus === 1) {
            await vm.setDataAddSuccess(1);
            $('*').removeClass('rule-broken');
            $('.error-box').remove()
        }


        if (vm.scanStationObj.inDataEditRowId == null) {
            vm.returnedStationID = data.data.returnedStationID;
            // if we are in a pop up we've no reason to get the data after saving
            if (vm.gridPopUpObject != null && vm.gridPopUpObject.popUpOrEmbedded == 'popup') {
                // do nothing
            } else {
                if (vm.filterData) {
                    await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
                } else {
                    await vm.getData(scanStationID);
                }
            }
        }

        let dataNowResetted = false

        if (vm.scanStationObj.submittedStatus === 1) {
            vm.confirmSubmit = false;
            vm.addNew = false;

            // no need to reset data for single entry forms or if we are editing data based on a single entry ID
            await vm.setDataAddSuccess(1);


            if (vm.scanStationObj.stationDetails.single_entry_form === 0) {
                vm.scanStationObj.stationDataID = undefined;
                await vm.resetData();
                dataNowResetted = true;
            }

            // Fix for the revert edit cant be possible
            if (vm.scanStationObj.showUndo !== undefined) {
                vm.showUndo = vm.scanStationObj.showUndo;
            } else {
                vm.showUndo = true;
            }

            if (vm.scanStationObj.isInEditMode != true) {
                $('.image-preview').each(function () {
                    $(this).attr('src', '');
                });
            }

            if (vm.scanStationObj.inDataEditRowId == null) {
                $(vm.fieldToFocus).trigger('focus');
            }

            vm.scanStationObj.inDataEditRowId = null;

            $('html, body').animate({
                //   scrollTop: "0px"
            }, 0);
            console.log(vm.scanStationObj.stationDetails)

            if(vm.scanStationObj.isInEditMode == true) {
                alert("Record has been updated")
            }

            if (vm.scanStationObj.stationDetails.popup_on_data_submission === 1) {
                if (vm.scanStationObj.stationDetails.popup_on_data_submission_message == null || vm.scanStationObj.stationDetails.popup_on_data_submission_message == '') {
                    alert('Form Successfully Submitted');
                } else {
                    alert(vm.scanStationObj.stationDetails.popup_on_data_submission_message);
                }
            }

            // this is a redirect to another bloc if the details are passed in via the url

            if ($state.params.redirectToUniqueModuleSlug != null && $state.params.redirectToScanStationId != null) {
                $state.go('bloc', {
                    uniqueModuleSlug: $state.params.redirectToUniqueModuleSlug,
                    stationId: $state.params.redirectToScanStationId
                }, {
                    inherit: false,
                    reload: false
                });
            }

            // this bloc setting redirects the bloc to another url once all processes have finished

            if (vm.scanStationObj.stationDetails.blocRedirectAfterSubmission != null && vm.scanStationObj.stationDetails.blocRedirectAfterSubmission != '') {
                window.location = vm.scanStationObj.stationDetails.blocRedirectAfterSubmission;
            }

            // TODO Resolve misunderstanding of the name of this parameter
            // it is saying triggerRulesBeforeSubmissionOfForm, but its really happening after not before.
            // this is important because we also now have one that really happens before
            for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                let field = vm.scanStationObj.scanStationFields[i];
                if (field.triggerRulesBeforeSubmissionOfForm == 1) {
                    await vm.triggerRulesAndWaitFor1Second(field);
                    if (!$scope.$$phase) {$scope.$applyAsync();};
                }
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};
        }

        if (vm.scanStationObj.isInEditMode === true) {
            vm.scanStationObj.isInEditMode = false;
            if (dataNowResetted === false) {
                await vm.resetData();
            }

        }

        if (vm.scanStationObj.stationDetails.closeFormAfterSubmit == 1) {
            vm.scanStationObj.showForm = false;
        }

        // if we're in a sub bloc then process expected behaviour
        if (vm.inSubBlocOwnerFieldDetails != null) {
            await SubBlocFieldFactory.processAfterSubmitDataBehaviour(vm.inSubBlocOwnerFieldDetails);
        }

        if (vm.showFormceptionPopup && vm.scanStationObj.stationDetails.formception_close_after_submit) {
            await vm.returnScanStation();
        }

        if (vm.scanStationObj.submittedStatus === 1) {
            await vm.hideDataAddSuccessMsg();
        }

        if (!$scope.$$phase) {$scope.$applyAsync();};
    }

    vm.triggerProcessAfterSubmitDataBehaviourFunction = async function () {
        await SubBlocFieldFactory.processManualCloseBehaviour(vm.inSubBlocOwnerFieldDetails);
        if (!$scope.$$phase) {$scope.$applyAsync();};
    }


    /**
     * Method that will trigger the action of add a new data,
     * we have implemented a formFactory that will ensure
     * that one only request will be done per time.
     *
     * @param dataToAdd
     * @param scanStationID
     * @constructor
     */
    vm.AddData = async function (dataToAdd, scanStationID) {
        try {
            await FormFactory.send(vm.processData, [dataToAdd, scanStationID]);
        } catch (e) {
            console.log(e);
            vm.savingData = false;
            if (typeof (e.data) != 'undefined') {
                vm.prompt = e.data.error;
            } else {
                vm.prompt = 'Error in system, please contact administrator or developer.';
            }
        }

    };

    vm.disableEnableField = async function (index, status) {
        try {
            vm.scanStationObj.scanStationFields[index].status = status;
            await vm.updateScanStation('disable-enable-field');
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Main method that will be updating data for a scan station, this is being used
     * wide in the system, as a main function to make sure that scan station have
     * all the requirements before change anything.
     *
     * @param queryType
     */
    vm.updateScanStation = async function (queryType) {
        try {
            await scanStation.updateScanningStation(
                vm.scanStationObj.jobID,
                vm.scanStationObj.scanStationFields,
                vm.fieldsToDelete,
                vm.scanStationObj.id,
                vm.fieldRulesToAdd,
                vm.fieldRulesToDelete,
                vm.scanStationObj.selectedSOPFiles,
                vm.stationUnits,
                vm.editNewRuleCase
            );

            vm.fieldRulesToAdd = [];
            vm.fieldRulesToDelete = [];
            vm.fieldsToDelete = [];

            if (queryType != 'prevent-field-rebuild') {
                await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'main-query');
            }

            await vm.getScanStationRules(vm.scanStationObj.id);

            if (!$scope.$$phase) {$scope.$applyAsync();};


        } catch (e) {
            // TODO: find a way to reload the page when this happens: location.reload();
            console.log(e);
            vm.error = e.data !== null ? e.data.error : e;
            console.log("%c%s", "color:red; font-size:15px", e.data.error);
            if (e.data.error && e.data.error.startsWith('This')) {
                vm.fieldsToDelete = [];
                alert(e.data.error)
            } else {
                alert('An error has occurred, please check browser console to read error.');
            }
        }
    };

    /**
     * This will be responsible to check if exist a first key ['0'], if so
     * it will be cleaning the field localFieldValue.
     */
    vm.cleanLocalFieldMatchingFieldPairs = (localFieldMatchingFieldPairs) => {
        if (Object.keys(localFieldMatchingFieldPairs).length >= 1) {
            if (localFieldMatchingFieldPairs[0].localFieldValue !== undefined) {
                localFieldMatchingFieldPairs[0].localFieldValue = ''
            }
        }
    }

    vm.resetData = async function () {

        vm.fieldsToRetriggerRules = {};
        vm.allowActionsAfterShowMore = true;
        vm.scanStationObj.dataToAddDisplayOnly = {};

        // we will always reset this so in any reset situation the autogeneration fields are starting fresh

        vm.scanStationObj.previousDataToAddForAutoGenerate = [];

        // We are looping though the scan station object that contain fields, and doing some data clean
        angular.forEach(vm.scanStationObj.scanStationFields, async function (scanStationFieldValue, scanStationFieldIndex) {

            vm.scanStationObj.restarted = true;
            vm.preventRuleCall[scanStationFieldValue.field_slug] = true;
            vm.filesToAdd = {};
            vm.scanStationObj.filesToAdd = {};

            // by default will reset things, but a bunch of criteria we can avoid to reset
            let canReset = true;
            let inArray = vm.scanStationObj.fieldIDsThatKeepData !== undefined ? vm.scanStationObj.fieldIDsThatKeepData.indexOf(scanStationFieldValue.id) : -1;
            let fieldShowCondition = scanStationFieldValue.field_show_condition;

            if (inArray != -1 && fieldShowCondition == null) {
                canReset = false;
                vm.fieldToFocus = '#station-field-' + (scanStationFieldIndex + 1);
                vm.hasKeptData = true;
                vm.fieldsToRetriggerRules[scanStationFieldValue.field_slug] = {
                    'data': vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug],
                    'domIndex': vm.domIndexes[scanStationFieldValue.field_slug],
                    'field_id': scanStationFieldValue.id,
                    'special_field_key': scanStationFieldValue.special_field_key
                };
            }

            // TODO: this can refactored to a function
            if (fieldShowCondition != null) {

                // this show that if you have keepDataOnSubmit can reset became false
                if (typeof (fieldShowCondition.keepDataOnSubmit) != 'undefined' && fieldShowCondition.keepDataOnSubmit === 1) {
                    canReset = false;
                }
                if (typeof (fieldShowCondition.keepDataOnSubmit) != 'undefined' && fieldShowCondition.keepDataOnSubmit == 0) {
                    canReset = true;
                }
            }

            if (scanStationFieldValue.field_type == 'use-select') {
                vm.dataToAdd[scanStationFieldValue.field_slug] = [];
                vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = [];
                canReset = false;
            }

            if (vm.scanStationObj.isInEditMode) {
                /** TODO see why this was here
                 * canReset = false
                 */
            }

            if (scanStationFieldValue.field_type == 'logged-in-user-info') {

                vm.loadUserDetails(scanStationFieldValue);

                canReset = false;

            }
            // TODO: this can refactored to a function


            if (canReset == true) {

                if (scanStationFieldValue.field_type == 'results-box') {
                    await ResultsBoxService.callTriggerClearResultsBox(scanStationFieldValue.field_slug);
                }

                // As we are on the "can reset" case, we can assume to delete data from the multi button
                if (scanStationFieldValue.field_type == 'multi-button') {

                    // Cleaning all buttons with class .selected
                    $('.form-scan-station-view #scanStationForm-' + scanStationFieldValue.id + ' button.selected').removeClass('selected');

                    // making sure that the dataToAdd is also clean for this field_slug
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.defaultButtonValue ? scanStationFieldValue.defaultButtonValue : ''

                    // we add the selected class again for default values
                    if (scanStationFieldValue.defaultButtonValue) {
                        $(".form-scan-station-view #scanStationForm-" + scanStationFieldValue.id + " button[data-field-buttonValue='" + scanStationFieldValue.defaultButtonValue + "']").addClass('selected');
                    }
                }

                angular.forEach(vm.scanStationObj.dropdownFilteredDataFields, function (dropdownFilteredDataFieldValue) {

                    if (dropdownFilteredDataFieldValue[2] == scanStationFieldValue.field_slug && vm.scanStationObj.scanStationFieldSlugsToField[scanStationFieldValue.field_slug].loadDropdownFieldsOnce != 1) {
                        vm.stationDataForDropDown[dropdownFilteredDataFieldValue[0]] = {};
                    }
                });

                if (scanStationFieldValue.field_type == 'checkbox-dynamic-checked-value') {
                    vm.scanStationObj.checkboxesWithDynamicDataFields[scanStationFieldValue.field_slug] = 'blocworx-empty';
                }

                if (scanStationFieldValue.field_type == 'digital-signature') {
                    if (typeof (vm.digitalSignatureButtons) != 'undefined') {
                        vm.digitalSignatureButtons[scanStationFieldValue.field_slug] = true;
                    } else {
                        vm.digitalSignatureButtons = [];
                        vm.digitalSignatureButtons[scanStationFieldValue.field_slug] = true;
                    }

                    // TODO: this i am letting in here, as deletiong of it sounds wrong, as we need to clear data afterwards
                    if (scanStationFieldValue.signatureAuthType == 'signature-pad') {
                        vm.signaturePad[scanStationFieldValue.field_slug].clear();
                    }

                }

                if (scanStationFieldValue.field_type == 'checkbox' || scanStationFieldValue.field_type == 'button') {
                    vm.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.pressedUnpressedValues.unPressed;
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.pressedUnpressedValues.unPressed;
                } else if (scanStationFieldValue.field_type == 'plain-data') {
                    vm.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.plainDataFieldValue;
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = null;
                } else if (scanStationFieldValue.field_type == 'multi-button') {
                    if (scanStationFieldValue.defaultButtonValue != null) {
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.defaultButtonValue;
                    }
                } else if (scanStationFieldValue.field_type.includes('auto-generate')) {
                    // TODO: Adrian now we have object destructing, so we can load like this:
                    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
					if(scanStationFieldValue.forceEmptyValueAfterReset == 1) {
						vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = null;
					} else {
						await vm.getAutoGenerateFieldData(scanStationFieldValue, scanStationFieldValue.field_type, scanStationFieldValue.field_slug)
					}
                } else if (scanStationFieldValue.field_type == 'date-time-selector'
                    && (
                        scanStationFieldValue.conditionallyShow == 'show'
                        || scanStationFieldValue.conditionalShowParameters.includes(vm.scanStationObj.dataToAdd[scanStationFieldValue.conditionalShowFieldSlug])
                        && scanStationFieldValue.status == 1
                    )
                ) {
                    if (scanStationFieldValue.dateTimeSetting == 'time') {
                        vm.newDate = new Date();
                        vm.scanStationObj.scanStationTimePickerFields[scanStationFieldValue.field_slug] = vm.newDate;
                        vm.setSeconds(scanStationFieldValue.field_slug);
                        vm.updateTime(scanStationFieldValue.field_slug);
                    }

                    if (scanStationFieldValue.field_data == 'date') {
                        await vm.today(scanStationFieldValue.field_slug);
                        vm.scanStationObj.showDatePickerField[scanStationFieldValue.field_slug] = true;
                        vm.scanStationObj.showDateInput[scanStationFieldValue.field_slug] = false;
                    }
                } else if (scanStationFieldValue.field_type == 'maths') {
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = 'blocworx-unset-maths';
                    vm.dataToAdd[scanStationFieldValue.field_slug] = 'blocworx-unset-maths';
                } else if (scanStationFieldValue.field_type == 'checkbox-buttons-from-station') {

                    // This is cleaning the case of checkboxFromStation
                    vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = {};

                    if (vm.scanStationObj.scanStationFieldSlugsToField[scanStationFieldValue.field_slug].loadDropdownFieldsOnce != 1) {
                        vm.stationDataForDropDown[scanStationFieldValue.field_slug] = [];
                    }

                } else {

                    // Main part that cleans basic fields
                    if (scanStationFieldValue.status == 1) {
                        let defaultValue = scanStationFieldValue.defaultValue;
                        vm.dataToAdd[scanStationFieldValue.field_slug] = defaultValue ? defaultValue : '';
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = defaultValue ? defaultValue : '';
                    } else {
                        vm.dataToAdd[scanStationFieldValue.field_slug] = 'N/A';
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = 'N/A';
                    }

                    // this is the way that a file cleans afterwards
                    if (scanStationFieldValue.field_type == 'file') {
                        let fileElement: HTMLInputElement = document.getElementById('station-field-' + scanStationFieldValue.fieldIndex) as HTMLInputElement;
                        fileElement.value = null;
                        vm.filesToAdd[scanStationFieldValue.field_slug] = {};
                        (<HTMLInputElement>document.getElementById('station-field-' + scanStationFieldValue.fieldIndex)).value = null;
                        vm.scanStationObj.filesToAdd[scanStationFieldValue.field_slug] = {};
                        $('.file-upload-container.' + scanStationFieldValue.field_slug + ' .photo-preview')
                            .css('position', 'fixed');
                    }

                    // adding the conditional for the multi file type
                    if (scanStationFieldValue.field_type == 'multi-file') {
                        let multiFilesElement: HTMLInputElement = document.getElementById('station-field-' + scanStationFieldValue.fieldIndex) as HTMLInputElement;
                        multiFilesElement.value = null;
                        if (vm.scanStationObj.multiFilesToAdd == null) {
                            vm.scanStationObj.multiFilesToAdd = {[scanStationFieldValue.field_slug]: {}};
                        } else {
                            vm.scanStationObj.multiFilesToAdd[scanStationFieldValue.field_slug] = {}
                        }
                    }

                    if (scanStationFieldValue.defaultFieldValue != null) {
                        vm.scanStationObj.dataToAdd[scanStationFieldValue.field_slug] = scanStationFieldValue.defaultFieldValue;
                    }
                }

                // this will be cleaning the linkDataService stored on service.linkData
                if (scanStationFieldValue.field_type == 'link-data-field') {
                    vm.linkedDataFieldJson['key_' + scanStationFieldValue.id] = {};
                }
            }

        });


        if (vm.hasKeptData != true) {
            vm.fieldToFocus = '#station-field-0';
        }

        // override any existing field to focus values if we have set a parameter
        if (typeof vm.priorityFocus != 'undefined') {
            vm.fieldToFocus = vm.priorityFocus;
        }

        angular.forEach(vm.scanStationObj.scanStationFields, function (scanStationFieldValue) {
            vm.preventRuleCall[scanStationFieldValue.field_slug] = false;
        });

        // TODO: check if is necessary to wait for the async function
        await angular.forEach(vm.fieldsToRetriggerRules, async function (field, fieldSlug) {
            vm.preventRuleCall[fieldSlug] = false;
            await vm.getRules('no-event', field.field_id, field.special_field_key, field.data, field.domIndex, fieldSlug);
        });

    }

    /**
     * This function isnt being used, so you must figure it out how to fix using this
     * function, how to update the data from the digest when scan station rule
     * data is changed.
     *
     * @param fieldRules
     * @param fieldId
     * @param updatedParams
     */
    vm.updateScanStationRuleData = function (fieldRules, fieldId, updatedParams) {
        try {
            let array = []
            angular.forEach(fieldRules, function (value) {
                // we only need to update if the value is equal
                if (fieldId === value.field_id) {
                    value.rules_parameters = updatedParams;
                }
                array.push(value);
            });

            return array;

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This method will get the scan station rules
     * by informing the rule id.
     * @param id id of the rule.
     */
    vm.getScanStationRules = async function (id) {
        try {
            let data = await scanStation.getScanStationRules(id);
            vm.fieldRules = data.data.fieldRules;
            vm.fieldIdsWithRules = data.data.fieldIdsWithRules;

            // we need to find the field IDs that keep the data for next scan
            vm.scanStationObj.fieldIDsThatKeepData = [];
            vm.autoGeneratedFieldSlugs = [];
            vm.fieldWithMustBeEqualToAnotherLocalField = [];

            angular.forEach(vm.fieldRules, function (value) {

                // TODO: check this one how we can do this, (talk with ADRIAN)
                if (value.rule_condition_id == 'keep_data_for_next_scan') {
                    vm.scanStationObj.fieldIDsThatKeepData.push(value.field_id);
                }

                if (value.rule_condition_id == 'must_be_equal_to_another_local_field') {
                    vm.fieldWithMustBeEqualToAnotherLocalField.push([
                        value.field_id
                        , value.rules_parameters.param1
                        , value.json_response_data.failureResponseType
                        , value.custom_response
                        , value.field_name
                    ]);
                }
            });


        } catch (e) {
            console.log(e);
        } finally {
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    };

    vm.editFieldRules = async function (field, fieldOrderData, scrollTo = null) {
        try {

            vm.fieldIDForUpdatingRules = field.id;
            vm.fieldSlugforUpdatingRules = field.field_slug;
            vm.initAndGateParams(vm.fieldSlugforUpdatingRules);
            vm.fieldShowConditionForUpdatingRules = field.field_show_condition;
            vm.parentFieldIDForUpdatingRules = field.parent_field_id;
            vm.fieldShowCounter = field.show_counter;
            vm.fieldNextLine = field.field_next_line;
            vm.fieldWidth = field.field_width;
            vm.fieldOrderData = fieldOrderData[0] + ',' + fieldOrderData[1];
            vm.currentSelectedFieldIndex = fieldOrderData[0];
            vm.editFieldRuleName = field.field_name;
            await vm.getRuleData();
            vm.fieldnameForRules = field.field_name;
            vm.showFieldRulesFromSideClick = true;
            vm.fieldHideLabel = field.hide_label;
            vm.fieldAutoComplete = field.autocomplete;
            vm.isStationMetaDataField = field.is_station_meta_data_field;
            vm.hideFromGetDataList = field.hide_from_get_data_list;
            vm.resultCharacterCount = field.result_character_count;

            vm.createFieldVisibilityParameters();

            await vm.getFieldVisibilityOptions(vm.fieldIDForUpdatingRules);
            await vm.getStationStationFieldsForRule(vm.scanStationObj.id);

            if (vm.fieldShowConditionForUpdatingRules != null) {
                if (vm.fieldShowCondition == null) {
                    vm.fieldShowCondition = {};
                }
                vm.fieldShowCondition.field_slug = vm.fieldShowConditionForUpdatingRules.field_slug;
                vm.fieldShowCondition.parameters = vm.fieldShowConditionForUpdatingRules.parameters;
                if (vm.fieldShowConditionForUpdatingRules.keepDataOnSubmit != null) {
                    if (vm.keepDataOnSubmit == null) {
                        vm.keepDataOnSubmit = {};
                    }
                    vm.keepDataOnSubmit.parameters = vm.fieldShowConditionForUpdatingRules.keepDataOnSubmit;
                }
            }

            await vm.getSelectedRolesForFieldID(vm.fieldIDForUpdatingRules);
            vm.fieldHeight = (field.field_height === "auto") ? field.field_height : parseInt(field.field_height);

            // get latest field Parameters
            let latestFieldParameters = await scanStation.getFieldParameters(vm.fieldIDForUpdatingRules);
            field.parameters = latestFieldParameters.data.data;

            if (field.parameters.length > 0) {
                angular.forEach(field.parameters, function (fieldParam) {

                    // TODO: make sure that each value contain the correct type later on in a function
                    // This checks if we have an integer, and we transform to int, so a select option can be selected.
                    let parameterValue = /^[0-9]+$/.test(fieldParam.parameter_value) ? parseInt(fieldParam.parameter_value, 10) : fieldParam.parameter_value;

                    // sometimes the parameters are getting stored with quotes around them. Before UUIDs this was ok because it
                    // new it was an integer, now it doesnt so the quotes get left there, here we strip the quotes if its a UUID
                    const uuidRegexWithQuotes = /^"([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"$/i;

                    if (uuidRegexWithQuotes.test(parameterValue)) {
                        parameterValue = parameterValue.replace(uuidRegexWithQuotes, '$1');
                    }

                    vm.currentSelectedFieldParameters[fieldParam.scan_station_field_parameter] = parameterValue;

                    // TODO come up with better solution for this, this is workaround for V3 deadline
                    if (fieldParam.scan_station_field_parameter == 'multiFieldShowConditions') {
                        vm.multiFieldShowConditionsNew = JSON.parse(angular.copy(fieldParam.parameter_value));
                        vm[fieldParam.scan_station_field_parameter] = [];
                        vm[fieldParam.scan_station_field_parameter][vm.fieldIDForUpdatingRules] = vm.multiFieldShowConditionsNew;
                        delete vm.multiFieldShowConditionsNew;
                    }
                });
            }


            if (!$scope.$$phase) {$scope.$applyAsync();};

            if (scrollTo != null) {
                vm.scrollTo(scrollTo, '.field-rules', true);
            }

        } catch (e) {
            console.log(e);
        }
    };

    vm.createAGFields = function () {
        vm.fieldDataToUpdateTo = {};
    };

    vm.editFieldDetails = async function (fieldID, fieldName, fieldSlug, fieldType, fieldData, fieldParameters) {
        try {

            vm.showFieldUpdatePopUp = true;

            if (typeof vm.newField == 'undefined') {
                vm.newField = {};
            }
            vm.fieldIDForUpdatingRules = fieldID;
            vm.fieldSlugforUpdatingRules = fieldSlug;
            vm.editFieldRuleName = fieldName;
            vm.newField.field_type = fieldType;
            vm.newField.field_name = fieldName;
            vm.newField.field_data = fieldData;
            vm.originalFieldData = fieldData;
            vm.tempHideFieldEditor = true;


        } catch (e) {
            console.log(e);
        }
    };

    vm.updateField = async function (initialFieldParams = null) {
        try {
            vm.updating = true;
            let canUpdate = true;

            let validInitialFieldParams = await fieldValidation.validateInitialFieldParams(vm.newField.field_type, initialFieldParams);

            if (validInitialFieldParams !== true) {
                alert(validInitialFieldParams);
                return false;
            }

            angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                if (value.id != null && value.field_name === vm.newField.field_name && value.id !== vm.fieldIDForUpdatingRules) {
                    canUpdate = false;
                }
            });

            if (canUpdate === true) {

                vm.newField.initialFieldParams = initialFieldParams;
                await scanStation.updateFieldDetails(vm.scanStationObj.id, vm.fieldIDForUpdatingRules,
                    vm.newField.field_name, vm.newField.field_type, vm.newField.field_data, vm.newField.initialFieldParams);

                await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'main-query');

                vm.updating = false;
                vm.showFieldUpdatePopUp = false;
                vm.newField = {};
                vm.newField.field_type = 'text';
                vm.activeField = '';
                vm.modalOff();


                $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);

                if (!$scope.$$phase) {$scope.$applyAsync();};

            } else {
                vm.updating = false;
                alert('Please choose a field name that is already not in this station');
            }

        } catch (e) {
            console.log(e);
            vm.updating = false;
            if (e.data.error) {
                alert(e.data.error);
            } else {
                alert('An error occurred, please check the console in the' + ' browser');
            }
        }

    };

    vm.getRuleData = async function () {
        try {
            vm.ruleResponseErrors = null;
            let data = await scanStation.getRuleData();
            vm.ruleData = data.data;
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    vm.getRuleDetails = function () {
        try {
            let selectedRuleKey = vm.newRuleID;
            if (selectedRuleKey) {
                vm.ruleInstructions = vm.ruleData[selectedRuleKey].rule_instructions;
                vm.ruleDataEntryMedium = vm.ruleData[selectedRuleKey].data_entry_medium;
                vm.selectedRuleID = vm.ruleData[selectedRuleKey].id;
            }

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This function is responsible to send a new rule.
     */
    vm.submitNewRule = async function () {
        try {
            vm.ruleResponseErrors = [];
            let selectedRuleKey = vm.newRuleID;
            let ruleValidationResponse = await fieldRuleValidationFactory.validateRule(vm.ruleData[selectedRuleKey], vm.newRule.rules_parameters);
            if (ruleValidationResponse.length > 0) {
                vm.ruleResponseErrors = ruleValidationResponse;
                return false;
            }

            if (typeof (vm.fieldIDForUpdatingRules) == 'undefined') {
                vm.fieldIDToSend = 0;
            } else {
                vm.fieldIDToSend = vm.fieldIDForUpdatingRules;
            }

            if(vm.conditionallyTriggerFieldId != null && vm.conditionallyTriggerFieldId != ''  && vm.conditionallyTriggerFieldValue != null && vm.conditionallyTriggerFieldValue != ''){
                vm.newRule.conditionallyTriggerRule = [];

                vm.newRule.conditionallyTriggerRule.push({
                    localFieldId: vm.conditionallyTriggerFieldId,
                    field_value: vm.conditionallyTriggerFieldValue
                });
            }

            // for sending to the backend
            vm.fieldRulesToAdd = vm.fieldRulesToAdd.concat([{
                rule_parameters_to_add: vm.newRule.rules_parameters,
                rule_name: vm.ruleData[selectedRuleKey].rule_name,
                field_id: vm.fieldIDToSend,
                response_data: vm.newRule.responseData,
                custom_response: vm.newRule.customResponse,
                conditionally_trigger_rule: vm.newRule.conditionallyTriggerRule,
                prevent_clearing_value_on_failure: vm.newRule.preventClearingValueOnFailure,
                scan_station_id: vm.id,
                rule_condition_id: selectedRuleKey,
                'field_name_for_rules': vm.fieldnameForRules
            }]);

            // reset new rule
            vm.newRule.rules_parameters = {};

            // TODO: Adrian: check how to fix this
            vm.newRule.responseData = {};
            // TODO: Adrian: check how to fix this

            vm.newRule.customResponse = null;
            vm.newRule.preventClearingValueOnFailure = 0;
            await vm.updateScanStation('prevent-field-rebuild');
            await vm.triggerBlocUpdateSuccess('Rule Added');

            vm.scrollTo('add-rules', '.field-rules', true);

        } catch (e) {
            console.log(e);
        } finally {
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    };

    vm.editNewRule = async function (fieldId) {
        try {
            let selectedRuleKey = vm.newRuleID;
            let fieldRuleId = vm.fieldRuleId === undefined ? fieldId : vm.fieldRuleId;

            if (typeof (vm.fieldIDForUpdatingRules) == 'undefined') {
                vm.fieldIDToSend = 0;
            } else {
                vm.fieldIDToSend = vm.fieldIDForUpdatingRules;
            }

            if(vm.conditionallyTriggerFieldId != null && vm.conditionallyTriggerFieldId != ''  && vm.conditionallyTriggerFieldValue != null && vm.conditionallyTriggerFieldValue != ''){
                vm.newRule.conditionallyTriggerRule = [];

                vm.newRule.conditionallyTriggerRule.push({
                    localFieldId: vm.conditionallyTriggerFieldId,
                    field_value: vm.conditionallyTriggerFieldValue
                });
            }

            // for sending to the backend
            let fieldRuleToAdd = new FieldRulesToAdd().build(
                vm.newRule,
                vm.ruleData,
                vm.fieldIDToSend,
                vm.id,
                selectedRuleKey,
                vm.fieldnameForRules,
                fieldRuleId
            );

            vm.fieldRulesToAdd = vm.fieldRulesToAdd.concat([fieldRuleToAdd]);

            await vm.updateScanStation('prevent-field-rebuild');

            // await vm.updateScanStationRuleData(vm.fieldRules, fieldRuleToAdd.field_id, fieldRuleToAdd.rule_parameters_to_add);

            // We could add the thing here to change
            vm.fieldRules = {};
            await vm.getScanStationRules(vm.scanStationObj.id);
            if (!$scope.$$phase) {$scope.$applyAsync();};


        } catch (e) {
            console.log(e);
        } finally {

            // This will reset after running this
            vm.newRule.rules_parameters = {};
            vm.newRule.responseData = {};
            vm.newRule.customResponse = null;
            vm.newRule.preventClearingValueOnFailure = 0;
            vm.newRule.conditionallyTriggerRule = {};
            vm.conditionallyTriggerFieldId = null;
            vm.conditionallyTriggerFieldValue = null;

        }

    };

    vm.updateFieldParameter = async function (fieldID, toUpdate, parameters) {
        try {
            await scanStation.updateFieldParameter(fieldID, toUpdate, parameters);
            await vm.updateScanStation('prevent-field-rebuild');
            $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);
            await vm.triggerBlocUpdateSuccess('Field Parameter Updated');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This is the main point to remove rules from a field,
     * this should delete the selected ruleID and later on
     * organize the order for each rule of this field.
     *
     * @param existingOrNew
     * @param ruleID
     * @param index
     */
    vm.addtoRulesToDelete = async function (existingOrNew, ruleID, index) {
        try {
            if (existingOrNew == 'new_rule') {
                vm.fieldRulesToAdd.splice(index, 1);
            } else {
                //  this will remove the last element of vm.existingFileRulesDisplay
                vm.existingFileRulesDisplay.splice(index, 1);

                vm.fieldRulesToDelete = vm.fieldRulesToDelete.concat([{
                    id: ruleID
                }]);
            }

            await vm.updateScanStation('prevent-field-rebuild');
            await vm.triggerBlocUpdateSuccess('Rule Deleted');


        } catch (e) {
            console.log(e);
        } finally {
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    };

    vm.addScanStationField = async function (initialFieldParams = null) {
        try {
            if (typeof (vm.newField.field_name) == 'undefined') {
                alert('Please Enter a field name before continuing');
                return false;
            }

            let validInitialFieldParams = await fieldValidation.validateInitialFieldParams(vm.newField.field_type, initialFieldParams);

            if (validInitialFieldParams !== true) {
                alert(validInitialFieldParams);
                return false;
            }

            vm.modalOff();

            if (vm.newField.field_type === 'checkbox' || vm.newField.field_type === 'button') {
                if (typeof (initialFieldParams.pressedUnpressedValues) == 'undefined') {
                    initialFieldParams.pressedUnpressedValues.pressed = 1;
                    initialFieldParams.pressedUnpressedValues.unPressed = 0;
                }

                if (typeof initialFieldParams.pressedUnpressedValues.pressed == 'undefined' || initialFieldParams.pressedUnpressedValues.pressed == '') {
                    initialFieldParams.pressedUnpressedValues.pressed = 1;
                }

                if (typeof initialFieldParams.pressedUnpressedValues.unPressed == 'undefined' || initialFieldParams.pressedUnpressedValues.unPressed == '') {
                    initialFieldParams.pressedUnpressedValues.unPressed = 0;
                }
            }

            vm.newField.initialFieldParams = initialFieldParams;

            vm.fieldOrder = vm.scanStationObj.scanStationFields.length + 1;
            vm.scanStationObj.scanStationFields = vm.scanStationObj.scanStationFields.concat(
                [{
                    field_name: vm.newField.field_name,
                    field_data: vm.newField.field_data,
                    field_order: vm.fieldOrder,
                    field_type: vm.newField.field_type,
                    field_next_line: vm.fieldNextLine,
                    field_width: vm.fieldWidth,
                    is_entry_cycle_field: 0,
                    initial_field_params: vm.newField.initialFieldParams,
                    new_field_grid_id: vm.newFieldGridId
                }]);

            await vm.updateScanStation('add-new-field');
            vm.newField = {};
            vm.newField.field_type = 'text';
            // TODO when we've more time investigate a nicer way to call this function using binding rather than a broadcast
            $rootScope.$broadcast('reloadSpecificGrids', [vm.newFieldGridId])
            vm.showAddNewField = false;

            if (!$scope.$$phase) {$scope.$applyAsync();};
            return true;

        } catch (e) {
            console.log(e);
        }
    };

    vm.addToFieldsToDelete = async function (field_slug, index, elementId) {
        try {
            vm.scanStationObj.scanStationFields.splice(index, 1);

            vm.fieldsToDelete = vm.fieldsToDelete.concat([{
                field_slug: field_slug
            }]);

            await vm.updateScanStation('prevent-field-rebuild');
            $rootScope.$broadcast('reloadSpecificGrids', [elementId]);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedSOPFiles = async function (SOPFile) {
        try {
            let SOPFileFileArray = SOPFile.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.scanStationObj.selectedSOPFiles, function (file) {
                if (file.id == SOPFileFileArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.scanStationObj.selectedSOPFiles = vm.scanStationObj.selectedSOPFiles.concat([{
                    id: SOPFileFileArray[0],
                    title: SOPFileFileArray[1]
                }]);
            }

            await vm.updateScanStation('prevent-field-rebuild');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedRoleForLoadingUsers = async function (roleDetails) {
        try {
            let RoleDetailsArray = roleDetails.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.scanStationObj.selectedRolesForLoadingUsers, function (role) {
                if (role.id == RoleDetailsArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.scanStationObj.selectedRolesForLoadingUsers = vm.scanStationObj.selectedRolesForLoadingUsers.concat([{
                    id: RoleDetailsArray[0],
                    title: RoleDetailsArray[1]
                }]);
            }

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.addStationUnit = async function (unit) {
        try {
            if (typeof (vm.stationUnits) == 'undefined' || vm.stationUnits === null) {
                vm.stationUnits = [];
            }
            vm.isInArray = false;
            angular.forEach(vm.stationUnits, function (value) {
                if (value == unit) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.stationUnits.push(unit);
            }

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedSOPFiles = async function (id) {
        try {
            angular.forEach(vm.scanStationObj.selectedSOPFiles, function (file, index) {
                if (file.id == id) {
                    vm.scanStationObj.selectedSOPFiles.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    vm.removeSelectedRolesForLoadingUsers = async function (id) {
        try {
            angular.forEach(vm.scanStationObj.selectedRolesForLoadingUsers, function (role, index) {
                if (role.id == id) {
                    vm.scanStationObj.selectedRolesForLoadingUsers.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.processScanStationFields = async function () {

        if (vm.scanStationObj.scanStationFieldSlugs == null || (vm.scanStationObj.scanStationFieldSlugs != null && !Array.isArray(vm.scanStationObj.scanStationFieldSlugs))) {
            vm.scanStationObj.scanStationFieldSlugs = [];
        }

        // first loop creates some variables that the second loop relies on
        angular.forEach(vm.scanStationObj.scanStationFields, async function (value, index) {
            value.fieldIndex = index;
            vm.scanStationObj.scanStationFieldSlugs.push(value.field_slug);
            vm.scanStationObj.scanStationFieldIDsToSlugs[value.id] = value.field_slug;
            vm.scanStationObj.scanStationFieldIdsToField[value.id] = value;
            vm.scanStationObj.scanStationFieldSlugsToField[value.field_slug] = value;
            if (value.parameters != null && value.parameters.length > 0) {
                angular.forEach(value.parameters, function (fieldParam) {
                    try {
                        value[fieldParam.scan_station_field_parameter] = JSON.parse(fieldParam.parameter_value);
                    } catch (e) {
                        value[fieldParam.scan_station_field_parameter] = fieldParam.parameter_value;
                    }

                    if (fieldParam.scan_station_field_parameter == 'excludeFromGetLatestData' && value[fieldParam.scan_station_field_parameter] == '1') {
                        vm.scanStationObj.fieldsToExcludeFromGetData.push(value.field_slug);
                    }
                });
            }
        });

        // string data getting mixed with int data
        angular.forEach(vm.scanStationObj.scanStationFields, async function (value, index) {

            if (value.parameters != null && value.parameters.length > 0) {

                let builtFieldParameters = await fieldParameterFactory.buildParameterValues(vm.scanStationObj.fieldsToExcludeFromGetData, value);
                vm.scanStationObj.fieldsToExcludeFromGetData = builtFieldParameters.fieldsToExcludeFromGetData;
                value = builtFieldParameters.field;

                angular.forEach(value.parameters, function (parameterValue) {

                    //check if its a primary calendar field
                    if (parameterValue.scan_station_field_parameter == 'primaryCalendarField' && parameterValue.parameter_value == '1') {
                        if (value.field_type == 'auto-generate-date') {
                            vm.scanStationObj.calendarPrimaryFieldFormat = value.dateFormat;
                        } else if (value.field_type == 'date-time-selector' && value.dateTimeSetting == 'date') {
                            vm.scanStationObj.calendarPrimaryFieldFormat = 'DD/MM/YYYY';
                        }
                        if (value.field_type == 'auto-generate-date') {
                            vm.scanStationObj.calendarPrimaryFieldFormat = value.dateFormat;
                        }

                        // when we're performing a calendar date data look up
                        vm.fieldToCheckDateBy = value.field_slug;
                    }
                    if (parameterValue.scan_station_field_parameter == 'andParam') {
                        vm.scanStationObj.andGateParamArray[value.field_slug] = JSON.parse(parameterValue.parameter_value);
                    }

                    if (parameterValue.scan_station_field_parameter == 'filterByField' || parameterValue.scan_station_field_parameter == 'filterByFieldSubBloc') {
                        vm.filterByFields[value.field_slug] = JSON.parse(parameterValue.parameter_value);
                    }

                    if (parameterValue.scan_station_field_parameter == 'populateLatestDataField' && parameterValue.parameter_value == 1) {
                        vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields = 1;
                        vm.scanStationObj.populateLatestDataFields.push(value.field_slug);
                    }

                    if (parameterValue.scan_station_field_parameter == 'doesntSaveData' && parameterValue.parameter_value == 1) {
                        vm.scanStationObj.fieldsThatDontSaveData.push(value.field_slug);
                    }
                });
            }

            if (typeof value.focusAfterSubmission != 'undefined' && value.focusAfterSubmission == 1) {
                vm.priorityFocus = '#station-field-' + index;
            }

            if (value.field_type == 'date-time-selector' && value.numberOfDaysFieldId != null && vm.scanStationObj.scanStationFieldIdsToField[value.numberOfDaysFieldId] != null) {

                let dateToAddFromFieldId = null;

                if (value.dateToAddFromFieldId != null && vm.scanStationObj.scanStationFieldIdsToField[value.dateToAddFromFieldId] != null) {
                    dateToAddFromFieldId = value.dateToAddFromFieldId;
                }

                vm.scanStationObj.fieldIdsThatChangeDates.push({
                    'fieldSlugWithNumberValue': vm.scanStationObj.scanStationFieldIdsToField[value.numberOfDaysFieldId].field_slug,
                    'fieldIdSrc': value.numberOfDaysFieldId,
                    'fieldSlugToChange': value.field_slug,
                    'dateToAddFromFieldId': dateToAddFromFieldId,
                    'triggerIfChanges': vm.scanStationObj.scanStationFieldIdsToField[dateToAddFromFieldId],
                });
            }

            if (value.field_type == 'field-merge-builder') {
                vm.scanStationObj.fieldsUsedForMergeField[value.field_slug] = [
                    value.fieldMergeFormat,
                    vm.getMergeFieldSlugs(value.fieldMergeFormat)
                ];
            }

            if (value.field_type == 'cross-bloc-data-updates' && (value.whenActionHappens != null && value.whenActionHappens == 'on-form-submit')) {
                vm.scanStationObj.crossBlocDataUpdateFieldsOnDataSubmit.push(value.id);
            }

            if (value.field_type == 'cross-bloc-data-updates' && (value.whenActionHappens != null && value.whenActionHappens == 'after-record-deletion')) {
                vm.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterDelete.push(value.id);
            }

            if (value.field_type == 'cross-bloc-data-updates' && (value.whenActionHappens != null && value.whenActionHappens == 'after-results-box-record-deletion' && value.whenActionHappensResultsBoxFieldId != null)) {
                vm.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterResultsBoxRecordDeletion.push({
                    "crossBlocFieldId": value.id,
                    "resultsBoxFieldId": value.whenActionHappensResultsBoxFieldId
                });
            }

            if (value.field_type == 'mail-alert' && (value.applyOnNewSubmission != null && value.applyOnNewSubmission == 1)) {
                vm.scanStationObj.mailAlertFieldsOnSubmit.push(value.id);
            }

            if (value.field_type == 'mail-alert' && (value.applyWhenEditing != null && value.applyWhenEditing == 1)) {
                vm.scanStationObj.mailAlertFieldsWhenEditing.push(value.id);
            }

            if (value.field_type == 'blocworx-gateway' && (value.applyOnNewSubmission != null && value.applyOnNewSubmission == 1)) {
                vm.scanStationObj.blocworxGatewayFieldsOnSubmit.push(value.id);
            }

            if (value.field_type == 'blocworx-gateway' && (value.applyWhenEditing != null && value.applyWhenEditing == 1)) {
                vm.scanStationObj.blocworxGatewayFieldsWhenEditing.push(value.id);
            }

            if (value.field_type == 'link-data-field') {
                vm.scanStationObj.crossBlocDataUpdateFieldsOnDataSubmit.push(value.id);
            }

            if (value.field_type == 'dropdown-from-station' || value.field_type == 'radio-buttons-from-station'
                || value.field_type == 'autocomplete-field' || value.field_type == 'checkbox-buttons-from-station' || value.autocomplete == 1) {

                let dropDownFilterDataFields = await DataFromStationFieldsFactory.buildDropDownFilterDataFields(value);
                vm.scanStationObj.dropdownFilteredDataFields.push(dropDownFilterDataFields);
            }

            if (value.hidden_options !== undefined && value.hidden_options.length > 0) {
                angular.forEach(value.hidden_options, function (hideOptions) {
                    if (hideOptions.hidden_option == 'report_list') {
                        value.reportListHide = true;
                    } else if (hideOptions.hidden_option == 'on_screen_list') {
                        value.screenListHide = true;
                    } else if (hideOptions.hidden_option == 'show_more') {
                        value.showMoreHide = true;
                    } else if (hideOptions.hidden_option == 'export_print') {
                        value.exportPrintHide = true;
                    } else if (hideOptions.hidden_option == 'in_form') {
                        value.inFormHide = true;
                    } else if (hideOptions.hidden_option == 'is_filter') {
                        value.isFilterHide = true;
                    } else if (hideOptions.hidden_option == 'hide_from_results') {
                        value.hide_from_get_data_list = 1;
                    }
                });
            }

            if (value.field_type === 'digital-signature') {
                if (typeof (vm.digitalSignatureButtons) == 'undefined') {
                    vm.digitalSignatureButtons = [];
                }
            }

            if (value.parent_field_id != null) {
                vm.scanStation.scanStationObj.childParentStation = true;
            }

            vm.scanStationObj.scanStationFields[index].field_order = parseInt(value.field_order);

            // get the field slugs so we can generate a sample report


            // field types for use in the resulting data to determine how to present it
            vm.scanStationObj.scanStationFieldTypes[value.field_slug] = value.field_type;
            vm.scanStationObj.scanStationFieldNames[value.field_slug] = value.field_name;
            vm.scanStationObj.scanStationHideOptions[value.field_slug] = value.hide_from_get_data_list;

            if (value.field_type === 'checkbox' || value.field_type === 'button') {
                value.trueValue = value.pressedUnpressedValues.pressed;
                value.falseValue = value.pressedUnpressedValues.unPressed;
            }

            if (value.field_type == 'total-results') {
                vm.scanStationObj.hasTotalsField = true;
                let totalsResultFieldData = value.totalResultsAssociatedFields;
                if (typeof vm.scanStationObj.scanStationFieldIdsToField[value.totalResultsAssociatedFields.keyFieldId] != 'undefined' && vm.scanStationObj.scanStationFieldIdsToField[value.totalResultsAssociatedFields.fieldIdToCount] != 'undefined')
                    vm.totalsField[value.field_slug] = {
                        'parentField': vm.scanStationObj.scanStationFieldIdsToField[value.totalResultsAssociatedFields.keyFieldId].field_slug,
                        'childField': vm.scanStationObj.scanStationFieldIdsToField[value.totalResultsAssociatedFields.fieldIdToCount].field_slug,
                        'totalsField': value.field_slug,
                        'totalResultsAssociatedFields': value.totalResultsAssociatedFields
                    };
            }

            if (value.field_type == 'time-diff') {
                if (value.startAndFinishFields !== undefined
                    && typeof vm.scanStationObj.scanStationFieldIdsToField[value.startAndFinishFields.startFieldId] != 'undefined'
                    && vm.scanStationObj.scanStationFieldIdsToField[value.startAndFinishFields.finishFieldId] != 'undefined') {
                    vm.timeDiffFields[value.field_slug] = {
                        'startFieldSlug': vm.scanStationObj.scanStationFieldIdsToField[value.startAndFinishFields.startFieldId].field_slug,
                        'finishFieldSlug': vm.scanStationObj.scanStationFieldIdsToField[value.startAndFinishFields.finishFieldId].field_slug,
                        'timeDiffFieldSlug': value.field_slug,
                        'field': value
                    };
                }
            }


            if (value.field_type == 'formception-2') {

                let jsonParentChildFields = {};

                value.formceptionLink = '/module/' + value.formceptionTwoStationAndJob.uniqueModuleSlug + '/bloc/' + value.formceptionTwoStationAndJob.stationId;


                if (typeof vm.subformArray[value.field_slug] == "undefined") {
                    vm.subformArray[value.field_slug] = {};
                }

                vm.subformArray[value.field_slug]['subFormMasterField'] = value.subFormParentChildFields;
                vm.subformArray[value.field_slug]['subFormElementID'] = value.field_slug;
                vm.subformArray[value.field_slug]['subFormID'] = value.formceptionTwoStationAndJob.stationId;
                vm.subformArray[value.field_slug]['subFormParentChildFields'] = value.subFormParentChildFields;
                vm.subformArray[value.field_slug]['clickShowMoreLastEntry'] = value.clickShowMoreLastEntry;

            }

            if (value.field_type == 'data-to-form') {

                let localFormListFieldsForPassingData = [];
                angular.forEach(value.formListLocalRemoteFields, function (fieldPair) {
                    localFormListFieldsForPassingData.push(fieldPair.localFieldId);
                });

                vm.dataToFormArray.push({
                    linkedStationDetails: value.linkedStationDetails,
                    dataToPass: value.formListLocalRemoteFields,
                    dataToFormFieldSlug: value.field_slug,
                    jobID: value.formListJobAndStationId.jobId,
                    localFormListFieldsForPassingData: localFormListFieldsForPassingData,
                    localLinkedStationFieldId: (value.linkedStationDetails != null) ? value.linkedStationDetails.localFieldId : ''
                });

                if (value.formListLatestDataSlugFilters != null) {
                    vm.scanStationObj.dataToFormLatestDataFilters[value.field_slug] = value.formListLatestDataSlugFilters;
                }
            }


            // check for parameters
            if (value.field_show_condition != null) {
                value.conditionallyShow = 'hide';
                value.conditionalShowFieldSlug = value.field_show_condition.field_slug;
                value.conditionalShowParameters = value.field_show_condition.parameters.split(',');
            } else {
                value.conditionallyShow = 'show';
                value.conditionalShowParameters = false;
            }

            if (value.show_counter == 1) {
                vm.scanStationObj.scanStationFieldCounterArray.push({
                    slug: value.field_slug,
                    name: value.field_name
                });
            }

            if (value.field_type == 'auto-generate-number-text' || value.field_type == 'auto-generate-number' || value.field_type == 'auto-generate-number-date' || value.field_type == 'auto-generate-custom') {
                if (typeof (value.referenceField) != 'undefined' && typeof vm.scanStationObj.scanStationFieldIdsToField[value.referenceField] != 'undefined') {
                    // TODO Allow multiple reference fields in editor

                    if (vm.scanStationObj.autogenerateTriggerFields[value.field_slug] == null) {
                        vm.scanStationObj.autogenerateTriggerFields[value.field_slug] = [];
                    }

                    vm.scanStationObj.autogenerateTriggerFields[value.field_slug].push(
                        [vm.scanStationObj.scanStationFieldIdsToField[value.referenceField].field_slug, value, value.field_type]
                    );
                }
            }

            if (value.field_type == 'auto-generate-custom' && value.autoGenerateCustomFormat.includes('[BW]')) {

                for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                    let fieldInLoop = vm.scanStationObj.scanStationFields[i];

                    if (vm.scanStationObj.autogenerateTriggerFields[value.field_slug] == null) {
                        vm.scanStationObj.autogenerateTriggerFields[value.field_slug] = [];
                    }

                    if (value.autoGenerateCustomFormat.includes(`[BW]${fieldInLoop.field_slug}[/BW]`)) {
                        vm.scanStationObj.autogenerateTriggerFields[value.field_slug].push([fieldInLoop.field_slug, value, value.field_type]);
                    }
                }
            }

            // build an array of fields that are associated with maths fields, not just the maths fields themselves
            if (value.field_type == 'maths') {
                if (vm.mathsTriggerFields == null) {
                    vm.mathsTriggerFields = [];
                }
                let equation = value.mathsEquation;
                for (let i = 0; i < vm.scanStationObj.scanStationFieldSlugs.length; i++) {
                    if (equation.includes(`[${vm.scanStationObj.scanStationFieldSlugs[i]}]`) && !vm.mathsTriggerFields.includes(vm.scanStationObj.scanStationFieldSlugs[i])) {
                        vm.mathsTriggerFields.push(vm.scanStationObj.scanStationFieldSlugs[i]);
                    }
                }
            }

            if (value.field_type == 'multi-file') {
                vm.startWatchForMultiFileField(value.field_slug);
            }

            // to prevent every type of field in the system triggering results boxes here we are just defining the right ones

            // to prevent every type of field in the system triggering results boxes here we are just defining the right ones

            if (value.field_type == 'results-box') {

                if (vm.stateName == 'edit-bloc') {
                    await ResultsBoxService.callTriggerClearResultsBox(value.field_slug);
                }

                if (vm.resultsBoxFieldsThatTrigger == null) {
                    vm.resultsBoxFieldsThatTrigger = [];
                }

                if (vm.scanStationObj.allResultsBoxFieldIds == null) {
                    vm.scanStationObj.allResultsBoxFieldIds = [];
                }

                vm.scanStationObj.allResultsBoxFieldIds.push(value.id);

                if (value.localFieldMatchingFieldPairs != null) {
                    for (let i = 0; i < Object.keys(value.localFieldMatchingFieldPairs).length; i++) {
                        if (value.localFieldMatchingFieldPairs[i].localFieldId != null && !vm.resultsBoxFieldsThatTrigger.includes(value.localFieldMatchingFieldPairs[i].localFieldId) && vm.scanStationObj.scanStationFieldIdsToField[value.localFieldMatchingFieldPairs[i].localFieldId] != null) {
                            if (vm.resultsBoxFieldsThatTrigger[vm.scanStationObj.scanStationFieldIdsToField[value.localFieldMatchingFieldPairs[i].localFieldId].field_slug] == null) {
                                vm.resultsBoxFieldsThatTrigger[vm.scanStationObj.scanStationFieldIdsToField[value.localFieldMatchingFieldPairs[i].localFieldId].field_slug] = [];
                            }
                            vm.resultsBoxFieldsThatTrigger[vm.scanStationObj.scanStationFieldIdsToField[value.localFieldMatchingFieldPairs[i].localFieldId].field_slug].push(value.field_slug);
                        }
                    }
                }
            }

            // gets the fields that trigger external bloc data look ups
            if (vm.fieldsThatTriggerExternalBlocData == null) {
                vm.fieldsThatTriggerExternalBlocData = [];
            }

            if (value.field_type == 'bartender-file-generator') {
                vm.scanStationObj.bartenderFieldActionsEnabled = true;
            }

            if (vm.scanStationObj.linkDataFields == null) {
                vm.scanStationObj.linkDataFields = [];
            }

            if (value.field_type == 'link-data-field') {
                vm.scanStationObj.linkDataFields.push(value);
            }

            if (vm.scanStationObj.mindeeFields == null) {
                vm.scanStationObj.mindeeFields = [];
            }

            if (value.field_type == 'mindee') {

                vm.scanStationObj.mindeeFields.push(value);
            }

            if (vm.scanStationObj.blocworxGatewayFields == null) {
                vm.scanStationObj.blocworxGatewayFields = [];
            }

            // Ensure the linked fields array is initialized
            if (vm.scanStationObj.blocworxGatewayLinkedFields == null) {
                vm.scanStationObj.blocworxGatewayLinkedFields = [];
            }

            if (value.field_type === 'blocworx-gateway') {
                vm.scanStationObj.blocworxGatewayFields.push(value);

                // Loop through the gatewayLinkArray and append each ID
                for (var key in value.gatewayLinkArray) {
                    if (value.gatewayLinkArray.hasOwnProperty(key)) {
                        vm.scanStationObj.blocworxGatewayLinkedFields.push(value.gatewayLinkArray[key]);
                    }
                }
            }

            if (vm.scanStationObj.chartFields == null) {
                vm.scanStationObj.chartFields = [];
            }

            if(value.field_type == 'pie-chart' || value.field_type == 'echart') {
                vm.scanStationObj.chartFields.push(value);
            }

            if ( vm.scanStationObj.fileBuilderFields  == null) {
                vm.scanStationObj.fileBuilderFields = [];
            }

            if(value.field_type == 'file-builder') {
                vm.scanStationObj.fileBuilderFields.push(value);
            }

            vm.fieldsThatTriggerExternalBlocData = await ExternalBlocDataFactory.getExternalBlocDataTriggeringFields(value.id, vm.scanStationObj.scanStationFields, vm.fieldsThatTriggerExternalBlocData);

            value.fieldNextLine = value.field_next_line;
            value.fieldWidth = value.field_width;

            return true;

        });
    }


    /**
     * We don't need all the field data to be in the records when visible, only the visible ones.
     * scanStationFieldsForResults is the list of fields that only appear in the results
     * By only showing these performance is improved in blocs with a large amount of fields
     */
    vm.processScanStationFieldsForResults = async () => {

        let scanStationFieldsForResults = [];

        let limit = 5;

        if (vm.scanStationObj.stationDetails.field_results_limit != null) {
            limit = vm.scanStationObj.stationDetails.field_results_limit
        }

        for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
            if (i < limit) {
                scanStationFieldsForResults.push(vm.scanStationObj.scanStationFields[i]);
            }
        }

        vm.scanStationObj.scanStationFieldsForResults = angular.copy(scanStationFieldsForResults);
    }

    vm.getStationDataForDropDown = async function (fieldSlug, stationAndField, dropdownFromStationOnlyDataAfterDate, localFieldMatchingFieldPairs,
                                                   reverse, dropDownFromStationExcludeMatchingDataFromAnotherFieldData, localFieldExclusionMatchingFieldPairs, dropdownFromStationOrder) {
        try {
            if (vm.scanStationObj.stateName == 'edit-bloc') {
                return true;
            }

            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].loadDropdownFieldsOnce == 1 && vm.stationDataForDropDown[fieldSlug] != null) {
                return true;
            }

            let data = await scanStation.getStationDataForDropDown(vm.id, stationAndField, dropdownFromStationOnlyDataAfterDate, 'dropdown-from-another-station', localFieldMatchingFieldPairs, reverse, dropDownFromStationExcludeMatchingDataFromAnotherFieldData, localFieldExclusionMatchingFieldPairs, dropdownFromStationOrder);

            if (typeof (vm.stationIDForStationDataForDropDown) == 'undefined') {
                vm.stationIDForStationDataForDropDown = [];
            }

            vm.stationIDForStationDataForDropDown[fieldSlug] = stationAndField.stationId;
            vm.preventRuleCall[fieldSlug] = true;
            vm.stationDataForDropDown[fieldSlug] = data.data.data;
            vm.preventRuleCall[fieldSlug] = false;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e)
        }

    };

    /**
     * Searches the array of {@param fieldSlug} with the user input {@param input} and returns and array of the matches
     * it has found.
     * @param fieldSlug
     * @param input
     *
     */
    vm.getSearchData = function (fieldSlug, input) {
        try {
            if (input != null) {
                let result = [];
                angular.forEach(vm.stationDataForDropDown[fieldSlug], function (foundData) {
                    if (foundData != null) {
                        if (foundData.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                            result.push(foundData);
                        }
                    }
                });

                vm.searchResult[fieldSlug] = result;

            }

        } catch (e) {
            console.log(e);
        }

    };

    vm.accumulateRulesForSubmitButton = function (e, field_id, data, index) {
        try {
            if (typeof (vm.ruleDataToTest) == 'undefined') {
                vm.ruleDataToTest = [];
            }
            if (e.keyCode == 13 || e.keyCode == 9) {
                vm.ruleDataToTest.push({
                    field_id: field_id,
                    data: data,
                    index: index
                });
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This will load all actions.
     */
    vm.loadAllActions = async function (data = null) {
        /*
        // TODO discuss this with Raf, we may leave it out until V3.1 so we can decide how it works, in its current state it seems to be causing some rule issues
        console.log('loading all ACTIONS')
        let allFieldsData = vm.getAllFieldsData();
        let scanStationId = vm.scanStationObj.id;

        if (!vm.actions) {
            vm.actions = await scanStation.loadAllActions(scanStationId, allFieldsData, data);
        }

        angular.forEach(vm.actions.data.populate_fields, function (value, index) {
           vm.dataToAdd[index] = value;
            vm.scanStationObj.dataToAdd[index] = value;
        });*/

    }

    vm.isFieldAsFilterCase = async function (field_id, data) {
        try {

            let field = vm.scanStationObj.scanStationFieldIdsToField[field_id];

            if (field.filterByFieldSubBloc != null && field.filterByFieldSubBloc == 1) {

                if (!$('body').hasClass('blocworx-iframe')) {
                    return false;
                }
            }

            // checking if this filed is a field filter kind
            if (FilterByFieldService.isFieldAsFilter(field_id, field)) {
                let options = {'filterDataToQuery': {}};

                // allow for filtering of multiple fields

                if (vm.filterDataOptions != null && vm.filterDataOptions.filterDataToQuery != null) {
                    options = vm.filterDataOptions;
                }

                options.filterDataToQuery[field.field_slug] = [data];

                if (data == 'BlocworxRemoveFilter' || (data == '' && options.filterDataToQuery[field.field_slug] != null)) {
                    delete options.filterDataToQuery[field.field_slug];
                }

                if (data == 'BlocworxRemoveFilter' || (data == '' && vm.filterDataOptions.filterDataToQuery[field.field_slug] != null)) {
                    delete vm.filterDataOptions.filterDataToQuery[field.field_slug];
                }

                vm.filterDataOptions = options;

                Data.updateEnterDataController(options, 'updateDataForDataFiltering');

                // we don't trigger the data look up if its just a filter only and not a triggering one
                if (field.filterValueOnlyField != 1) {
                    if (Object.keys(options.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', options);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }
                }
            }

            // TODO figure out how to guarantee this happens after the data is finished displaying on the page
            setTimeout(function () {
                if (vm.thisSubBlocLoadsLatestData == true && vm.clickTriggerFinished != true) {
                    $('.form-results-section.table .form-results-tbody tr:first-child td:last-child a i').trigger('click');
                    vm.clickTriggerFinished = true;
                }
            }, 1000);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Function that will remove all lastfield class
     * from the whole form, it will also go though all the elments
     * and only on the last one it will be adding the lastfield
     * class in it.
     *
     * @param field_id
     */
    vm.updateLastField = function (field_id) {
        try {
            let fieldsInput = $("div[id^='scan-station-fields'] input:not(.hide), div[id^='scan-station-fields'] textarea, div[id^='scan-station-fields'] button:not(.submit-button)");
            let length = fieldsInput.length;

            // should remove from all elements the last
            $("div[id^='scan-station-fields'] input, div[id^='scan-station-fields'] textarea, div[id^='scan-station-fields'] button").removeClass('lastfield');

            // loops though the array of fields and set the last one
            fieldsInput.each(function (index, value) {
                if (index === length - 1) { // This will be only the last element
                    $(value).addClass('lastfield');
                }
            });

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.getAllFieldsData = () => {

        let allFieldsData = {};
        let dataToAdd = <AllDataInterface>{};

        angular.forEach(vm.scanStationObj.scanStationFields, (field) => {
            let dataToAdd = {
                fieldId: field.id,
                field_name: field.field_name,
                data: vm.scanStationObj.dataToAdd[field.field_slug],
                slug: field.field_slug,
            };
            allFieldsData[field.id] = dataToAdd;
        });

        vm.allFieldsData = allFieldsData;
        return allFieldsData;

    }

    vm.updateDataValue = async function () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve('ready');
            }, 200);
        });
    }

    /**
     * This will be the trigger of the action to clean all broken rules
     * by clicking to the field.
     */
    vm.fieldClick = (selector) => {

        // at anytime that you select a field, must be removing the class rule-broken due to, it must be an attempt to fix it
        let scanStationField = document.querySelector(`${selector} .rule-broken`);

        // just a basic check if exist or not the classList, so we can remove the rule-broken
        if (scanStationField != undefined && scanStationField.classList != undefined) {
            scanStationField.classList.remove('rule-broken');
        }
    }

    vm.triggerPrompt = async function (index, preventClearingValueOnFailure = 0) {
        try {
            if (index !== false) {

                let parentSelector = '';

                // check if vm.scanStation.gridPopUpObject is an object and is not empty
                if (vm.gridPopUpObject != null && vm.gridPopUpObject.popUpOrEmbedded == 'popup') {
                    parentSelector = '.grid-popup ';
                } else if (vm.gridPopUpObject != null && Object.keys(vm.gridPopUpObject).length > 0) {
                    parentSelector = '.embedded-line-true ';
                }

                let fieldIndex = Number.isInteger(index) ? '#station-field-' + index : '#' + index;

                let fieldsNotToReset = ['maths'];

                if (!fieldsNotToReset.includes(vm.scanStationObj.scanStationFields[index].field_type) && preventClearingValueOnFailure == 0) {
                    vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFields[index].field_slug] = '';
                    $(parentSelector + fieldIndex).val('');
                }

                $(parentSelector + fieldIndex).trigger("focus");
                $(parentSelector + fieldIndex).addClass('rule-broken');
            }

            await vm.playSound('error');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerYellowPrompt = async function (index, confirmSubmit) {
        try {
            $('#station-field-' + index).trigger("focus");
            $('#station-field-' + index).addClass('rule-broken');
            await vm.playSound('warning');
            vm.confirmSubmit = confirmSubmit;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.playSound = async function (sound) {
        try {
            let snd = new Audio();
            if (sound == 'error') {
                snd = new Audio("/resources/error.mp3");
            }
            if (sound == 'warning') {
                snd = new Audio("/resources/warning-sound.mp3");
            }
            if (sound == 'scan') {
                snd = new Audio("/resources/scan.mp3");
            }

            await snd.play();

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.filterStatus = function (item) {
        return item === 'red' || item === 'blue';
    };

    vm.checkLastThenSubmit = async function (e, scanStationId, fieldId) {
        try {

            // this should never work inside a popup
            if (vm.gridPopUpObject != null && vm.gridPopUpObject.popUpOrEmbedded == 'popup') {
                return;
            }

            if (typeof (vm.scanStationObj.scanStationFieldIdsToField[fieldId].submitDataField) != 'undefined' && vm.scanStationObj.scanStationFieldIdsToField[fieldId].submitDataField == 1) {
                await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, scanStationId);
                if (!$scope.$$phase) {$scope.$applyAsync();};
                return;
            }

            let current_input_id = '';
            if (e != false) {
                if (typeof (e.target) != 'undefined') {
                    current_input_id = e.target.id;
                }
            }

            if (current_input_id !== '') {


                // The new grid case
                if (typeof ($('#' + current_input_id).hasClass('lastfield'))) {
                    // check if the element with  class .lastfield is the same as the current
                    if ($('.lastfield').attr('id') === current_input_id) {
                        await vm.checkAllRulesThenAddData(vm.scanStationObj.dataToAdd, scanStationId);
                    }
                }
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDataForDataFiltering = async function (queryType, dataFilteringData = null) {
        try {

            // Making sure that we have the live data removed before any filtering, so we can see the update of the data later on
            // sometimes we want to keep this, e.g. for sockets and fully live data
            // we will acknowledge this in the docs
            if (vm.scanStationObj.stationDetails.getLiveDataWithoutRefreshing != 1) {
                vm.scanStationObj.liveData = []
            }

            if (dataFilteringData == null) {
                dataFilteringData = {};
            }

            dataFilteringData.stationID = vm.scanStationObj.id;
            dataFilteringData.jobID = vm.scanStationObj.jobID;

            if (queryType === 'all') {

                if (dataFilteringData.filterDataToQuery == null) {
                    dataFilteringData.filterDataToQuery = {};
                }
            }

            if (dataFilteringData.currentPage == null) {
                dataFilteringData.currentPage = vm.currentPage;
            }

            dataFilteringData.resultLimit = vm.resultLimit;

            dataFilteringData.reverse = vm.reverseData;

            vm.dataFilteringData = dataFilteringData;

            // we know if the setting is "all" then this has been set, otherwise it must exist elsewhere, if it doesnt
            // something has gone wrong so we dont do the filter
            if (dataFilteringData.filterDataToQuery == null || $.isEmptyObject(dataFilteringData.filterDataToQuery)) {
                await vm.getData(vm.scanStationObj.id);
                if (!$scope.$$phase) {$scope.$applyAsync();};
                return false;
            }

            let data = await Data.getDataForDataFiltering(dataFilteringData, 'fieldSlug');

            if (queryType === 'all') {
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');

            } else if (queryType === 'formception') {
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');
                vm.scanStationObj.liveData = data.data.data;
                vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
            } else {
                vm.scanStationObj.liveData = data.data.data;
                vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
            }

            if (isEmpty(dataFilteringData.filterDataToQuery)) {
                // TODO: check to see if the broadcast is working fine or will need an await
                Data.updateEnterDataController(data.data, 'originalDataForDataFiltering');
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    const isEmpty = inputObject => {
        if (inputObject == null) {
            return false;
        }

        return Object.keys(inputObject).length === 0;
    };

    vm.getData = async function (scanStationID) {
        try {
            if (typeof (vm.resultLimit) == 'undefined') {
                vm.resultLimit = 10;
            }
            if (typeof (vm.currentPage) == 'undefined') {
                vm.currentPage = 1;
            }
            if (typeof (vm.reverseData) == 'undefined') {
                vm.reverseData = false;
            }

            // if we already have field sorting then we will no longer use the default one
            // if we dont we will initialise the default one
            if (vm.scanStationObj.stationDetails.sortByFieldId != null && vm.sortByFieldId == null) {
                vm.sortByFieldId = vm.scanStationObj.stationDetails.sortByFieldId;
            }

            // we clear the data, unless we specify otherwise
            if (vm.scanStationObj.stationDetails.getLiveDataWithoutRefreshing != 1) {
                vm.scanStationObj.liveData = {};
            }

            if (typeof (vm.includeCountsAndChecksTime) == 'undefined') {
                vm.includeCountsAndChecksTime = 'all-data';
            }

            let data = await scanStation.getData(scanStationID, null, vm.includeCountsAndChecksTime,
                vm.scanStationObj.jobID, vm.scanStationObj.stateName, vm.resultLimit, vm.currentPage,
                vm.reverseData, vm.fromDate, vm.toDate, vm.scanStationObj.calendarPrimaryFieldFormat, vm.sortByFieldId);

            vm.fromDate = null;
            vm.toDate = null;
            if (typeof (data) != 'undefined') {

                vm.scanStationObj.liveData = data.data.data;

                if (!$scope.$$phase) {$scope.$applyAsync();};

                // Adding this conditional as it request to load on the first getData
                if (data.data.data.countLimitInfo !== undefined) {
                    vm.upperLimit = data.data.data.countLimitInfo.scans_counted;
                }
            }

            await vm.updateDropDownsRadiosAutoCompletesForAllFields();

            if (vm.scanStationObj.hasTotalsField) {
                await vm.getTotalsResults();
            }

            vm.savingData = false;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateDropDownsRadiosAutoCompletesForAllFields = async function (triggerField: any = false) {
        let fields = vm.scanStationObj.scanStationFields;
        if (triggerField != false && triggerField.fieldsToRefreshAfterCompletion != null && Object.keys(triggerField.fieldsToRefreshAfterCompletion).length > 0) {
            fields = [];
            for (let key in triggerField.fieldsToRefreshAfterCompletion) {
                if (triggerField.fieldsToRefreshAfterCompletion.hasOwnProperty(key)) {
                    fields.push(vm.scanStationObj.scanStationFieldIdsToField[triggerField.fieldsToRefreshAfterCompletion[key]])
                }
            }
        }
        angular.forEach(fields, async function (field) {

            if (field.field_type == 'dropdown-from-station' || field.field_type == 'radio-buttons-from-station'
                || field.field_type == 'autocomplete-field' || field.field_type == 'checkbox-buttons-from-station' || field.autocomplete == 1) {
                if (field.dropdownFromStationLookUp == null) {
                    field.dropdownFromStationLookUp = {
                        stationId: vm.id,
                        fieldIdToLookUp: field.id
                    };
                }

                angular.forEach(field.localFieldMatchingFieldPairs, function (fieldPair) {
                    fieldPair.localFieldValue = vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIDsToSlugs[fieldPair.localFieldId]];
                });

                // this function will be cleaning the local field value, as this was triggering again things after finish the insert/edit
                //vm.cleanLocalFieldMatchingFieldPairs(field.localFieldMatchingFieldPairs);

                await vm.getStationDataForDropDown(field.field_slug, field.dropdownFromStationLookUp, field.dropdownFromStationOnlyDataAfterDate, field.localFieldMatchingFieldPairs, field.dropdownFromStationReverseOrder, field.dropDownFromStationExcludeMatchingDataFromAnotherFieldData, field.localFieldExclusionMatchingFieldPairsArray, field.dropdownFromStationOrder);

            }
        });
    }

    vm.getCalendarData = async function (scanStationID) {
        try {
            vm.calendarData = {};
            let data = await scanStation.getCalendarData(scanStationID, vm.scanStationObj.jobID,
                vm.scanStationObj.stateName, vm.calendarFromDate, vm.calendarToDate, vm.scanStationObj.calendarPrimaryFieldFormat);

            if (typeof (data) != 'undefined') {
                vm.calendarData = data.data.data;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };


    vm.loadMoreData = function () {
        alert('This feature has been discontinued, please click the X button on the top of the entry form to see'
            + ' the timestamps and user id');
    };

    vm.getJobScanStations = async function (sectionID, jobID = null) {
        try {
            if (jobID == null) {
                jobID = vm.scanStationObj.jobID;
            }

            let data = await scanStation.getJobScanStations(jobID, sectionID);
            vm.jobScanStations = data.data.data;
            vm.jobSections = data.data.sections;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getExternalJobsByKeyWord = async function (keyword, secondInstanceOfDirective = false) {
        try {
            if (keyword.length >= 3) {
                let data = await scanStation.getExternalJobsByKeyWord(keyword);
                // TODO remove secondInstanceOfDirective once component to handle multiple of them is complete
                if (secondInstanceOfDirective == false) {
                    vm.externalJobs = data.data.data;
                } else {
                    vm.externalJobsTwo = data.data.data;
                }
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getSections = async function () {
        try {
            let data = await scanStation.getSections(vm.scanStationObj.jobID);
            vm.jobSections = data.data.sections;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getStationStationFieldsForRule = async function (id) {
        try {
            vm.scanStationFieldDesignOptions = []

            let data = await AngularScanStationService.getScanStationInformation(id, vm.scanStationObj.stateName);

            vm.scanStationFieldsForRule = data.scanStationFields;

            angular.forEach(vm.scanStationFieldsForRule, function (value) {
                vm.scanStationFieldDesignOptions[value.id] = {};
                vm.scanStationFieldDesignOptions[value.id].fieldNextLine = value.field_next_line;
                vm.scanStationFieldDesignOptions[value.id].fieldWidth = value.field_width;
            });

            if (vm.fieldIDForUpdatingRules !== undefined
                && vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules] !== undefined) {
                vm.fieldNextLine = vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules].fieldNextLine;
                vm.fieldWidth = vm.scanStationFieldDesignOptions[vm.fieldIDForUpdatingRules].fieldWidth;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e)
        }

    };

    vm.undo = async function () {
        try {
            await scanStation.undo(vm.scanStationObj.id);
            vm.showUndo = false;
            await vm.getData(vm.scanStationObj.id);
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * Loading data of the object vm.stationUsers by providing
     * the scanStationId.
     *
     * @param scanStationId
     */
    vm.getStationUsers = async function (scanStationId) {
        try {
            // loading all data of the current scanStationID
            let data = await scanStation.getStationUsers(scanStationId);

            // cleaning the previous value
            vm.stationUsers = false;

            // setting the value
            if (data) {
                vm.stationUsers = data;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            await vm.triggerBlocError(e.data.error);
            console.log(e);
        }

    };

    /**
     * This will be responsible to add data to the table
     * station_user.
     *
     * @param jobID
     * @param userID
     */
    vm.addStationUser = async function (jobID, userID) {
        try {
            vm.updating = true; // starting flag

            // this will add a new station_user register
            let data = await scanStation.addStationUser(vm.scanStationObj.id, jobID, userID);

            // update of the station users
            await vm.getStationUsers(vm.id);

            if (data?.data?.success) {
                await vm.triggerBlocUpdateSuccess(data?.data?.success);
            }

            vm.updating = false; // finishing flag

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {

            vm.updating = false;
            vm.prompt = e?.data?.error ? e.data.error : 'Error: Unknown';

            // showing the error message
            await vm.triggerBlocError(vm.prompt);

            // consoling the exception
            console.log(e);
        }

    };

    /**
     * This will remove the user from a scan station
     * the table that we will be removing data is the
     * station_user.
     *
     * @param stationUserID
     */
    vm.deleteUserFromStation = async function (stationUserID) {
        try {
            vm.updating = true;

            // deleting the user from the station_user
            let data = await scanStation.deleteUserFromStation(stationUserID);

            // showing the message
            if (data?.data?.success) {
                await vm.triggerBlocUpdateSuccess(data?.data?.success);
            }

            await vm.getStationUsers(vm.scanStationObj.id);

            vm.updating = false;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
            vm.updating = false;
            alert('Error:' + JSON.stringify(e));
        }

    };

    vm.getSections = async function () {
        try {
            let data = await scanStation.getSections(vm.jobID);
            vm.scanStation.sections = data.data.data;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.updateStationSetting = async function (setting, value) {
        try {

            // Adding validation of the value, needs to have a value to be able to update the station setting
            if (value !== undefined && value !== null) {
                vm.updating = true;
                await scanStation.updateStationSetting(vm.id, setting, value);
                vm.updating = false;
                await vm.triggerBlocUpdateSuccess('Bloc Setting Applied');
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

        } catch (e) {
            console.log(e);
            vm.updating = false;
        }

    };

    vm.importDataFromExcel = async function () {
        try {
            let excelDataFile = vm.excelData;
            vm.updating = true;
            let data = await scanStation.importDataFromExcel(vm.scanStationObj.id, vm.scanStationObj.jobID, vm.importDuplicateOptions, excelDataFile); // add importDuplicateOptions Here
            alert('Data uploaded successfully - ' + data.data.success);
            vm.updating = false;
            await vm.getData(vm.scanStationObj.id);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
            alert('Something went wrong, please check the browser console to see the error');
            vm.updating = false;
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }

    };

    vm.updateDataFromStation = async function (rowID, key, value) {
        try {
            vm.updatingEntryData = true;
            await scanStation.updateDataFromStation(rowID, key, value);
            vm.updatingEntryData = false;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
            vm.updatingEntryData = false;
        }

    };

    vm.getAntiStaticSignedForUser = async function () {
        try {
            let data = await scanStation.getAntiStaticSignedForUser(vm.scanStationObj.jobID, vm.userID);
            vm.antiStaticSignedForUser = data.data.digitalSignature;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getSOPSignedForUser = async function () {
        try {
            let data = await scanStation.getSOPSignedForUser(vm.scanStationObj.jobID, vm.scanStationObj.id, vm.userID);
            vm.SOPSignedForUser = data.data.digitalSignature;
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.signItemFrontEnd = async function (userID, password, itemToSign) {
        try {
            await scanStation.signItem(vm.scanStationObj.jobID, userID, password, itemToSign);
            if (itemToSign === 'anti_static_instructions') {
                await scanStation.logAntiStaticEntry(userID, password);
            }
            await vm.getAntiStaticSignedForUser();
            await vm.getSOPSignedForUser();

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
            alert('Incorrect Password');
        }

    };

    vm.onGetUserMediaButtonClick = async function () {
        try {
            vm.takePhotoStatus = 'open';

            // Grab elements, create settings, etc.
            let video = <HTMLVideoElement>document.getElementById('video');

            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Not adding `{ audio: true }` since we only want video now
                let stream = await navigator.mediaDevices.getUserMedia({video: true})
                video.srcObject = stream;
                await video.play();
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }


        } catch (e) {
            console.log(e);
        }

    };

    vm.onTakePhotoButtonClick = function () {
        try {
            // Elements for taking the snapshot
            let canvas = <HTMLCanvasElement>document.getElementById('canvas');
            let context = canvas.getContext('2d');
            let video = <HTMLVideoElement>document.getElementById('video');

            context.drawImage(video, 0, 0);
            let image = new Image();
            image.src = canvas.toDataURL("image/jpeg");
            $('#photo-preview-' + vm.fileFieldSlugSelected).attr('src', image.src);
            vm.takePhotoStatus = 'closed';
            vm.filesToAdd[vm.fileFieldSlugSelected] = image.src;
            vm.scanStationObj.filesToAdd[vm.fileFieldSlugSelected] = image.src;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.scrollTop = function () {
        try {
            $('html, body').animate({
                scrollTop: "0px"
            }, 800);

        } catch (e) {
            console.log(e);
        }

    };

    vm.createNewRevisionOfStation = async function () {
        try {
            let data = await scanStation.createNewRevisionOfStation(vm.scanStationObj.id);
            alert('New Revision has been successfully created, click Ok and you will be redirected.');
            $state.go('edit-bloc', {
                id: vm.scanStationObj.jobID,
                stationId: data.data.newStationID
            });
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This will trigger the action of duplication of a bloc.
     */
    vm.duplicateStationFromWithinStation = async function (jobId, userId) {
        try {
            vm.waitingForBlocDuplicate = true;
            // making sure that we have a job id before trying to duplicate a scan station
            if (jobId == null) {
                await vm.triggerBlocError('Missing Job Id in this ScanStation');
            }

            // loading the new scan station reference
            let duplicatedScanStation = await scanStation.duplicateStationFromWithinStation(vm.id, jobId, userId);

            // triggering the success from it
            await vm.triggerBlocUpdateSuccess('Bloc Duplicated');

            // if everything went well we will return the jobID and newScanStationId
            if (duplicatedScanStation?.data?.jobId && duplicatedScanStation?.data?.newScanStationId) {
                vm.waitingForBlocDuplicate = false;
                // showing an alert with message
                alert('Bloc Duplication has been successful. You you will now be redirected to the edit page of the new bloc.');

                // moving to the new scan station
                $state.go('edit-bloc', {
                    id: duplicatedScanStation.data.jobId,
                    stationId: duplicatedScanStation.data.newScanStationId
                });
            }
            vm.waitingForBlocDuplicate = false

        } catch (exception) {
            await vm.triggerBlocError(exception.data.response);
            vm.waitingForBlocDuplicate = false;
        }

    };

    vm.triggerClickByFieldID = function (fieldID) {
        try {
            console.log('inside function');
            $('input[data-field-id="' + fieldID + '"]').triggerHandler("focus");
            // $timeout(function () {
            //     $('input[data-field-id="' + fieldID + '"]').triggerHandler( "focus" );
            // });

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerClickByFieldIDTriggerAction = function (fieldID) {
        try {
            $('input[data-field-id="' + fieldID + '"]').trigger("focus");

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.triggerClickBySpecialFieldKey = function (specialFieldKey) {
        try {
            $('input[data-special-field-key="' + specialFieldKey + '"]').triggerHandler("focus");
            if (!$scope.$$phase) {$scope.$applyAsync();};
        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedRoleForFieldID = async function (selectedRoleSlug, fieldID) {
        try {
            await scanStation.addSelectedRoleForFieldID(selectedRoleSlug, fieldID);
            await vm.getSelectedRolesForFieldID(fieldID);
            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getSelectedRolesForFieldID = async function (fieldID) {
        try {
            let data = await scanStation.getSelectedRolesForFieldID(fieldID);
            vm.selectedRolesForFieldID = data.data.data;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedRoleForFieldID = async function (id, fieldID) {
        try {
            await scanStation.removeSelectedRoleForFieldID(id);
            await vm.getSelectedRolesForFieldID(fieldID);
            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This is responsible for populating fields with the latest entry from the records when the page loads
     * It uses either bloc settings related to this or field parameters
     */
    vm.getDataFromLatestRecord = async function () {
        try {

            // specific field population takes priority over the option where all fields populate

            let singleRecord: any = {
                data: {
                    data: {}
                }
            }

            if (vm.scanStationObj.stationDetails.has_populate_latest_data_specific_fields == 1) {
                singleRecord = await scanStation.getSingleRecordByRecordId(vm.scanStationObj.liveData[0]['cartolytics_entry_row_id'], JSON.stringify(vm.scanStationObj.populateLatestDataFields));
            } else if (vm.scanStationObj.stationDetails.uses_automatic_saving == 1 || vm.scanStationObj.stationDetails.populate_latest_data == 1 || vm.scanStationObj.stationDetails.single_entry_form == 1) {
                singleRecord = await scanStation.getSingleRecordByRecordId(vm.scanStationObj.liveData[0]['cartolytics_entry_row_id']);
            }

            for (let fieldSlug in singleRecord.data.data) {
                if (!vm.scanStationObj.fieldsToExcludeFromGetData.includes(fieldSlug)) {
                    if (typeof singleRecord.data.data[fieldSlug] === 'string' || singleRecord.data.data[fieldSlug] instanceof String) {
                        singleRecord.data.data[fieldSlug] = singleRecord.data.data[fieldSlug].replace("N/A", "");
                    }

                    if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != null) {
                        vm.scanStationObj.dataToAdd[fieldSlug] = singleRecord.data.data[fieldSlug];
                        let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
                        await vm.getRules('no-event', field.id, field.special_field_key, singleRecord.data.data[fieldSlug], field.fieldIndex, field.field_slug);

                        if (!$scope.$$phase) {$scope.$applyAsync();};
                        // for the time field we need to set more variables
                        // TODO we may have to look at all other exceptions and do things by field classes
                        if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].field_type == 'date-time-selector') {
                            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].dateTimeSetting == 'time') {
                                vm.scanStationObj.scanStationTimePickerFields[fieldSlug] = moment(singleRecord.data.data[fieldSlug], 'hh:mm:ss');
                                await vm.setSeconds(fieldSlug);
                                await vm.updateTime(fieldSlug);
                                if (!$scope.$$phase) {$scope.$applyAsync();};
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    vm.searchUsersByKey = async function (fieldSlug) {
        try {
            let data = await scanStation.searchUsersByKey(vm.scanStationObj.userSearchKey[fieldSlug]);
            if (typeof (vm.usersByKey) == 'undefined') {
                vm.usersByKey = [];
            }
            vm.usersByKey[fieldSlug] = data.data.data;
            vm.userResultsCount = vm.usersByKey.length;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This will add the user for the select data.
     *
     * @param email
     * @param fieldSlug
     */
    vm.addUserToUserSelectData = function (email, fieldSlug) {
        try {

            // making sure that we have an array of data
            if (vm.scanStationObj != undefined) {
                if (vm.scanStationObj.dataToAdd[fieldSlug] == undefined) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = [];
                } else if (vm.scanStationObj.dataToAdd[fieldSlug] == '') {
                    vm.scanStationObj.dataToAdd[fieldSlug] = [];
                }
            }

            // now that is guaranteed that we have an array of data, we can check
            // if is an array or a string with previous data
            if (Array.isArray(vm.scanStationObj.dataToAdd[fieldSlug])) {
                vm.scanStationObj.dataToAdd[fieldSlug].push(email);
            } else {
                // for the string cases
                vm.scanStationObj.dataToAdd[fieldSlug] = `${vm.scanStationObj.dataToAdd[fieldSlug]},${email}`;
            }

            vm.usersByKey = {};
            vm.userSearchKey = [];
            vm.scanStationObj.userSearchKey = [];

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This will be responsible to remove the users from the select
     * on user list.
     * @param userToDelete
     * @param fieldSlug
     */
    vm.removeUserFromSelectUserList = function (userToDelete, fieldSlug) {
        try {
            if (vm.scanStationObj.dataToAdd != undefined && vm.scanStationObj.dataToAdd[fieldSlug] != undefined) {

                // for the array cases
                if (Array.isArray(vm.scanStationObj.dataToAdd[fieldSlug])) {
                    angular.forEach(vm.scanStationObj.dataToAdd[fieldSlug], function (user, index) {
                        if (user === userToDelete) {
                            vm.scanStationObj.dataToAdd[fieldSlug].splice(index, 1);
                        }
                    });
                } else {
                    // For string cases
                    let arrayData = vm.scanStationObj.dataToAdd[fieldSlug].split(',');
                    angular.forEach(arrayData, function (user, index) {
                        if (user === userToDelete) {
                            arrayData.splice(index, 1);
                        }
                    });

                    // this will set the data to be with "," between the field data
                    vm.scanStationObj.dataToAdd[fieldSlug] = arrayData.join(',')
                }
            }

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This will be responsible to load the users for the
     * select in user_list -> field
     *
     * @param fieldSlug
     */
    vm.loadUserFromSelectUserList = function (fieldSlug) {
        try {

            let users = [];

            if (vm.scanStationObj.dataToAdd != undefined && vm.scanStationObj.dataToAdd[fieldSlug] != undefined) {
                if (Array.isArray(vm.scanStationObj.dataToAdd[fieldSlug])) {
                    users = vm.scanStationObj.dataToAdd[fieldSlug];
                } else {
                    users = vm.scanStationObj.dataToAdd[fieldSlug] == '' ? [] : vm.scanStationObj.dataToAdd[fieldSlug].split(',');
                }
            }

            // this is to fix issue with sometimes showing array ['']
            users = users.filter(function (value) {
                return value.trim() !== '';
            });

            return users;

        } catch (e) {
            console.log(e);
        }

    };

    vm.addDocumentSignTrigger = async function () {
        try {
            await scanStation.addDocumentSignTrigger(vm.newDocumentSignTrigger);
            await vm.getDocumentSignTriggers();
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.deleteDocumentSignTrigger = async function (id) {
        try {
            await scanStation.deleteDocumentSignTrigger(id);
            await vm.getDocumentSignTriggers();

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDocumentSignTriggers = async function () {
        try {
            let data = await scanStation.getDocumentSignTriggers(vm.scanStationObj.id);
            vm.documentSignTriggers = data.data.data;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getDocumentSignTriggerData = async function (id) {
        try {
            let data = await scanStation.getDocumentSignTriggerData(id);
            vm.documentSignTriggerDataToUpdate = data.data.data;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.addSelectedRoleForLoadingUsers = async function (roleDetails) {
        try {
            let RoleDetailsArray = roleDetails.split("|");

            // check if its already there
            vm.isInArray = false;
            angular.forEach(vm.scanStationObj.selectedRolesForLoadingUsers, function (role) {
                if (role.id == RoleDetailsArray[0]) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.scanStationObj.selectedRolesForLoadingUsers = vm.scanStationObj.selectedRolesForLoadingUsers.concat([{
                    id: RoleDetailsArray[0],
                    title: RoleDetailsArray[1]
                }]);
            }

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.addStationUnit = async function (unit) {
        try {
            if (typeof (vm.stationUnits) == 'undefined' || vm.stationUnits == null) {
                vm.stationUnits = [];
            }

            vm.isInArray = false;
            angular.forEach(vm.stationUnits, function (value) {
                if (value == unit) {
                    vm.isInArray = true;
                }
            });

            if (vm.isInArray == false) {
                vm.stationUnits.push(unit);
            }

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedSOPFiles = async function (id) {
        try {
            angular.forEach(vm.scanStationObj.selectedSOPFiles, function (file, index) {
                if (file.id == id) {
                    vm.scanStationObj.selectedSOPFiles.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeSelectedRolesForLoadingUsers = async function (id) {
        try {
            angular.forEach(vm.scanStationObj.selectedRolesForLoadingUsers, function (role, index) {
                if (role.id == id) {
                    vm.scanStationObj.selectedRolesForLoadingUsers.splice(index, 1);
                }
            });

            await vm.updateScanStation('non-fields-related');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.scrollTo = function (tag, element = "html, body", usePosition = false, elementId = false) {
        try {
            let scrollToElement = $("a[name='" + tag + "']");

            if (elementId != false) {
                scrollToElement = $("#" + elementId);
            }

            if (usePosition == false) {
                $(element).animate({scrollTop: scrollToElement.offset().top - 150}, "slow");
            } else {
                $(element).animate({scrollTop: scrollToElement[0].offsetTop - 150}, "slow");
            }

            return true;

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * This will be making sure that the html content inside
     * can be shown, so if contain an htmlCode in it will be
     * adding trustAsHtml from the $sce service.
     *
     * @param htmlCode
     */
    vm.toTrusted = function (htmlCode) {
        try {
            let elementToReturn = '';

            // some integers and floats a failing, we will do a try/catch in case anything unusual is fed in here
            if (htmlCode != null) {
                elementToReturn = htmlCode.toString();
            }

            return $sce.trustAsHtml(elementToReturn);

        } catch (e) {
            console.log(e)
        }
    };

    vm.displayDigitalSignature = function (fieldSlug, digitalSignaturePreview) {
        try {
            if (vm.scanStationObj.stateName === 'public-form' && digitalSignaturePreview === '') {
                vm.digitalSignatureButtons[fieldSlug] = true;
                alert('You must be logged in as a non public user to sign your digital signature.');
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = digitalSignaturePreview;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.dataNextPage = async function (nextPage) {
        try {
            vm.currentPage = await Data.dataNextPage(nextPage, vm.currentPage);

        } catch (e) {
            console.log(e);
        }
    };

    vm.createCellColour = async function (name, colour) {
        try {
            let colourScheme = <ColourScheme>{};
            colourScheme.id = vm.scanStationObj.id;
            if (name !== undefined || colour !== undefined) {
                colourScheme.name = name;
                colourScheme.colour = colour;
                await scanStation.addColourScheme(colourScheme);
                await vm.getColourSchemes();
                alert('Coloured cell created')

            } else {
                alert('Must not be empty')
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.deleteCellColour = async function (name) {
        try {
            if (name !== undefined) {
                await scanStation.deleteColourScheme(name, vm.scanStationObj.id);
                await vm.getColourSchemes();
            } else {
                alert('Cannot delete empty')
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.getColourSchemes = async function () {
        try {
            vm.colourSchemeArray = {};

            let data = await scanStation.getColourSchemes(vm.scanStationObj.id);

            angular.forEach(JSON.parse(data.data.data), function (value, index) {
                vm.colourSchemeArray[index] = (value.data);
            });

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.createAlert = async function (alertData, dateTimeStamp) {
        try {
            alertData.dateTimeStamp = dateTimeStamp;
            alertData.title = vm.alertDataTitle + '-' + alertData.dateTimeStamp;
            alertData.alertType = 'standard';
            alertData.frequency = 'N/A';
            alertData.recipients = 'Custom';
            alertData.hour = '4:00';

            angular.forEach(alertData.customRecipients.split(','), function (value) {
                let validEmail = User.validateEmail(value);
                if (!validEmail) {
                    alert('Sorry invalid email: ' + value);
                    InvalidEmailExceptionFactory.throwException(value);
                }
            });

            let data = await Data.addAlert(alertData);
            alert(data.data.success);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (exception) {
            if (exception.constructor.name === 'InvalidEmailException') {
                return;
            }
            console.log(exception);
        }

    };

    function autoGenerateDateFormat(date, dateFormat) {
        try {
            let formattedDate = '';
            if (dateFormat === 'YYYYMMDD') {
                let splitDate = date.split('-');
                angular.forEach(splitDate, function (value) {
                    formattedDate += value;
                });

                return formattedDate;

            } else if (dateFormat === 'DD/MM/YYYY') {

                let splitDate = date.split('-').reverse();
                angular.forEach(splitDate, function (value, index) {
                    if (index !== 0) {
                        formattedDate += '/' + value;
                    } else {
                        formattedDate += value;
                    }
                });

                return formattedDate;

            } else if (dateFormat === 'DD.MM.YYYY') {

                let splitDate = date.split('-').reverse();
                angular.forEach(splitDate, function (value, index) {
                    if (index !== 0) {
                        formattedDate += '.' + value;
                    } else {
                        formattedDate += value;
                    }
                });

                return formattedDate;

            } else if (dateFormat === 'Single Week - DD/MM/YYY - DD/MM/YYYY') {

                let momentDate = moment(date); //saturday, the next one
                let weekNumber = Math.ceil(momentDate.date() / 7);
                let check = moment(date, 'YYYY-MM-DD');

                let month = check.format('MM');
                let year = check.format('YYYY');

                formattedDate = moment(date).startOf('isoWeek').format('DD/MM/YYYY') + ' - '
                    + moment(date).endOf('isoWeek').format('DD/MM/YYYY');

                return formattedDate;

            } else if (dateFormat === 'day-month-00th-year') {

                formattedDate = moment(date).format('dddd, MMMM Do YYYY');

                return formattedDate;

            } else {
                formattedDate = date;
            }

            return formattedDate;

        } catch (e) {
            console.log(e);
        }
    }

    /* Main function to get the autogenerate data, everything else is now handled on the server side */

    vm.getAutoGenerateFieldData = async function (field, fieldType, fieldSlug) {
        try {

            if (vm.scanStationObj.previousDataToAddForAutoGenerate == null) {
                vm.scanStationObj.previousDataToAddForAutoGenerate = [];
            }
            if (!vm.isViewMode() && await vm.triggerAutoGenerateConditionsPass(field, fieldSlug)) {
                let data = await scanStation.getAutoGeneratedFieldData(field.id, vm.scanStationObj.id, vm.scanStationObj.dataToAdd);
                vm.scanStationObj.dataToAdd[fieldSlug] = data.data.data[fieldSlug];

                // we dont want to show the -BLCX-TEMP- part of the autogenerate on the screen, thats why its called DisplayOnly

                if(vm.scanStationObj.dataToAddDisplayOnly == null) {
                    vm.scanStationObj.dataToAddDisplayOnly = {};
                }

                vm.scanStationObj.dataToAddDisplayOnly[fieldSlug] = data.data.data[fieldSlug].split('-BLCX-TEMP-')[0];

                vm.scanStationObj.previousDataToAddForAutoGenerate[fieldSlug] = structuredClone(vm.scanStationObj.dataToAdd);
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }
        } catch (e) {
            console.log(e);
        }

    };


    /**
     * These are extra conditions we check to see if we will do the autogenerate
     */

    vm.triggerAutoGenerateConditionsPass = async function (field, fieldSlug) {

        // this is to prevent the same autogenerate fields being triggered multiple times when the data hasnt changed
        if (JSON.stringify(vm.scanStationObj.previousDataToAddForAutoGenerate[fieldSlug]) == JSON.stringify(vm.scanStationObj.dataToAdd)) {
            return false;
        }

        if (field.preventTriggerWhenHidden == 1 && !GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug])) {
            return false;
        }

        if (vm.scanStationObj.isInEditMode && field.neverTriggerIfValueIsSet == 1 && vm.scanStationObj.dataToAdd[fieldSlug] != 'N/A' && vm.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStationObj.dataToAdd[fieldSlug] != '') {
            return false;
        }

        return true;

    }

    vm.validateAutoGeneratedNumber = async (autogeneratedNumber = null, scanStationId) => {

        // getting the current number stored into the table autogenerate_queue
        let queuedNumber = await scanStation.getCurrentScanStationAutoGeneratedNumberAvailable(scanStationId);

        // if we cant find any number, we must save it and use it as new one
        if (queuedNumber === false) {
            queuedNumber = autogeneratedNumber;
            await scanStation.setCurrentScanStationAutoGeneratedNumberAvailable(scanStationId, queuedNumber);
            return queuedNumber;
        }

        let captured = /(?<string>[a-zA-Z]+)(?<number>\d+)/.exec(queuedNumber);
        let {string, number} = captured.groups;
        let iterableNumber = (parseInt(number) + 1).toString();
        queuedNumber = string + iterableNumber.padStart(number.length, '0');
        await scanStation.setCurrentScanStationAutoGeneratedNumberAvailable(scanStationId, queuedNumber);
        return queuedNumber;

    }

    /**
     * builds the data after clicking show more or edit
     * @param currentRow
     * @param rowIndex
     */
    vm.setFieldsEditEntryApply = async function (currentRow, rowIndex = null, recordId = null) {

        let recordData = {};

        currentRow = currentRow || {};

        if (rowIndex !== null && vm.scanStationObj.liveData && vm.scanStationObj.liveData[rowIndex]) {
            const cartolyticsEntryRowId = vm.scanStationObj.liveData[rowIndex]['cartolytics_entry_row_id'];

            if (cartolyticsEntryRowId || recordId) {
                let recordIdToLookup = cartolyticsEntryRowId || recordId;
                let singleRecord = await scanStation.getSingleRecordByRecordId(recordIdToLookup);

                for (let fieldSlug in singleRecord.data.data) {
                    recordData[fieldSlug] = singleRecord.data.data[fieldSlug];
                    if (!currentRow[fieldSlug]) {
                        currentRow[fieldSlug] = singleRecord.data.data[fieldSlug];
                    }
                }
            }
        } else if (recordId) {
            let singleRecord = await scanStation.getSingleRecordByRecordId(recordId);

            for (let fieldSlug in singleRecord.data.data) {
                recordData[fieldSlug] = singleRecord.data.data[fieldSlug];
                if (!currentRow[fieldSlug]) {
                    currentRow[fieldSlug] = singleRecord.data.data[fieldSlug];
                }
            }
        }

        angular.forEach(currentRow, function (rowValue, key: any) {
            vm.scanStationObj.dataToAdd[key] = recordData[key];

            if (vm.scanStationObj.scanStationFieldSlugsToField[key] != null && vm.scanStationObj.scanStationFieldSlugsToField[key].field_type == 'checkbox-buttons-from-station') {
                vm.scanStationObj.dataToAdd[key] = recordData[key + '_blcwrx_checkbox_object'];
            }

            if (key === 'user_id') {
                vm.scanStationObj.dataToAdd[key] = $window.localStorage.userID;
            }

            if (key === 'cartolytics_entry_row_id') {
                vm.scanStationObj.stationDataID = recordData[key];
            }

            if (vm.scanStationObj.scanStationFieldSlugsToField[key] != null && vm.scanStationObj.scanStationFieldSlugsToField[key].plainDataPermanentValueNeverChanges == 1) {
                let field = vm.scanStationObj.scanStationFieldSlugsToField[key];
                vm.scanStationObj.dataToAdd[key] = vm.scanStationObj.scanStationFieldSlugsToField[key].plainDataFieldValue;
                if (typeof vm.getRules === "function") {
                    vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                }
            }

            if (vm.scanStationObj.scanStationFieldSlugsToField[key] != null && vm.scanStationObj.scanStationFieldSlugsToField[key].forceRuleTriggerAfterClickingEdit == 1) {
                let field = vm.scanStationObj.scanStationFieldSlugsToField[key];
                if (typeof vm.getRules === "function") {
                    vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                }
            }

            if (vm.scanStationObj.scanStationFieldSlugsToField[key] != null && vm.scanStationObj.scanStationFieldSlugsToField[key].field_type == 'logged-in-user-info' && vm.scanStationObj.isInEditMode) {
                let field = vm.scanStationObj.scanStationFieldSlugsToField[key];
                vm.loadUserDetails(field);
            }
        });

        vm.linkedDataFieldJson = await LinkFieldFactory.rebuildLinkedDataJson(vm.scanStationObj.scanStationFieldIdsToField, vm.scanStationObj.dataToAdd);
    }

    vm.setFieldsEditEntry = async function (currentRow, rowIndex) {
        try {

            if (vm.scanStationObj.stationDetails.scrollToAfterShowMoreFieldId == null || vm.scanStationObj.stationDetails.scrollToAfterShowMoreFieldId == "null") {
                vm.scrollTop();
            } else {

            }
            await vm.setFieldsEditEntryApply(currentRow, rowIndex);

            if (vm.scanStationObj.stationDetails.scrollToAfterShowMoreFieldId != null && vm.scanStationObj.stationDetails.scrollToAfterShowMoreFieldId != "null") {
                $timeout(function () {
                    let elementId = `scanStationForm-${vm.scanStationObj.stationDetails.scrollToAfterShowMoreFieldId}`;
                    vm.scrollTo(null, "html, body", false, elementId)
                }, 0);
            }
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.formatToCharacterLimit = function (result, charLimit = null) {
        if (charLimit !== null) {
            return result.substring(0, charLimit) + '...';
        } else {
            return result;
        }
    };

    vm.checkIfLoadMore = function () {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, function (data) {
                if (data.result_character_count != null || data.hide_from_get_data_list === 1) {
                    return vm.showLoadMoreData = true;
                }
            });

            // if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    /**
     * TODO: Check this function, sounds weird that a set just change the value of the vm.showMoreSingleDataBox
     */
    vm.setShowMoreSingleDataBox = function () {
        vm.showMoreSingleDataBox = !vm.showMoreSingleDataBox;
    };

    vm.checkIfPageButtonClickable = async function (direction) {
        try {
            if (direction === 'forward') {
                if (((vm.currentPage * vm.resultLimit) < vm.upperLimit)) {

                    await vm.dataNextPage(1);

                    // TODO: check if is necessary await for: $rootScope.$broadcast(msg);
                    Data.updateEnterDataController((vm.currentPage), 'updatePageNumber');

                    if (vm.filterDataOptions != null) {
                        vm.filterDataOptions.currentPage = vm.currentPage;
                        vm.filterDataOptions.resultLimit = vm.resultLimit;
                    }

                    if (vm.dataFilteringData.filterDataToQuery != null && Object.keys(vm.dataFilteringData.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }

                } else {
                    return false;
                }
            } else if (direction === 'backward') {

                if (vm.currentPage != 1) {
                    await vm.dataNextPage(0);

                    // TODO: check if is necessary await for: $rootScope.$broadcast(msg);
                    Data.updateEnterDataController(vm.currentPage, 'updatePageNumber');

                    if (vm.filterDataOptions != null) {
                        vm.filterDataOptions.currentPage = vm.currentPage;
                        vm.filterDataOptions.resultLimit = vm.resultLimit;
                    }

                    if (vm.dataFilteringData.filterDataToQuery != null && Object.keys(vm.dataFilteringData.filterDataToQuery).length > 0) {
                        await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
                    } else {
                        await vm.getData(vm.scanStationObj.id);
                    }
                } else {
                    return false;
                }
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.saveScanStation = async function (id) {
        try {
            if (vm.showUndo === true) {
                vm.showUndo = false;
            }

            if (vm.showLoadMoreData === true) {
                vm.showLoadMoreData = false;
            }

            if (vm.prompt !== false) {
                vm.prompt = false;
            }

            const scanStationObyCopy = angular.copy(vm.scanStationObj);
            await scanStation.saveScanStation(scanStationObyCopy);
            await AngularScanStationService.callGetScanStationInformationComponentFunction(id, 'main-query');
            vm.scanStationObj.id = id;
            await vm.getData(vm.scanStationObj.id);
            await vm.getScanStationRules(id);
            vm.scanStationObj.isInEditMode = false;
            vm.scanStationObj.stationDataID = null;

            vm.scanStationObj.dataToAdd[vm.formceptionChildField] = scanStationObyCopy.dataToAdd[vm.formceptionParentField];

            let fieldToCheckRulesFor = null;
            angular.forEach(vm.scanStationObj.scanStationFields, function (value) {
                if (value.field_slug == vm.formceptionChildField) {
                    fieldToCheckRulesFor = value;
                }
            });

            if (fieldToCheckRulesFor != null) {
                await vm.getRules('no-event', fieldToCheckRulesFor.id, fieldToCheckRulesFor.special_field_key,
                    scanStationObyCopy.dataToAdd[vm.formceptionParentField], 0, vm.formceptionChildField);
            }

            await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            if (vm.scanStationObj.hasTotalsField) {
                await vm.getTotalsResults();
            }

        } catch (e) {
            console.log(e);
        }

    };


    vm.addFilterData = function (fieldSlug, fieldData) {

        try {
            if (vm.dataFilteringData == null) {
                vm.dataFilteringData = {};
            }

            if (typeof (vm.dataFilteringData.resultLimit) == 'undefined') {
                vm.dataFilteringData.resultLimit = 10;
            }

            if (typeof (vm.dataFilteringData.filterDataToQuery) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery = {}
            }
            if (typeof (vm.dataFilteringData.filterDataToQuery[fieldSlug]) == 'undefined') {
                vm.dataFilteringData.filterDataToQuery[fieldSlug] = [];
            }
            if (fieldData === "ignore") {
                angular.forEach(vm.dataFilteringData.filterDataToQuery, function (value, index) {
                    if (index === fieldSlug) {
                        delete vm.dataFilteringData.filterDataToQuery[index];
                    }
                });
            } else {
                vm.dataFilteringData.filterDataToQuery[fieldSlug].push(fieldData);
            }

        } catch (e) {
            console.log(e);
        }

    };

    vm.removeFilter = function (fieldSlug, fieldData, fieldKey) {
        try {
            angular.forEach(vm.dataFilteringData.filterDataToQuery[fieldSlug], function (value, index) {
                if (fieldData === value) {
                    vm.dataFilteringData.filterDataToQuery[fieldSlug].splice(index, 1);
                    if (vm.dataFilteringData.filterDataToQuery[fieldSlug].length <= 0) {

                        delete vm.dataFilteringData.filterDataToQuery[fieldSlug];

                        if (vm.dataFilteringSelect == null) {
                            vm.dataFilteringSelect = {};
                        }

                        vm.dataFilteringSelect[fieldKey] = 'ignore';
                    }
                }
            });

            // now we need to check if we are finished with vm.specificFieldSearch or not, if there is only blocworx_free_search left
            // then we can turn vm.specificFieldSearch off
            let specificFieldSearch = false;
            angular.forEach(vm.dataFilteringData.filterDataToQuery, function (filter, key) {
                let keyString = key.toString();
                if (keyString != 'blocworx_free_search') {
                    specificFieldSearch = true;
                }
            });

            if (specificFieldSearch == false) {
                vm.specificFieldSearch = false;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.returnScanStation = async function () {
        try {

            let result = scanStation.returnScanStation();
            vm.scanStationObj = result[0];

            await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'main-query');
            await vm.getData(vm.scanStationObj.id);

            await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            await vm.getScanStationRules(vm.scanStationObj.id);

            if (result[1] === true) {
                vm.showFormceptionPopup = false;
            }

            if (vm.showUndo === true) {
                vm.showUndo = false;
            }

            if (vm.prompt !== false) {
                vm.prompt = false;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    vm.checkForOtherForm = async function (scanStationID) {
        try {
            if (vm.showFormceptionPopup === true) {
                await vm.saveScanStation(scanStationID);
            }
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.setForNewForm = function (fieldData) {
        try {
            fieldData = fieldData.split(',');
            vm.formceptionID = fieldData[0];
            vm.formceptionChildField = fieldData[1];
            vm.formceptionParentField = fieldData[2];

            let formceptionData = {
                formceptionID: fieldData[0],
                formceptionChildField: fieldData[1],
                formceptionParentField: fieldData[2]
            };

            if (vm.scanStationObj.formceptionData.length <= 0) {
                vm.scanStationObj.formceptionData.push(formceptionData);
            } else {
                let pushed = false;
                angular.forEach(vm.scanStationObj.formceptionData, function (formception) {
                    if ((formceptionData['formceptionID'] !== formception['formceptionID']) && pushed === false) {
                        vm.scanStationObj.formceptionData.push(formceptionData);
                        pushed = true;
                    }
                });
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkIfEntryIsFormceptionEntry = function (fieldSlug) {
        try {
            checkFormceptionDuplicates();
            let formceptionEntry = false;
            angular.forEach(vm.scanStationObj.formceptionData, function (formception) {
                if (formceptionEntry === false) {
                    if (fieldSlug === formception['formceptionParentField']) {
                        formceptionEntry = true;
                    }
                }
            });

            return formceptionEntry;

        } catch (e) {
            console.log(e);
        }

    }

    vm.setSeconds = function (fieldSlug) {
        try {
            let d = new Date();
            let n = d.getSeconds();

            const timePicker = vm.scanStationObj.scanStationTimePickerFields[fieldSlug];
            if (timePicker !== null && timePicker !== undefined && 'setSeconds' in timePicker) {
                timePicker.setSeconds(n);
            }

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This method will be responsible to get a timepicker value, has a basic validation of:
     * check if the object datepicker exists
     * check if the method exists
     * @return 0 if something is invalid, otherwise the correct value.
     **/
    vm.getTimePicker = function (timePicker, getMethod) {
        try {
            if (timePicker !== null && timePicker !== undefined && getMethod in timePicker) {
                return timePicker[getMethod]();
            }
            return undefined;

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method sets the time format on key press
     **/
    vm.setTimeFromFieldChange = async function (fieldSlug, fieldIndex) {
        try {

            const timePicker = vm.scanStationObj.scanStationTimePickerFields[fieldSlug];
            if (timePicker != null) {
                console.log(timePicker);
                let timeFormat = moment(timePicker).format('HH:mm:ss');
                console.log(timeFormat)

                vm.scanStationObj.dataToAdd[fieldSlug] = timeFormat;
                let field = vm.scanStationObj.scanStationFields[fieldIndex];
                await vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = 'Invalid Time';
            }


        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method is responsible to update the time value with some basic rules
     * to at least have a 0 on the left  for numbers lower than 10
     **/
    vm.updateTime = function (fieldSlug) {
        try {

            if (vm.scanStationObj.scanStationTimePickerFields[fieldSlug] == null) {
                vm.scanStationObj.scanStationTimePickerFields[fieldSlug] = vm.newDate;
            }

            const timePicker = vm.scanStationObj.scanStationTimePickerFields[fieldSlug];
            // TODO: Adrian: here i can see vm.getTimePicker...
            let hours = vm.getTimePicker(timePicker, 'getHours');
            let minutes = this.getTimePicker(timePicker, 'getMinutes');
            let seconds = this.getTimePicker(timePicker, 'getSeconds');

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            vm.scanStationObj.dataToAdd[fieldSlug] = hours + ':' + minutes + ':' + seconds;

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateTimeFromDataToAddField = async function (fieldSlug) {
        try {
            vm.scanStationObj.scanStationTimePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'hh:mm:ss');

        } catch (e) {
            console.log(e);
        }

    }

    vm.clear = function (fieldSlug) {
        vm.scanStationObj.scanStationDatePickerFields[fieldSlug] = null;
    };

    vm.toggleMin = function () {
        vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
        vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.open1 = function (fieldSlug) {
        try {

            vm.popup1.opened[fieldSlug] = true;

        } catch (e) {
            console.log(e);
        }

    };

    vm.setDate = function (year, month, day) {
        try {
            // TODO: Adrian we must figure it out what this is doing
            let fieldSlug = 'fieldSlug';

            vm.scanStationObj.scanStationDatePickerFields[fieldSlug] = new Date(year, month, day);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    vm.setScanStationDatePickerFieldValueFromEmpty = function (fieldSlug) {
        vm.scanStationObj.scanStationDatePickerFields[fieldSlug] = new Date();
    }

    /**
     * This will refresh the data for
     * @param dateSrcData
     * @param fieldSlugToChange
     * @param addNumberOfDays
     */
    vm.refreshDatepicker = (dateSrcData, fieldSlugToChange, fieldSlugOrigin, addNumberOfDays = 0) => {

        let loadedNewDateData = DateService.currentDateToCheck(dateSrcData, addNumberOfDays);
        let formattedData = DateService.formatDate(loadedNewDateData);

        // To work with datepicker we just need to send an object of date
        vm.scanStationObj.scanStationDatePickerFields[fieldSlugToChange] = loadedNewDateData;

        // to save the data we format before save it
        vm.scanStationObj.dataToAdd[fieldSlugToChange] = formattedData;
    }

    /**
     * Method to add X amount of days to a date field value if
     * @param fieldSlug
     * @param numberOfDaysToAdd
     */
    vm.updateDateFieldsWithNewValues = async function (fieldSlug, numberOfDaysToAdd) {

        // this will be checking if exist numberOfDaysToAdd and if doesn't it will set as 0
        let addNumberOfDays = numberOfDaysToAdd ? +numberOfDaysToAdd : 0;

        angular.forEach(vm.scanStationObj.fieldIdsThatChangeDates, async function (fieldCombination) {

            let dateSrcData = undefined;

            if (fieldCombination.fieldSlugWithNumberValue == fieldSlug) {

                let fieldDateObject = vm.scanStationObj.scanStationFieldIdsToField[fieldCombination.dateToAddFromFieldId];

                // This is the way to get the data from another field, or undefined if we can't have a data src
                if (fieldDateObject != null && fieldDateObject.field_slug != undefined) {
                    dateSrcData = vm.scanStationObj.dataToAdd[fieldDateObject.field_slug];
                } else {
                    dateSrcData = undefined;
                }

                let loadedNewDateData = DateService.currentDateToCheck(dateSrcData, addNumberOfDays);

                vm.scanStationObj.scanStationDatePickerFields[fieldCombination.fieldSlugToChange] = loadedNewDateData;
                await vm.updateDatePicker(fieldCombination.fieldSlugToChange);

            }

            // this case is checking that we have a field_slug to update the value, and only will be refreshing the data
            if (fieldCombination.triggerIfChanges != undefined && fieldCombination.triggerIfChanges.field_slug == fieldSlug) {

                // loading the days from the dataToAdd days quantity
                let fieldSlugToSearchForData = vm.scanStationObj.scanStationFieldIdsToField[fieldCombination.fieldIdSrc].field_slug;
                let days = vm.scanStationObj.dataToAdd[fieldSlugToSearchForData];

                // loading the value of the date that was changed
                let dateSrcSlugField = vm.scanStationObj.scanStationFieldIdsToField[fieldCombination.dateToAddFromFieldId];
                let changedDataValue = vm.scanStationObj.dataToAdd[dateSrcSlugField.field_slug];

                vm.refreshDatepicker(changedDataValue, fieldCombination.fieldSlugToChange, fieldCombination.triggerIfChanges.field_slug, days);
            }

        });
    }

    vm.today = async (fieldSlug) => {
        try {
            if (typeof (vm.scanStationObj.scanStationDatePickerFields) == 'undefined') {
                vm.scanStationObj.scanStationDatePickerFields = {};
            }

            vm.setScanStationDatePickerFieldValueFromEmpty(fieldSlug);
            await vm.updateDatePicker(fieldSlug);

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will update a datepicker.
     *
     * @param fieldSlug
     */
    vm.updateDatePicker = async (fieldSlug) => {
        try {
            if (typeof (vm.scanStationObj.scanStationDatePickerFields[fieldSlug]) == 'undefined') {
                vm.setScanStationDatePickerFieldValueFromEmpty(fieldSlug);
            }

            // adding the new date, making sure that we have 2-digit on day,month
            vm.scanStationObj.dataToAdd[fieldSlug] = DateService.formatDate(vm.scanStationObj.scanStationDatePickerFields[fieldSlug]);

            // Update of the field on get rules
            let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
            await vm.getRules('no-event', field.id, vm.scanStationObj.scanStationFieldIdsToField[field.id].special_field_key, vm.scanStationObj.dataToAdd[fieldSlug], field.fieldIndex, fieldSlug);

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateDateFromDataToAddField = function (fieldSlug) {
        try {
            if (vm.scanStationObj.isInEditMode && typeof vm.scanStationObj.scanStationDatePickerFields[fieldSlug] == 'undefined') {
                vm.scanStationObj.scanStationDatePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'DD/M/YYYY').toDate();
            }
            if (vm.scanStationObj.scanStationDatePickerFields[fieldSlug] != '' && vm.scanStationObj.scanStationDatePickerFields[fieldSlug] != null) {
                vm.scanStationObj.scanStationDatePickerFields[fieldSlug] = moment(vm.scanStationObj.dataToAdd[fieldSlug], 'DD/M/YYYY').toDate();
            }

        } catch (e) {
            console.log(e);
        }

    }

    function getDayClass(data) {
        try {
            let date = data.date,
                mode = data.mode;

            if (mode === 'day') {
                let dayToCheck = new Date(date).setHours(0, 0, 0, 0);
                for (let i = 0; i < vm.events.length; i++) {
                    let currentDay = new Date(vm.events[i].date).setHours(0, 0, 0, 0);
                    if (dayToCheck === currentDay) {
                        return vm.events[i].status;
                    }
                }
            }

            return '';

        } catch (e) {
            console.log(e);
        }

    }


    vm.updateEnterDataController = function (updatedData, dataField) {
        try {
            Data.updateEnterDataController(updatedData, dataField);
            // if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    };

    function checkFormceptionDuplicates() {
        try {
            if (vm.scanStationObj.formceptionData.length > 1) {
                let uniqueFormceptionData = [];
                angular.forEach(vm.scanStationObj.formceptionData, function (formceptionData, index) {
                    if (index > 0) {
                        if (formceptionData['formceptionParentField'] !==
                            vm.scanStationObj.formceptionData[index - 1]['formceptionParentField']) {
                            uniqueFormceptionData.push(formceptionData);
                        }
                    }
                });

                vm.scanStationObj.formceptionData = uniqueFormceptionData;
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.deleteData = async function (entryID) {
        try {

            let copyOfDataToAdd = JSON.parse(JSON.stringify(vm.scanStationObj.dataToAdd));

            await Data.deleteData(entryID);
            vm.scanStationObj.isInEditMode = false;

            await vm.getData(vm.scanStationObj.id);

            if (vm.filterData !== false) {
                await vm.getDataForDataFiltering('all', vm.filterDataOptions);
            }

            vm.scanStationObj.stationDataID = undefined;

            for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                let field = vm.scanStationObj.scanStationFields[i];
                if (field.triggerRulesBeforeDelete == 1) {
                    await vm.triggerRulesAndWaitFor1Second(field);
                    if (!$scope.$$phase) {$scope.$applyAsync();};
                }
            }

            // process Cross Bloc Data Fields on Submit
            if (vm.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterDelete.length > 0) {

                // build the array of field Ids to apply this to, hidden ones should not be applied. This gives control over the user to decide if they
                // want to apply the action or not

                let crossBlocDataUpdateFieldsOnDataAfterDelete = [];
                angular.forEach(vm.scanStationObj.crossBlocDataUpdateFieldsOnDataAfterDelete, function (fieldId, index) {
                    let isVisibleFromShowCondition = GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldIdsToField[fieldId]);
                    if (isVisibleFromShowCondition) {
                        crossBlocDataUpdateFieldsOnDataAfterDelete.push(fieldId);
                    }
                });

                // we don't need to await as we are not doing anything with this response

                CrossBlocDataUpdatesFieldFactory.triggerActions(crossBlocDataUpdateFieldsOnDataAfterDelete, copyOfDataToAdd, false);

            }

            await vm.resetData();

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    };

    /**
     * This function will trigger any maths field calculations
     * @param fieldSlug
     * @returns {Promise<void>}
     */
    vm.updateLocalMathsResults = async function (fieldSlug) {
        try {
            // first we check if we are working on a field that is related to a maths field, if not there is nothing to do
            if (vm.mathsTriggerFields != null && vm.mathsTriggerFields.includes(fieldSlug)) {

                // we build data to process, we only need to process data related to the maths field, not all fields
                let mathsFieldTriggerDataObject = {};

                for (let i = 0; i < vm.mathsTriggerFields.length; i++) {
                    mathsFieldTriggerDataObject[vm.mathsTriggerFields[i]] = vm.scanStationObj.dataToAdd[vm.mathsTriggerFields[i]];
                }

                let results: any = {};

                if (
                    vm.previousMathsFieldTriggerDataObject == null
                    || (JSON.stringify(vm.previousMathsFieldTriggerDataObject) != JSON.stringify(mathsFieldTriggerDataObject))) {
                    vm.previousMathsFieldTriggerDataObject = mathsFieldTriggerDataObject;
                    results = await MathsFactoryBLCXAU.updateMathsValue(vm.scanStationObj.id, mathsFieldTriggerDataObject, vm.mathsTriggerFields);
                    vm.previousMathsResults = results;

                } else {
                    results = vm.previousMathsResults;
                }

                // here we reset the maths field if we get back a null value

                if (results != null && results.data != null) {
                    for (let i = 0; i < vm.scanStationObj.scanStationFields.length; i++) {
                        let field = vm.scanStationObj.scanStationFields[i];
                        if (field.field_type == 'maths' && results.data[field.field_slug] != null) {
                            vm.scanStationObj.dataToAdd[field.field_slug] = results.data[field.field_slug];
                            await vm.setNumericField(field.id, field.field_slug, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex);
                        } else if (field.field_type == 'maths' && results.data[field.field_slug] == null) {
                            vm.scanStationObj.dataToAdd[field.field_slug] = '';
                        }
                    }
                }

                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.getTotals = async function (fieldSlug, totalResultsAssociatedFields, valueToFind) {
        try {
            if (valueToFind === undefined || valueToFind === 0 || !valueToFind || vm.scanStationObj.isInEditMode == true) {
                return;
            }
            if (typeof vm.scanStationObj.scanStationFieldIdsToField[totalResultsAssociatedFields.keyFieldId] != 'undefined' && typeof vm.scanStationObj.scanStationFieldIdsToField[totalResultsAssociatedFields.fieldIdToCount] != 'undefined') {
                let parentField = vm.scanStationObj.scanStationFieldIdsToField[totalResultsAssociatedFields.keyFieldId].field_slug;
                let childField = vm.scanStationObj.scanStationFieldIdsToField[totalResultsAssociatedFields.fieldIdToCount].field_slug;
                let total = 0;


                angular.forEach(vm.scanStationObj.liveData, function (row) {
                    if (row[parentField] == valueToFind) {
                        if (row[childField] != 'N/A') {
                            total += parseFloat(row[childField]);
                        }
                    }
                });

                if (total) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = total;
                    await vm.setNumericField(vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].id, fieldSlug, vm.scanStationObj.dataToAdd[fieldSlug], vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].fieldIndex);
                    if (!$scope.$$phase) {$scope.$applyAsync();};
                } else if (total == 0) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = '0';
                }
                //TODO FUTURE REVIEW WHY THIS WAS BEING CALLED
                // This is related somehow to viewing all the results field underneath, but its unused functionality
                //await vm.getTotalsResults();
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.getTotalsResults = async function () {
        try {
            vm.allResultsArray = {};
            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {
                if (field.field_type == 'total-results' && typeof vm.scanStationObj.scanStationFieldIdsToField[field.totalResultsAssociatedFields.keyFieldId] != 'undefined' && typeof vm.scanStationObj.scanStationFieldIdsToField[field.totalResultsAssociatedFields.fieldIdToCount] != 'undefined') {
                    let parentField = vm.scanStationObj.scanStationFieldIdsToField[field.totalResultsAssociatedFields.keyFieldId].field_slug;
                    let valueToFind = vm.scanStationObj.scanStationFieldIdsToField[field.totalResultsAssociatedFields.fieldIdToCount].field_slug;
                    await vm.getTotals(field.field_slug, field.totalResultsAssociatedFields, valueToFind);
                    vm.allResultsArray[field.field_name] = vm.scanStationObj.dataToAdd[field.field_slug];
                }
            });

        } catch (e) {
            console.log(e);
        }
    }

    vm.createFieldVisibilityParameters = function () {
        vm.fieldVisibilityParameters = {
            'Hide in Excel Report': 'report_list',
            'Hide in Data Results Box': 'results_box',
            'Visually Hide in the form but remain functional': 'in_form',
            'Hide in Mail Alerts': 'hide_from_mail_alerts',
            'Hide from External Data Look Up': 'hide_from_external_data_lookup',

        }
    }

    vm.setFieldVisibilityParameter = async function (scanStationId, fieldID, visibilityParam) {
        try {
            await scanStation.setFieldVisibilityParameter(scanStationId, fieldID, visibilityParam);
            await vm.getFieldVisibilityOptions(fieldID);
            await vm.triggerBlocUpdateSuccess('Field Visibilty Parameter Added');
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }

    vm.getFieldVisibilityOptions = async function (fieldID) {
        try {
            let data = await scanStation.getFieldVisibilityOptions(fieldID);
            vm.currentFieldVisibilityParameters = data.data.data;
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }

    vm.deleteFieldVisibilityOptions = async function (hiddenFieldOption) {
        try {
            let fhID = hiddenFieldOption.id;
            await scanStation.deleteFieldVisibilityOptions(fhID);
            await vm.getFieldVisibilityOptions(hiddenFieldOption.field_id);
            await vm.triggerBlocUpdateSuccess('Field Visibilty Parameter Removed');


            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.setFormVisibilityParameter = async function (scanStationID, visibilityParam) {
        try {
            await scanStation.setFormVisibilityParameter(scanStationID, visibilityParam);
            await vm.getFormVisibilityParameter(scanStationID);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }

    vm.getFormVisibilityParameter = async function (scanStationID) {
        try {
            let data = await scanStation.getFormVisibilityParameter(scanStationID);
            vm.currentFormVisibilityParameter = data.data.data;

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.printToPDF = async function (scanStationID, dataToAdd) {
        try {
            vm.reportDownloadUrl = "";
            let response = await scanStation.printToPDF(scanStationID, dataToAdd, vm.chosenTemplateFile, vm.pdfOrWord);
            let blob = new Blob([response.data], {
                type: 'application/octet-stream'
            });

            let URL = window.URL || window.webkitURL;
            vm.reportDownloadUrl = URL.createObjectURL(blob);


            let element = await vm.getElementToClick('downloadPdfBtn');

            if (!$scope.$$phase) {$scope.$applyAsync();};
            element.click();

        } catch (e) {
            console.log(e);
        }

    }

    vm.getElementToClick = function (element) {
        return document.getElementById(element);
    }


    vm.editCheckFields = async function (entryRow) {
        try {
            vm.alreadyUpdatedSubFormChildFields = [];

            // factory step: vm.subformArray
            angular.forEach(entryRow, function (data, dataSlug) {
                angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
                    let field = vm.scanStationObj.scanStationFieldSlugsToField[subFormSlug];
                    angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                        if (typeof vm.scanStationObj.scanStationFieldSlugsToField[dataSlug] != 'undefined' && vm.scanStationObj.scanStationFieldSlugsToField[dataSlug].id == parentChildCombo.parentFieldId) {
                            vm.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug);
                        }
                    });
                });
            });

            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {

                angular.forEach(entryRow, async function (data, key) {
                    if (key == field.field_slug) {
                        if (field.field_type == 'date-time-selector') {
                            if (field.dateTimeSetting == 'time') {
                                vm.updateTimeFromDataToAddField(field.field_slug);
                            }
                            if (field.dateTimeSetting == 'date') {
                                vm.updateDateFromDataToAddField(field.field_slug);
                            }
                        }
                        if (field.field_type == 'logged-in-user-info') {

                            // TODO clean up confusion with username from main.username,

                            if (field.userInfoRequired != 'username') {
                                //vm.scanStationObj.dataToAdd[field.field_slug] = '';vm.scanStationObj.dataToAdd[field.field_slug] = vm.loggedInUsersName;
                            }
                            if (field.userInfoRequired == 'username') {
                                //vm.scanStationObj.dataToAdd[field.field_slug] = '';vm.scanStationObj.dataToAdd[field.field_slug] = vm.loggedInUsersEmail;
                            }
                        }
                    }
                });
            });
        } catch (e) {
            console.log(e);
        }

    }

    vm.getFilteredData = async function (dataFilteringData, fieldSlug, fieldIdOrFieldSlug) {
        try {
            // setting the filtering data
            vm.scanStationObj.dataToAdd[fieldSlug] = JSON.stringify(dataFilteringData);

            let data = await Data.getDataForDataFiltering(dataFilteringData, fieldIdOrFieldSlug);
            vm.scanStationObj.parseBoxResults[fieldSlug] = data.data.data;

            angular.forEach(vm.scanStationObj.parseBoxResults[fieldSlug], function (row, rowKey: string) {

                // getDataForDataFiltering sends back other information, but we are only interested in the list of data which is integers for keys

                if (isNaN(parseInt(rowKey))) {
                    delete vm.scanStationObj.parseBoxResults[fieldSlug][rowKey];
                }
            });

            // getting the data of result box
            await fieldService.loadResultBoxData(vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug], vm);

        } catch (e) {
            console.log(e);
        } finally {
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    }

    vm.openInNewTab = function (type, param1, param2) {
        try {
            let url = '';

            if (type == 'scan-station') {
                url = $state.href('scan-station', {id: param1, stationId: param2});
            } else if (type == 'module') {
                url = $state.href('module-form', {uniqueModuleSlug: param1, stationId: param2});
            }
            $window.open(url, '_blank');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateDynamicCheckboxField = function (fieldSlug, populateCheckedValueElseWhereFieldID, event) {
        try {
            if (event.target.checked == true) {
                let fieldSlugToGetDataFrom = vm.scanStationObj.scanStationFieldIdsToField[populateCheckedValueElseWhereFieldID].field_slug;
                vm.scanStationObj.dataToAdd[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlugToGetDataFrom];
                vm.scanStationObj.checkboxesWithDynamicDataFields[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlug];
            } else {
                vm.scanStationObj.checkboxesWithDynamicDataFields[fieldSlug] = 'blocworx-empty';
                vm.scanStationObj.dataToAdd[fieldSlug] = '';
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.flipResults = function () {
        try {
            // let resultsHeadWidth = $('.results-thead').outerWidth();
            // $('.form-results-section tbody').css('position','absolute');
            // $('.form-results-section tbody').css('left',resultsHeadWidth + 10 + 'px');
            // let elem = $('.form-results-tbody').children().last();
            // //let offset = elem.offset().left - elem.parent().offset().left;
            // alert(elem.offset().right);

            //if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.updateAutoGenerateFields = async function () {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, async function (field) {
                if (!vm.isEditOrViewMode() && field.field_type.includes('auto-generate') ) {
                    await vm.getAutoGenerateFieldData(field, field.field_type, field.field_slug)
                }
            });

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This is starting the hide from mobile and hide from tablet
     * field parameter.
     *
     * @param field
     */
    vm.startHideFromMobileAndHideFromTablet = (field) => {

        if (field.hideFromMobile == undefined) {
            field.hideFromMobile = 0
        }

        if (field.hideFromTablet == undefined) {
            field.hideFromTablet = 0
        }
    }


    /**
     * This will be responsible to load a scan station field parameter by sharing the
     * field and field parameter.
     *
     * @param field
     * @param fieldParameter
     */
    vm.loadScanStationFieldParameter = async function (field, fieldParameter, shouldBeNumber = true) {
        try {
            // loading the fields parameters of this field id
            let fieldsParameters = await fieldService.getAllFieldParameters(field.id);

            // getting the element that match the field parameter
            const matchingData = fieldsParameters.filter(
                (element) => element.scan_station_field_parameter === fieldParameter
            );

            // if this is found, update the value on the field with specific field parameter value
            // registered on database
            if (matchingData.length > 0) {
                field[fieldParameter] = shouldBeNumber ? parseInt(matchingData[0].parameter_value) : matchingData[0].parameter_value;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This will use the field id, field parameter and respective value to
     * use the scanStation service to update the value of the scan
     * station parameter.
     *
     * @param fieldID
     * @param fieldParameter
     * @param paramValue
     * @param parameterInstruction
     */
    vm.setScanStationFieldParameter = async function (fieldID, fieldParameter, paramValue, parameterInstruction = '') {
        try {
            vm.updating = true;
            let data = await scanStation.setScanStationFieldParameter(fieldID, fieldParameter, paramValue, parameterInstruction);
            $rootScope.$broadcast('reloadSpecificGrids', [vm.modifyingGridId]);

            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Field Parameter Updated');

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }


    vm.checkShowCounter = function (fieldSlug) {
        try {

            if (vm.scanStationObj.stationDetails.preventCounting != 1) {
                angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                    if (fieldSlug == field.field_slug) {
                        angular.forEach(vm.scanStationObj.liveData.fieldCounts, function (value, liveDataSlug) {

                            if (fieldSlug == liveDataSlug && field.show_counter == 1 && field.includeForShowCounter == 1) {

                                angular.forEach(value, function (data, valueSlug) {
                                    if (vm.scanStationObj.dataToAdd[fieldSlug] === valueSlug) {
                                        vm.fieldEntryCount[fieldSlug][vm.scanStationObj.dataToAdd[fieldSlug]] =
                                            vm.scanStationObj.liveData.fieldCounts[liveDataSlug][valueSlug] + 1;
                                    }
                                });

                            }

                        });
                    }
                })
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkIfExcludedFromLastEntryDataPopulating = function (fieldSlug) {
        try {
            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                if (fieldSlug == field.field_slug) {
                    if (typeof (field.excludeFromGetLatestData) != 'undefined' && field.excludeFromGetLatestData == '1') {
                        alert('true ' + field.field_slug)
                        return true;
                    } else {
                        alert('false ' + field.field_slug)
                        return false;
                    }
                }
            });

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.exportFilteredData = function () {
        try {
            let blob = new Blob([document.getElementById('exportable').innerHTML], {
                type: "text/csv"
            });
            saveAs(blob, "Report.csv");

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.calculateTimeDifference = function (fieldSlug, field, timeDiffField) {
        try {
            if (field.timeOrDateDifferenceOption === 'time') {
                let x = moment(vm.scanStationObj.dataToAdd[timeDiffField.startFieldSlug], field.timeDateDifferenceFormat, true);
                let y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormat, true);

                // optional format for the second field, defaults to the first format if none exists
                if (field.timeDateDifferenceFormatForEndField != null && field.timeDateDifferenceFormatForEndField != '') {
                    y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormatForEndField, true);
                }

                if (!x.isValid() || !y.isValid()) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = 'Incorrect Format'
                } else {
                    vm.scanStationObj.dataToAdd[fieldSlug] = moment.utc(y.diff(x)).format("HH:mm");
                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'with-decimal-points') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = parseFloat(moment.duration(vm.scanStationObj.dataToAdd[fieldSlug]).asHours().toFixed(2));
                    }

                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'round-to-hours') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = Math.round(moment.duration(vm.scanStationObj.dataToAdd[fieldSlug]).asHours());
                    }


                }

            } else if (field.timeOrDateDifferenceOption === 'date') {
                let x = moment(vm.scanStationObj.dataToAdd[timeDiffField.startFieldSlug], field.timeDateDifferenceFormat, true);
                let y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormat, true);

                // optional format for the second field, defaults to the first format if none exists
                if (field.timeDateDifferenceFormatForEndField != null && field.timeDateDifferenceFormatForEndField != '') {
                    y = moment(vm.scanStationObj.dataToAdd[timeDiffField.finishFieldSlug], field.timeDateDifferenceFormatForEndField, true);
                }


                if (!x.isValid() || !y.isValid()) {
                    vm.scanStationObj.dataToAdd[fieldSlug] = 'Incorrect Format'
                } else {
                    vm.scanStationObj.dataToAdd[fieldSlug] = y.diff(x, 'days');
                    if (typeof field.defaultResultFormat != 'undefined' && field.defaultResultFormat == 'with-decimal-points') {
                        vm.scanStationObj.dataToAdd[fieldSlug] = y.diff(x, 'days', true);
                    }

                }
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }


    vm.setScanStationParameter = async function (stationID, stationParam, paramValue) {
        try {
            vm.updating = true;
            let data = await scanStation.setScanStationParameter(stationID, stationParam, paramValue);
            if (data.data.data == 'Successfully Created') {

                // TODO: Get function must return things to add to something, this sounds like builder function
                await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'non-fields-related');
            }
            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Bloc Setting Applied');


            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This is the new way to add a scan station parameter, this is a specific way to do it
     * with some validations for the archive case.
     *
     * @param stationID id of the scan station
     * @param stationParam what is the param that you will be setting
     * @param paramValue what is the value to set
     */
    vm.setScanStationArchivedParameter = async function (parameters) {
        try {
            // loading the parameters for this function
            let scanStationId = parameters?.scanStationId;
            let scanStationParameter = parameters?.scanStationParameter;

            let scanStationParameterValue = parameters?.scanStationParameterValue;

            // this will be the validators place, as we do not have only numbers
            let onlyNumbers = /^[0-9]+$/;
            if (!onlyNumbers.test(scanStationParameterValue)) {

                // validation for numbers lower than 0
                const lowerThanZero = /^-[0-9]+$/;
                if (lowerThanZero.test(scanStationParameterValue)) {
                    throw new Error("Can't accept values lower than 0");
                }

                // if isn't lower than 0 is the case that contains invalid values
                throw new Error("Param value needs to be an integer");

            }

            // passing the validators we can store the data
            vm.updating = true;
            let data = await scanStation.setScanStationParameter(scanStationId, scanStationParameter, scanStationParameterValue);
            if (data.data.data == 'Successfully Created') {
                await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'non-fields-related');
            }
            vm.updating = false;

            await vm.triggerBlocUpdateSuccess('Bloc Setting Applied');
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (exception) {
            vm.triggerBlocError(exception);
        }

    }

    vm.removeScanStationParameter = async function (stationID, stationParam) {
        try {
            vm.updating = true;
            let data = await scanStation.removeScanStationParameter(stationID, stationParam);
            vm.updating = false;
            await vm.triggerBlocUpdateSuccess('Bloc Setting Updated');
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    /**
     * This method will trigger the update of the archived data
     * of a scan station, by using its id.
     *
     * @param stationID
     */
    vm.unArchiveData = async function (stationID) {
        try {
            await scanStation.unArchiveData(stationID);
            if (!$scope.$$phase) {$scope.$applyAsync();};
        } catch (exception) {
            vm.triggerBlocError(exception?.data?.message);
        }

    }


    vm.createEmailAlertParam = async function (stationID, stationParam, emailAddressParam, emailParam) {
        try {
            let paramValue = {
                emailAddressParam: emailAddressParam,
                emailParam: emailParam
            };
            await vm.setScanStationParameter(stationID, stationParam, paramValue);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }


    }

    vm.getSelectData = async function (scanStationID, fieldSlug) {
        try {
            let data = await Data.getSelectData(scanStationID, fieldSlug);
            vm.emailAlertOptionEmails = [];
            vm.emailAlertOptionEmailsResults = [];

            angular.forEach(data.data.data, function (datum) {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(datum[fieldSlug])) {
                        vm.emailAlertOptionEmailsResults.push(datum[fieldSlug])
                    }
                }
            )

            if (vm.emailAlertOptionEmailsResults.length > 0) {
                vm.emailAlertOptionEmails.push(vm.emailAlertOptionEmailsResults[vm.emailAlertOptionEmailsResults.length - 1]);
            }


            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.emailAlertOptionRemoveEmailAddress = function (index, email) {
        try {
            vm.removedEmailAddress.push(email);
            vm.emailAlertOptionEmails.splice(index, 1);
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.emailAlertOptionAddRemovedEmailAddress = function (index, email) {
        try {
            vm.emailAlertOptionEmails.push(email);
            vm.removedEmailAddress.splice(index, 1);
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.setDataAddSuccess = async function (dataAddSuccess) {
        vm.dataAddSuccess = dataAddSuccess;
    }

    /**
     * Creating function that can wait for a specific time, this will allow
     * us to wait for some time (in miliseconds), and after that any
     * other sync/async function can run afterwards.
     *
     * @param seconds
     */
    vm.waitFor = async (seconds) => new Promise(resolve => setTimeout(resolve, seconds))

    /**
     * This function is responsible to hide the message after we add some data in it.
     */
    vm.hideDataAddSuccessMsg = async function () {

        // this will sleep for the time on vm.maxTimeoutOnScanStation
        await vm.waitFor(vm.maxTimeoutOnScanStation);

        // setting to remove the add to data after vm.maxTimeoutOnScanStation
        await vm.setDataAddSuccess(0);
    }

    vm.addToAndGateParamArray = function (fieldSlug, fieldSlugToAdd, expectedValue) {
        try {
            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug] = {};
            }
            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug]['fields'] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug]['fields'] = {};
            }

            if (typeof fieldSlugToAdd == 'undefined' || typeof expectedValue == 'undefined') {
                alert('Field or Expected Value cannot be empty');
            } else {
                vm.scanStationObj.andGateParamArray[fieldSlug]['fields'][fieldSlugToAdd] = expectedValue;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.addParamsToAndGateParamArray = function (fieldSlug, index, expectedValue) {
        try {
            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug] = {};
            }

            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug][index] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug][index] = {};
            }

            if (typeof expectedValue == 'undefined') {
                vm.scanStationObj.andGateParamArray[fieldSlug][index] = expectedValue;
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.initAndGateParams = function (fieldSlug) {
        try {
            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug] = {};
            }

            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug]['pass'] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug]['pass'] = '';
            }

            if (typeof vm.scanStationObj.andGateParamArray[fieldSlug]['fail'] == "undefined") {
                vm.scanStationObj.andGateParamArray[fieldSlug]['fail'] = '';
            }


        } catch (e) {
            console.log(e);
        }

    }

    vm.removeFromAndGateParamArray = function (fieldSlug, fieldSlugToRemove) {
        try {
            delete vm.scanStationObj.andGateParamArray[fieldSlug]['fields'][fieldSlugToRemove];

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.createAndGateParam = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationFieldParameter(fieldID, param, (JSON.stringify(paramValue)));

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    // method related to the conditional value field parameter
    vm.parseAndGateParamCheckForPass = function (paramsField, ownerFieldSlug) {
        try {
            let pass = true;
            //

            let paramsFieldToUpdate = angular.copy(paramsField);
            // remove ones we may want to ignore
            angular.forEach(paramsField, function (expectedData, fieldSlug) {
                if (vm.scanStationObj.scanStationFieldSlugsToField[ownerFieldSlug] != null && vm.scanStationObj.scanStationFieldSlugsToField[ownerFieldSlug].conditionalValueIgnoreHiddenFields == 1 && !GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug])) {
                    delete paramsFieldToUpdate[fieldSlug];
                }
            });
            angular.forEach(paramsFieldToUpdate, function (expectedData, fieldSlug) {
                // ignore fields if the setting is turned on
                if (expectedData.includes('between:') && pass == true) {
                    let expectedDataRemoveText = expectedData.replace('between:', '');
                    let numberSplit = expectedDataRemoveText.split(',');
                    let lowerNumber = parseFloat(numberSplit[0]);
                    let higherNumber = parseFloat(numberSplit[1]);
                    let numberToCheck = parseFloat(vm.scanStationObj.dataToAdd[fieldSlug]);
                    if (isNaN(lowerNumber) || isNaN(higherNumber) || isNaN(numberToCheck) || numberToCheck < lowerNumber || numberToCheck > higherNumber) {
                        pass = false;
                    } else {
                        pass = true;
                    }

                } else if (pass) {
                    if (vm.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStationObj.dataToAdd[fieldSlug] == expectedData) {
                        pass = true;
                    } else {
                        pass = expectedData == '[blocworx-any-word]' && vm.scanStationObj.dataToAdd[fieldSlug] != null && vm.scanStationObj.dataToAdd[fieldSlug] != '';
                    }
                }
            });

            return pass;

        } catch (e) {
            console.log(e);
        }

    }

    vm.parseAndGateParamCheckForPending = function (paramsField, ownerFieldSlug) {
        try {
            let pending = true;

            let paramsFieldToCheck = angular.copy(paramsField);

            // remove ones we may want to ignore

            angular.forEach(paramsField, function (expectedData, fieldSlug) {
                if (vm.scanStationObj.scanStationFieldSlugsToField[ownerFieldSlug] != null && vm.scanStationObj.scanStationFieldSlugsToField[ownerFieldSlug].conditionalValueIgnoreHiddenFields == 1 && !GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug])) {
                    delete paramsFieldToCheck[fieldSlug];
                }
            });

            angular.forEach(paramsFieldToCheck, function (expectedData, fieldSlug) {
                pending = ((
                        typeof vm.scanStationObj.dataToAdd[fieldSlug] == 'undefined')
                    || vm.scanStationObj.dataToAdd[fieldSlug] == ''
                    || !GridService.scanStationFormValidate(vm, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug]));
            });

            return pending;

        } catch (e) {
            console.log(e);
        }

    }

    vm.parseAndGateParam = async function (fieldID, specialFieldKey, currentSelectedFieldIndex) {
        try {
            if (vm.scanStationObj.andGateParamArray != null && vm.scanStationObj.andGateParamArray != 'null' && Object.keys(vm.scanStationObj.andGateParamArray).length != 0) {


                angular.forEach(vm.scanStationObj.andGateParamArray, async function (params, index) {
                    let resultChange = false;
                    let andGatePending = vm.parseAndGateParamCheckForPending(params['fields'], index);
                    if (!andGatePending) {
                        let andGateSuccess = vm.parseAndGateParamCheckForPass(params['fields'], index);
                        if (!andGateSuccess) {
                            if (params['fail'] != null && vm.scanStationObj.dataToAdd[index] != params['fail']) {
                                if(params['fail'] != 'IGNORE') {
                                    vm.scanStationObj.dataToAdd[index] = params['fail'];
                                    resultChange = true;
                                }
                            }
                        } else {
                            if (params['pass'] != null && vm.scanStationObj.dataToAdd[index] != params['pass']) {

                                if(params['pass'] == 'CLEARVALUE') {
                                    vm.scanStationObj.dataToAdd[index] = "";
                                } else {
                                    vm.scanStationObj.dataToAdd[index] = params['pass'];
                                }
                                if(vm.scanStationObj.scanStationFieldSlugsToField[index].focusOnFieldAfterConditionalValueMet == 1) {

                                    let fieldId = vm.scanStationObj.scanStationFieldSlugsToField[index].id;

                                    await vm.triggerClickByFieldIDTriggerAction(fieldId);
                                }


                                resultChange = true;
                            }
                        }
                    } else {
                        if (params['pending'] != null && vm.scanStationObj.dataToAdd[index] != params['pending']) {
                            if(params['pending'] != 'IGNORE') {
                                vm.scanStationObj.dataToAdd[index] = params['pending'];
                                resultChange = true;
                            }
                        }
                    }

                    if (resultChange) {
                        await vm.getRules('no-event', fieldID, specialFieldKey, vm.scanStationObj.dataToAdd[index], currentSelectedFieldIndex, index);
                        await vm.triggerBackgroundDataUpdate(vm.scanStationObj.dataToAdd, vm.scanStationObj.id, vm.scanStationObj.dataToAdd[index]);
                    }
                });
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }


    function checkForNullAlert(param) {
        try {
            let notNull = false

            if (arguments.length > 0 && !notNull) {
                angular.forEach(arguments, function (arg) {
                    if (typeof arg == 'undefined' || arg.length < 0) {
                        notNull = true;
                        return notNull;
                    }
                });
            }

            return notNull;

        } catch (e) {
            console.log(e);
        }

    }

    // rewrite of the first function to accept array
    function checkForNullValues(valuesToCheck: Array<any>) {
        try {
            let notNull = false;
            if (valuesToCheck.length > 0) {
                angular.forEach(valuesToCheck, function (arg) {
                    if (typeof arg === 'undefined' || arg === null) {
                        notNull = true;
                        return notNull;
                    }
                })
            }

            return notNull;

        } catch (e) {
            console.log(e);
        }

    }

    vm.restrictByUsernameAddField = function (fieldToAdd) {
        try {
            if (typeof vm.scanStationObj.stationDetails.restrictDataByUsername == 'undefined'
                || vm.scanStationObj.stationDetails.restrictDataByUsername == null) {
                vm.scanStationObj.stationDetails.restrictDataByUsername = [];
            }

            vm.scanStationObj.stationDetails.restrictDataByUsername.push(fieldToAdd);

        } catch (e) {
            console.log(e);
        }

    }

    vm.restrictByUsernameDeleteField = function (index) {
        try {
            vm.scanStationObj.stationDetails.restrictDataByUsername.splice(index, 1);

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.setScanStationParameterJSON = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationParameter(fieldID, param, (JSON.stringify(paramValue)));
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.setScanStationParameterCommaSeparated = async function (fieldID, param, paramValue) {
        try {
            await vm.setScanStationParameter(fieldID, param, paramValue.toString());
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.checkInIFrame = function () {
        try {
            return window.self === window.top;

        } catch (e) {
            console.log(e);
        }

    }

    // two way communication between sub blocs and parent blocs

    $window.addEventListener('message', async function (data) {
        try {
            // Reading from Child Sub Bloc
            // Update Data based on data passed down from parent Bloc
            if (typeof (data.data.stationID) != 'undefined') {
                if (data.data.stationID == vm.scanStationObj.id) {
                    angular.forEach(vm.scanStationObj.scanStationFields, async function (field, index) {
                        if (field.id == data.data.childFieldId) {

                            vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[data.data.childFieldId].field_slug] = data.data.updatedData;

                            // TODO when refactor this into its own component we can remove the time out because it will be called after the field-actions component
                            setTimeout(async function () {
                                await vm.getRules('no-event', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], index, field.field_slug);

                                await vm.triggerBackgroundDataUpdate(vm.scanStationObj.dataToAdd, vm.scanStationObj.id, vm.scanStationObj.dataToAdd[field.field_slug]);

                                if (!$scope.$$phase) {$scope.$applyAsync();};
                            }, 500)

                        }
                    });

                    // a new sub bloc option to show the last entry in the sub bloc, common for sub blocs that only have one piece of data
                    if (data.data.clickShowMoreLastEntry == 1) {
                        vm.thisSubBlocLoadsLatestData = true;
                    }

                }
            }

            // Listening in Child Sub Bloc
            // Make the Sub Bloc field paramaters and details available inside the sub bloc to make use of more options
            if (data.data.subBlocFieldDetails != null) {
                vm.inSubBlocOwnerFieldDetails = data.data.subBlocFieldDetails;
                if (vm.inSubBlocOwnerFieldDetails.compactView == 1) {
                    $('.is-in-iframe').addClass('compact-view');
                }
            }

            // Listening in Parent Bloc
            if (data.data == 'updateAllSubForms') {
                angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                    if (field.field_type == 'formception-2') {
                        vm.updateAllSubForms();
                    }
                });

            }


        } catch (e) {
            console.log(e);
        }

    });


    function setSubFormFiltering(stationID, fieldSlug, data) {
        try {
            let dataFilteringData = <DataFiltering>{};
            dataFilteringData.stationID = stationID
            dataFilteringData.resultLimit = 10;
            dataFilteringData.currentPage = 1;
            dataFilteringData.filterDataToQuery = {};

            if (data.length > 0) {
                dataFilteringData.filterDataToQuery[fieldSlug] = [data];
            }

            return dataFilteringData;

        } catch (e) {
            console.log(e);
        }

    }

    vm.fetchSubFormDataFromParent = function () {
        window.parent.postMessage('updateAllSubForms', '*');
    }


    vm.updateAllSubForms = function () {
        angular.forEach(vm.scanStationObj.dataToAdd, function (data, fieldSlug) {
            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != null) {
                let field_id = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].id;
                angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
                    let fieldSlugToUpdate = null;
                    let field = vm.scanStationObj.scanStationFieldSlugsToField[subFormSlug];
                    angular.forEach(field.subFormParentChildFields, function (parentChildCombo) {
                        if (field_id == parentChildCombo.parentFieldId) {
                            vm.updateSubForm(data, subFormDatum['subFormParentChildFields'], parentChildCombo.childFieldId, subFormSlug, subFormDatum['clickShowMoreLastEntry']);
                        }
                    });

                });
            }
        });

        angular.forEach(vm.subformArray, function (subFormDatum, subFormSlug) {
            // set the sub bloc field details to make available inside the sub bloc
            vm.setSubBlocFieldDetails(subFormSlug);
        });

    }

    /**
     *  Method to set the sub bloc field details inside the sub bloc to allow for more functionality based on field settings
     *  @param subFormSlug
     */

    vm.setSubBlocFieldDetails = function (subFormSlug) {
        try {
            let dataToSend = {
                subBlocFieldDetails: vm.scanStationObj.scanStationFieldSlugsToField[subFormSlug],
            }

            let subform = <HTMLIFrameElement>document.getElementById(vm.subformArray[subFormSlug]['subFormElementID']);
            if (subform != null) {
                subform.contentWindow.postMessage(dataToSend, '*');
            }

        } catch (e) {
            console.log(e);
        }
    }

    vm.updateSubForm = function (updatedData, masterParentFields, childFieldId, subFormSlug, clickShowMoreLastEntry) {
        try {
            let dataToSend = {
                stationID: vm.subformArray[subFormSlug]['subFormID'],
                updatedData: updatedData,
                childFieldId: childFieldId,
                clickShowMoreLastEntry: clickShowMoreLastEntry
            }

            let subform = <HTMLIFrameElement>document.getElementById(vm.subformArray[subFormSlug]['subFormElementID']);
            if (subform != null) {
                subform.contentWindow.postMessage(dataToSend, '*');
            }

        } catch (e) {
            console.log(e);
        }
    }


    vm.buildSubFormChildValues = async function (subFormParentChildFields, fieldSlug, formceptionLink) {
        try {
            let dataToPassInUrlForThisForm = {};
            vm.dataToPassInUrls = [];
            $scope.$watch(vm.scanStationObj.dataToAdd, function () {

                angular.forEach(subFormParentChildFields, function (subFormParentChildFieldPair) {
                    if (subFormParentChildFieldPair.parentFieldId != null && typeof vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[subFormParentChildFieldPair.parentFieldId].field_slug] != 'undefined') {
                        dataToPassInUrlForThisForm[subFormParentChildFieldPair.childFieldId] = vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[subFormParentChildFieldPair.parentFieldId].field_slug];
                    }
                });
                if (Object.keys(dataToPassInUrlForThisForm).length !== 0) {
                    formceptionLink = formceptionLink + '?urlData=' + JSON.stringify(dataToPassInUrlForThisForm).replace(/&/g, "[bw]ampersand[/bw]");
                }

                vm.finalFormceptionLink[fieldSlug] = formceptionLink;
            });


        } catch (e) {
            console.log(e);
        }

    }

    vm.loadJsonDataFromUrl = async function () {
        try {

            if (typeof ($state.params.urlData) != 'undefined' && typeof ($state.params.urlData) != 'undefined') {
                let urlData = JSON.parse($state.params.urlData);
                angular.forEach(urlData, function (value, fieldId) {
                    if (value != null) {
                        value = value.replace('[bw]ampersand[/bw]', '&');
                        if (typeof vm.scanStationObj.scanStationFieldIdsToField[fieldId] != 'undefined') {
                            vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[fieldId].field_slug] = value;
                            let fieldIndex = 0;
                            // TODO See can we use indexOf
                            angular.forEach(vm.scanStationObj.scanStationFields, function (fieldValue, index) {
                                if (fieldId == fieldValue.id) {
                                    fieldIndex = index;
                                }
                            });

                            // TODO when refactor this into its own component we can remove the time out because it will be called after the field-actions component
                            setTimeout(async function () {
                                vm.getRules('no-event', fieldId, vm.scanStationObj.scanStationFieldIdsToField[fieldId].special_field_key, value, fieldIndex, vm.scanStationObj.scanStationFieldIdsToField[fieldId].field_slug);
                            }, 500);
                        }
                    }
                });
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

            if (typeof ($state.params.urlFieldSlugData) != 'undefined' && typeof ($state.params.urlFieldSlugData) != 'undefined') {

                // Preprocess JSON string to treat all values as strings
let urlFieldSlugData = JSON.parse(decodeURIComponent($state.params.urlFieldSlugData));

                // we need to create an event
                let event = {
                    fromUrl: true
                };

                if ($state.params.fieldSlugsFilterOnly == "true") {

                    angular.forEach(urlFieldSlugData, function (value, fieldSlug) {
                        vm.addConditionForFieldSearch(fieldSlug, value, event);
                    });

                } else {
                    angular.forEach(urlFieldSlugData, function (value, fieldSlug) {
                        if (typeof vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {
                            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].preventDataPopulationIfLoadedFromUrl != 1) {
                                vm.scanStationObj.dataToAdd[fieldSlug] = value;
                                let fieldIndex = 0;
                                // TODO See can we use indexOf
                                angular.forEach(vm.scanStationObj.scanStationFields, function (fieldValue, index) {
                                    if (fieldSlug == fieldValue.field_slug) {
                                        fieldIndex = index;
                                    }
                                });

                                setTimeout(function () {
                                    vm.getRules('no-event', vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].id, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].special_field_key, value, fieldIndex, vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].field_slug);
                                }, 500);
                            }

                            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].filterDirectlyFromUrl == 1) {
                                vm.addConditionForFieldSearch(fieldSlug, value, event);
                            }
                        }
                    });
                }
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

        } catch (e) {
            console.log(e);
        }

    }

    vm.dataToFormSearchStations = function (input, fieldSlug) {
        try {
            let result = []
            angular.forEach(vm.dataToFormStations[fieldSlug], function (foundData) {
                if (typeof foundData['scan_station_name'] != 'undefined' && foundData['scan_station_name'].toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0) {
                    result.push(foundData['scan_station_name']);
                }
            });
            vm.dataToFormSearchResults[fieldSlug] = result;

        } catch (e) {
            console.log(e);
        }

    }

    vm.dataToFormGetJobScanStations = async function (jobID, fieldSlug) {
        try {
            if (vm.scanStationObj.stateName == 'edit-bloc') {
                return true;
            }
            if (jobID == null) {
                jobID = vm.scanStationObj.jobID;
            }

            let data = await scanStation.getJobScanStations(jobID, '');
            vm.dataToFormStations[fieldSlug] = data.data.data;
            await vm.buildDataToFormsJSONData(fieldSlug);
            if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].formListLatestDataSlugs != null) {
                await vm.buildBlocListLatestData(fieldSlug);
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.buildDataToFormsJSONData = async function (fieldSlug) {
        try {
            if (typeof vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {
                let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
                if (typeof (field.formListLocalRemoteFields) != 'undefined') {
                    vm.jsonDataForDataToFormFields[fieldSlug] = [];
                    // first get the parents and children
                    let parentChildFieldCominationsObject = {};
                    angular.forEach(field.formListLocalRemoteFields, function (value) {
                        if (typeof vm.scanStationObj.scanStationFieldIdsToField[value.localFieldId] != 'undefined') {
                            parentChildFieldCominationsObject[value.remoteFieldSlug] = vm.scanStationObj.dataToAdd[vm.scanStationObj.scanStationFieldIdsToField[value.localFieldId].field_slug];
                        }
                    });
                    vm.jsonDataForDataToFormFields[fieldSlug]['dataToSend'] = JSON.stringify(parentChildFieldCominationsObject);
                }

            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.buildBlocListLatestData = async function (fieldSlug, filteringOptions = null) {
        try {
            if (typeof vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != 'undefined') {

                let stationIdsToCheck = [];

                angular.forEach(vm.dataToFormStations[fieldSlug], function (station) {
                    stationIdsToCheck.push(station.id);
                });

                let allFieldsData = vm.getAllFieldsData();

                let blocListLatestData = await scanStation.buildBlocListLatestData(vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug].id, stationIdsToCheck, filteringOptions, allFieldsData);

                console.log(blocListLatestData.data.data);
                angular.forEach(vm.dataToFormStations[fieldSlug], function (station) {
                    station.latestData = blocListLatestData.data.data[station.id];
                });

            }

            // if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }


    vm.dataToFormCheckLinkedStation = async function (dataToCheck, fieldToCheck, fieldToWatch, linkedStation, dataToFormFieldSlug, jobID) {
        try {

            let filteringOptions = setSubFormFiltering(linkedStation, fieldToCheck, dataToCheck);
            let data = await scanStation.dataToFormCheckLinkedStation(filteringOptions, fieldToWatch, linkedStation, jobID);

            vm.dataToFormStations[dataToFormFieldSlug] = data.data.data;

            if (vm.scanStationObj.scanStationFieldSlugsToField[dataToFormFieldSlug].formListLatestDataSlugs != null) {
                await vm.buildBlocListLatestData(dataToFormFieldSlug, filteringOptions);
                if (!$scope.$$phase) {$scope.$applyAsync();};
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * This method sends data to the Bartender Controller in order to drop a file to trigger a print
     * on Bartender (Using Bartender Integration
     * @return true if successful, otherwise false
     **/
    vm.generateFileForBartender = async function (fieldSlug) {
        try {

            if (vm.scanStationObj.bartenderFieldActionsEnabled != true) {
                return;
            }

            let folderToDropInto = '';
            let fileNameToCreate = '';
            let fieldValuesToSend = {};

            let bartenderField = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];

            angular.forEach(vm.scanStationObj.scanStationFields, function (field) {
                if (field.field_slug == fieldSlug && field.field_type == 'bartender-file-generator') {
                    folderToDropInto = field.bartenderSftpUserName;
                    fileNameToCreate = field.bartenderFileName;
                    let bartenderFieldSlugs = field.bartenderFieldSlugs.split(',');
                    angular.forEach(bartenderFieldSlugs, function (fieldSlug) {
                        fieldValuesToSend[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlug];
                    });
                }
            });

            if (folderToDropInto === '') {
                // TODO: implement an exception that will show a specific window
                return false;
            }

            if (bartenderField.sftpOrBrowser == 'browser-download') {
                let textFileJson = JSON.stringify(fieldValuesToSend);
                let blob = new Blob([textFileJson], {type: "text/plain;charset=utf-8"});
                saveAs(blob, fileNameToCreate);
            } else {
                await scanStation.generateFileForBartender(folderToDropInto, fileNameToCreate, fieldValuesToSend);
                alert('Data has been sent to printer, please collect your label');
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};
            return true;
        } catch (exception) {
            console.log(exception)
            alert('Something went wrong with the print, please contact suppport@blocworx.com');
            return false;
        }
    }

    vm.getMergeFieldSlugs = function (fieldMergeFormat) {
        try {
            let mergedFieldSlugs = [];

            // for the cases that we do not have the Field Merge Format
            if (fieldMergeFormat == undefined) {
                return mergedFieldSlugs
            }

            let splitString = fieldMergeFormat.split('[BW]');
            angular.forEach(splitString, function (value, index) {
                if (index > 0) {
                    let remove_after = value.indexOf('[/BW]');
                    mergedFieldSlugs.push(value.substring(0, remove_after));
                }
            });

            if (!$scope.$$phase) {$scope.$applyAsync();};
            return mergedFieldSlugs;

        } catch (e) {
            console.log(e);
        }
    }

    vm.detectFieldAndBuildMerge = function (fieldSlug) {
        try {
            angular.forEach(vm.scanStationObj.fieldsUsedForMergeField, function (fieldsUsedAndFormat, mergedFieldSlug) {
                if (fieldsUsedAndFormat[1].includes(fieldSlug)) {
                    let newString = fieldsUsedAndFormat[0];

                    // for autogenerate fields other values may have changed so we cannot used the default string set
                    if (vm.scanStationObj.scanStationFieldSlugsToField[mergedFieldSlug].field_type == 'auto-generate-custom') {
                        newString = vm.scanStationObj.dataToAdd[mergedFieldSlug];
                    }

                    angular.forEach(fieldsUsedAndFormat[1], function (singleFieldSlug) {
                        let replacementValue = '';
                        if (typeof (vm.scanStationObj.dataToAdd[singleFieldSlug]) != 'undefined') {
                            replacementValue = vm.scanStationObj.dataToAdd[singleFieldSlug]
                        }
                        newString = newString.replace(singleFieldSlug, replacementValue);

                    });
                    newString = newString.replaceAll("[BW]", "");
                    newString = newString.replaceAll('[/BW]', '');
                    vm.scanStationObj.dataToAdd[mergedFieldSlug] = newString;
                }
            });
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }

    }

    vm.uploadMultipleFiles = async function (fieldSlug) {
        try {
            let files = vm.scanStationObj.multiFilesToAdd[fieldSlug];
            vm.pleasewait = true;

            let data = await scanStation.uploadMultipleFiles(vm.jobID, vm.id, files, fieldSlug);

            if (typeof (vm.scanStationObj.dataToAdd[fieldSlug]) == 'undefined' || vm.scanStationObj.dataToAdd[fieldSlug] == 'N/A') {
                vm.scanStationObj.dataToAdd[fieldSlug] = '';
            } else {
                // if they have already uploaded files and are editing then we need to add a comma to seperate the existing files from new ones
                vm.scanStationObj.dataToAdd[fieldSlug] += ',';
            }

            vm.scanStationObj.dataToAdd[fieldSlug] += data.data.data;
            //vm.multiFilesForDisplay[fieldSlug] = await scanStation.loadMultiFilesForDisplayInForm(vm.scanStationObj.dataToAdd[fieldSlug])
            alert('Files uploaded');
            vm.pleasewait = false;
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);

            if ((e.data != null && e.data.error != null)) {
                alert(e.data.error)
            }

            vm.pleasewait = false;
        }

    };

    vm.startWatchForMultiFileField = function (fieldSlug) {
        $scope.$watch(function () {
            if (vm.scanStationObj.dataToAdd[fieldSlug] != null) {
                return vm.scanStationObj.dataToAdd[fieldSlug];
            }
        }, function (newValue, oldValue) {
            if (vm.scanStationObj.dataToAdd[fieldSlug] != null) {
                if (newValue !== oldValue) {
                    if (vm.multiFileArray == null) {
                        vm.multiFileArray = [];
                    }
                    vm.multiFileArray[fieldSlug] = vm.scanStationObj.dataToAdd[fieldSlug].split(',')
                        .filter(function (element: string): boolean {
                            return element.trim() !== ''; // remove empty elements
                        });
                }
            }
        });
    }

    /**
     * This will be responsible to update all checkbox data after click on the show more
     * button.
     */
    vm.cleanCheckboxFromStation = (scanStationObj) => {

        // TODO: Ask adrian what is the parameter to not clean the data
        scanStationObj.dataToAdd.checkboxData = "";
        scanStationObj.dataToAdd.checkboxFromStation = {};
        scanStationObj.isInViewMode = true;
    }


    /**
     * This function is triggered when we have an option to trigger graph on the:
     * Edit Mode -> Parameters -> Tick this box to trigger graph updates on the page
     * @param fieldSlug
     */
    vm.triggerChartUpdates = async function (fieldSlug) {
        try {
            // This is for the case when we are on edit mode, we just need to find a field to trigger
            // that is the way to get one of the fields slug to trigger the action.
            if (Array.isArray(fieldSlug)) {
                fieldSlug = fieldSlug.find(x => x !== undefined);
                if (typeof fieldSlug['field_slug'] !== 'undefined') {
                    fieldSlug = fieldSlug['field_slug'];
                } else {
                    fieldSlug = '';
                }
            }
            // TODO: check if needs the await before the async angular.forEach
            let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];

            angular.forEach(field.parameters, async function (fieldParameter) {
                // if we have the triggerGraph or value its the first one
                if (fieldParameter.scan_station_field_parameter == 'triggerGraphUpdate' && fieldParameter.parameter_value == '1') {
                    // trigger pie charts
                    angular.forEach(vm.scanStationObj.scanStationFields, async function (fieldForCharts) {
                        if (fieldForCharts.field_type == 'pie-chart') {
                            let withLabel = true;
                            // TODO: check if this works without the timeout. maybe this is runing in the wrong time
                            await PieChartField.buildPieChart(fieldForCharts, vm.scanStationObj.dataToAdd, withLabel, vm.scanStationObj.scanStationFieldIdsToField);
                            if (!$scope.$$phase) {$scope.$applyAsync();};
                        }
                    });

                    // trigger echarts
                    $('.echart-button-refresh-data').each(function () {
                        $(this).trigger('click');
                    });
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    /*
    Method for adding multiple field show conditions, i.e. other fields must equal certain values for this field to appear
     */

    vm.addMultiFieldShowConditions = async function () {
        if (typeof (vm.multiFieldShowConditions) == 'undefined') {
            vm.multiFieldShowConditions = [];
            vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = [];
        } else if (typeof (vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules]) == 'undefined') {
            vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = [];
        }

        vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules] = Object.values(vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules]);

        vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules].push(
            {
                localFieldId: vm.selectedMultiShowConditionField,
                valueItMustEqual: vm.multiShowConditionValue,
            }
        );

        delete vm.selectedMultiShowConditionField;
        delete vm.multiShowConditionValue;
        await vm.setScanStationFieldParameter(vm.fieldIDForUpdatingRules, 'multiFieldShowConditions', vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules], 'force-array-to-json');

        if (!$scope.$$phase) {$scope.$applyAsync();};

    }

    vm.removeMultiFieldShowCondition = async function (index) {

        delete vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules][index];
        await vm.setScanStationFieldParameter(vm.fieldIDForUpdatingRules, 'multiFieldShowConditions', vm.multiFieldShowConditions[vm.fieldIDForUpdatingRules], 'force-array-to-json');

    }

    vm.startDigitalSignature = function (fieldSlug) {
        setTimeout(function () {
            // TODO remove timeout
            let canvas = document.getElementById("signature-pad-" + fieldSlug);
            if (typeof vm.signaturePad == 'undefined') {
                vm.signaturePad = [];
            }

            if(canvas != null) {
                vm.signaturePad[fieldSlug] = new SignaturePad(canvas);
                // Rebinds all event handlers
                vm.signaturePad[fieldSlug].on();
            }

        }, 300);

    }

    vm.addDigitalSignature = async function (fieldSlug) {
        const data = vm.signaturePad[fieldSlug].toDataURL('image/png');
        vm.scanStationObj.dataToAdd[fieldSlug] = data;
    };

    /**
     * Method that will return the status in a text format
     * @returns {boolean}
     */

    vm.scanStationFormContainerValidate = function () {

        // Refactored the validation statement
        let validation1 = (vm.scanStationObj.requires_anti_static == 1 && vm.antiStaticSignedForUser != null);
        let validation2 = (validation1 || vm.scanStationObj.requires_anti_static == null || vm.scanStationObj.requires_anti_static == 0)
        let validation3 = ((vm.scanStationObj.selectedSOPFiles.length == 0) || (vm.scanStationObj.selectedSOPFiles.length > 0 && vm.SOPSignedForUser != null));

        return (validation2 && validation3);
    }

    vm.setNumericField = async function (fieldId, fieldSlug, data, fieldIndex) {

        if (vm.scanStationObj.scanStationFieldIdsToField[fieldId].numericField != null && vm.scanStationObj.scanStationFieldIdsToField[fieldId].numericField == 1) {
            let decimalPlaces = 2;
            if (vm.scanStationObj.scanStationFieldIdsToField[fieldId].numericFieldDecimalPlaces != null) {
                decimalPlaces = vm.scanStationObj.scanStationFieldIdsToField[fieldId].numericFieldDecimalPlaces;
            }
            let number = parseFloat(data);
            if (isNaN(number)) {
                $('#station-field-' + fieldIndex).addClass('minor-issue');
                return true;
            } else {
                vm.scanStationObj.dataToAdd[fieldSlug] = parseFloat(number.toFixed(decimalPlaces));
            }

            return true;

        } else {
            return true;
        }
    }

    vm.getRolesAndPermissions = async function () {
        try {
            let data = await RolesAndPermissionsFactory.getRolesAndPermissions();
            vm.rolesAndPermissions = data.data.data;
            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            console.log(e);
        }
    }

    /**
     * This will be responsible to update roles from the table
     * station_role.
     */
    vm.updateStationRolesAndPermissions = async function () {
        try {
            // this will update the table station_role
            let data = await RolesAndPermissionsFactory.updateStationRoles(vm.scanStationObj.stationDetails.role_groups, vm.id);

            // this will update the scan station
            await vm.updateScanStation('non-fields-related');

            // just double-checking that we have message before print
            if (data?.data?.message) {
                await vm.triggerBlocUpdateSuccess(data.data.message);
            }

            if (!$scope.$$phase) {$scope.$applyAsync();};

        } catch (e) {
            await vm.triggerBlocError(e?.data?.error);
        }
    }

    /**
     * This will be responsile to remove the role from the table
     * station_role.
     * @param roleId
     */
    vm.removeRoleFromExistingStation = async function (roleId) {

        // checking the index of the role that we will remove
        let index = vm.scanStationObj.stationDetails.role_groups.indexOf(roleId);

        // removing from the stationDetails this specific role id
        delete vm.scanStationObj.stationDetails.role_groups[index];

        // update of the data on station_role
        await vm.updateStationRolesAndPermissions();
    }

    vm.sendIframeType = async function (iframeType) {
        $rootScope.$broadcast('iframeType', iframeType);
    }

    $scope.iframeType = $scope.$on('iframeType', async function (event, iframeType) {
        //$('.is-in-iframe').addClass(iframeType);
    });

    vm.setBlocMessage = async function (message) {
        vm.blocUpdateMessage = message;
        return true;
    }

    /**
     * This is the bloc update success on edit scan station.
     * @param message
     */
    vm.triggerBlocUpdateSuccess = function (message) {
        let successTimer = 2000;

        // cleaning and setting the message
        vm.blocUpdateMessage = message;
        vm.showSuccessMessage = true;

        setTimeout(() => {
            vm.showSuccessMessage = false
        }, successTimer);
    }

    /**
     * This will make available to see the errors in a scan station edit (backend)
     * screen.
     * @param message
     */
    vm.triggerBlocError = function (message) {
        let errorTimer = 2000;

        // cleaning and setting the message
        vm.blocErrorMessage = message;
        vm.showErrorMessage = true;

        setTimeout(() => vm.showErrorMessage = false, errorTimer);
    }

    /**
     * This will be responsible to open the modal.
     */
    vm.openModalForScanStationEdit = (event, scanStation) => {

        // setting the parameters for the callback function on openAlertEdit
        let parameters = {
            scanStationId: scanStation.id,
            scanStationParameter: 'autoArchiveData',
            scanStationParameterValue: scanStation.scanStationObj.stationDetails.autoArchiveData
        }

        // setting the modal configuration for the title and main text.
        let modalConfiguration = {
            title: 'Set Auto Archive',
            text: 'Are you sure you want to continue? At midnight, data will be permanently archived that is older than the number of days you set.'
        }

        // opening the alert
        vm.alertFactory.openAlertEdit(event, scanStation
            , modalConfiguration
            , parameters
            , scanStation.setScanStationArchivedParameter
        );
    }

    vm.filterFieldsLocked = function (fieldId, userRoles) {

        let isFilterByField = (vm.scanStationObj.scanStationFieldIdsToField[fieldId] != null && (vm.scanStationObj.scanStationFieldIdsToField[fieldId].filterByField == 1 || vm.scanStationObj.scanStationFieldIdsToField[fieldId].filterByFieldSubBloc == 1));
        let cannotOverrideLockedFields = (!userRoles.includes('admin') && !userRoles.includes('admin-front-end') && !userRoles.includes('can-unlock-filtered-fields'));
        let hasLockedStationSetting = (vm.scanStationObj.stationDetails.force_data_filtered_fields === 1);
        return (isFilterByField && cannotOverrideLockedFields && hasLockedStationSetting);
    }

    /**
     * This will return what class should be added to the main div inner-standard-box-wrapper
     */
    vm.getScanStationDataClass = (customizedLayout) => {
        try {

            // this is the main class for the 100% block
            let classname = 'custom-compact'

            if (customizedLayout) {
                // this is the classname when we have a small space
                classname = 'custom-full';
            }

            return classname;

        } catch (e) {
            console.log(e);
        }
    }

    vm.triggerGetDataFiltering = async function (e) {
        if (e.keyCode === 13 || e.keyCode === 9 || e.type === 'click' || e.fromUrl === true) {

            vm.filterData = true;
            if (vm.blocworxFreeSearch != '' && vm.blocworxFreeSearch != null) {
                vm.addFilterData('blocworx_free_search', vm.blocworxFreeSearch);
            }

            vm.dataFilteringData.origin = 'bloc';

            await vm.getDataForDataFiltering('with-filter', vm.dataFilteringData);

            vm.blocworxFreeSearch = '';
            if (!$scope.$$phase) {$scope.$applyAsync();};
        }
    }

    /*
    This method checks if we have the show more viewing set in the url for the bloc state
    its mainly used for sub blocs so we can decide if the person can edit things or not
     */

    vm.isShowMoreViewing = function () {
        let showMoreViewing = $state.params.showMoreViewing;
        let showMoreResult = (showMoreViewing == 'true' || showMoreViewing === true);
        return showMoreResult;
    }

    vm.updatePerPagePagination = async function () {
        vm.currentPage = 1;

        await vm.updateEnterDataController(vm.resultLimit, 'updateResultLimit');

        (vm.dataFilteringData.filterDataToQuery != null && !$.isEmptyObject(vm.dataFilteringData.filterDataToQuery)) ? await vm.getDataForDataFiltering('with-filter', vm.dataFilteringData) : await vm.getData(vm.scanStationObj.id)
        if (!$scope.$$phase) {$scope.$applyAsync();};

    }

    /**
     * This will be responsible to load or not a class for the current data, that can be having
     * a rules:
     * mobile-off = this wont be showing on mobile
     * tablet-off = this wont be showing on tablet
     */
    vm.mobileAndTabletRules = (field) => {

        let classesToAdd = [];

        if (field !== undefined && field.hideFromMobile !== undefined && field.hideFromMobile == 1) {
            classesToAdd = [...classesToAdd, 'mobile-off']
        }

        if (field !== undefined && field.hideFromTablet !== undefined && field.hideFromTablet == 1) {
            classesToAdd = [...classesToAdd, 'tablet-off']
        }

        return classesToAdd.length == 0 ? '' : classesToAdd.join(' ');
    }

    vm.goBack = function () {
        window.history.back();
    }

    vm.initialiseCheckboxValue = function (fieldSlug) {
        let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
        if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = field.falseValue;
        }
    }

    vm.initialiseDefaultButtonValue = function (fieldSlug) {
        let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
        if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = field.defaultButtonValue;
        }
    }


    /**
     * Initialization of the dropdown field.
     *
     * @param field
     */
    vm.startDropdownField = async function (field) {

        // this will start the dom Indexes,
        vm.domIndexes[field.field_slug] = field.fieldIndex;

        // this will trigger the initialization of the default value
        await vm.loadDefaultValue(field.field_slug);
    }

    /**
     * This will load the default value of the field.
     *
     * @param fieldSlug
     */
    vm.loadDefaultValue = async function (fieldSlug) {
        if (vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug] != null) {

            let field = vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
            if (vm.scanStationObj.dataToAdd[fieldSlug] == null) {
                vm.scanStationObj.dataToAdd[fieldSlug] = field.defaultValue;
            }

            // FIXME: sometimes this get rules is not existent, as this is loaded on the Field Actions controller
            if (field.applyRulesOnInitiation == 1) {

                // I am adding this to be a timer and indicator of this issue, some cases the get rules are not prepared, for those
                // cases we will be having a console.log saying that was trying to load something that isn't existent
                while (typeof vm.getRules !== 'function') {
                    console.log("While trying to find the getRules()")
                }

                // we will be only running if we know that is a existent function
                if (typeof vm.getRules === 'function') {
                    await vm.getRules('after-submission', field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);
                } else {
                    console.log("Tried to call vm.getRules() but does not exist the function yet")
                }
            }
        }
    }

    /**
     * This is validating the standard form that shows with
     * all the fields from a scan station.
     */
    vm.validateStandardForm = () => {
        let valid = false;

        // if(vm.scanStationObj.showForm && vm.showMoreSingleDataBox != true){
        if (vm.scanStationObj.showForm) {
            valid = true;
        }

        return valid;
    }

    /**
     * This is responsible to remove the modal class on the body,
     * so we can avoid to scroll down when we are on a modal on
     * edit field.
     */
    vm.modalOff = () => {
        vm.modalStatus = 'off';
        $('body').removeClass('modal-on').addClass("modal-off");

        // if we have set update id, we should show it after close the popup
        if (vm.fieldIDForUpdatingRules !== null) {
            $('.show-field-rule-parameters').show();
        }
    }

    /**
     * This is responsible to remove the modal class on the body,
     * so we can avoid to scroll down when we are on a modal on
     * edit field.
     */
    vm.modalOn = () => {
        vm.modalStatus = 'on'
        $('body').removeClass('modal-off').addClass("modal-on");

        // if we have set update id, we should hide it so on ModdalOff can be showing again
        if (vm.fieldIDForUpdatingRules !== null) {
            $('.field-rules > .close-popup').trigger("click");
            $('.show-field-rule-parameters').hide();
        }
    }


    /**
     * This adds conditional for a field to search.
     *
     * @param fieldSlug
     * @param searchValue
     * @param event
     */
    vm.addConditionForFieldSearch = async function (fieldSlug, searchValue, event) {
        vm.addFilterData(fieldSlug, searchValue);
        vm.filterData = true;
        vm.specificFieldSearch = true;
        vm.currentPage = 1;
        vm.dataFilteringData.currentPage = 1;
        await vm.triggerGetDataFiltering(event);
    }

    /**
     * This will be getting a string and it will transform into
     * a slug.
     *
     * @param fieldName
     */
    vm.getFieldSlug = (fieldName: string) => {

        // check if we have some value
        if (fieldName == null) {
            return false
        }

        // step 1 : transform the field name to lower case and trim the value
        let fieldSlug = fieldName.toLowerCase().trim();

        // step 2 : replace space for _
        fieldSlug = fieldSlug.replace(/\s/g, '_');

        // step 3: adding slug before the field-slug, so .123456 will be .slug-123456
        if (fieldSlug != undefined && fieldSlug != '') {
            fieldSlug = `slug-${fieldSlug}`;

        }

        return fieldSlug;
    }

    /**
     Determines if we can show the astericks on a field or not for cannot be empty rules
     */
    vm.canShowAstericks = function (hasCannotBeEmptyRule, field) {
        if (vm.scanStationObj.stationDetails.hideAstericks != 1 && hasCannotBeEmptyRule == 1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     This function decides if we will show the reverse data tickbox along with whether it should be loading filtered data or not
     */
    vm.showFilteredReverseTickBox = function () {

        if (vm.filterDataOptions == null || (vm.filterDataOptions != null && vm.filterDataOptions.filterDataToQuery == null) || (vm.filterDataOptions.filterDataToQuery == null != null && vm.filterDataOptions.filterDataToQuery.length == 0)) {
            return 'get-data';
        } else {
            return 'get-filtered-data';
        }

    }

    /*
    This function gets a generic list of days and hours etc
     */
    vm.getHoursAndDays = async function () {
        vm.frequencyHours = await ScanStationHelperFactory.createHourArray();
        vm.frequencyDays = ScanStationHelperFactory.createDayArray();
        if (!$scope.$$phase) {$scope.$applyAsync();};
    }

    /**
     * Initialize the Autogenerated field with full timestamp to avoid submit the field without a value
     *
     * @param fieldType
     */
    vm.initAutoGeneratedField = function (fieldType) {
        if (fieldType == 'auto-generate') {
            vm.newField.field_type = 'auto-generate-full-timestamp'
        }
    }

    /**
     Method to trigger all the autocomplete actions which can eventually be moved to a component
     */
    vm.triggerAutoCompleteActions = async function (field, action, e, selectedItem, keyPressType) {

        if (selectedItem !== null) {
            vm.scanStationObj.dataToAdd[field.field_slug] = selectedItem;
        }

        // We need to use keyPressType because JS behaves one way with ng-keydown and another with ng-keyup and we need both for different circumstances
        if (action == 'continue-search' && keyPressType == 'keyup') {
            vm.getSearchData(field.field_slug, vm.scanStationObj.dataToAdd[field.field_slug]);
        }

        if (action == 'complete-search' || keyPressType == 'keydown') {
            vm.searchResult[field.field_slug] = null;
            await vm.triggerBackgroundDataUpdate(vm.scanStationObj.dataToAdd, vm.scanStationObj.id, vm.scanStationObj.dataToAdd[field.field_slug]);
            await vm.getRules(e, field.id, field.special_field_key, vm.scanStationObj.dataToAdd[field.field_slug], field.fieldIndex, field.field_slug);

        }

    }

    /**
     * Checks to see if we are allowed to show the data results in a bloc, admins should be able to see it if show_data_resuts_for_those_allowed is turned on
     * @param main
     * @returns {boolean}
     */
    vm.canSeeHiddenDataSectionInBloc = (main) => {
        return (vm.scanStationObj.stationDetails.data_results_layout != 'none'
            || (vm.scanStationObj.stationDetails.show_data_resuts_for_those_allowed == 1 && (
                    main.userRoles.includes('can-see-hidden-data-section-in-bloc')
                    || main.userRoles.includes('admin-front-end')
                    || main.userRoles.includes('admin'))
            )
        );
    }

    /**
     * This function determines if we should see the table results or not
     * @param main
     * @returns {boolean | any}
     */
    vm.showDefaultTableResults = (main) => {
        return (vm.scanStationObj.stationDetails.data_results_layout == 'table' || vm.canSeeHiddenDataSectionInBloc(main))
    }

    /**
     * Method that will check if it is in Viewing Mode.
     *
     * @returns {boolean} true for viewing mode, false for in edit mode
     */
    vm.isViewMode = function () {
        try {
            if (!vm.scanStationObj.isInEditMode && vm.showMoreSingleDataBox == true) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }

    }

    /*
     * This will be getting the preview SRC for the request on
     * /api/file-preview/
     * @param fieldType
     */
    vm.getFilePreviewSrc = (field) => {
        try {
            let previewSrc = '';
            if (field != undefined && field.field_slug != undefined && field.externalFileLookUp != undefined && vm.tokenRef != undefined
                && vm.scanStationObj != undefined && vm.scanStationObj.dataToAdd != undefined && vm.scanStationObj.dataToAdd[field.field_slug] != undefined) {
                let externalFileLookup = field.externalFileLookUp.externalFileModuleId;
                let externalFileStationId = field.externalFileLookUp.externalFileStationId;
                let dataToAdd = vm.scanStationObj.dataToAdd[field.field_slug];
                previewSrc = `/api/file-preview/${externalFileLookup}/${externalFileStationId}/${dataToAdd}/${vm.tokenRef}`;
            }
            return previewSrc;
        } catch (e) {
            console.log(e)
        }
    }


    /**
     * This will run manually the command archiverecords
     * this will be working only for super_user users.
     * @param resultsBoxField
     * @param fieldSlug
     */
    vm.runArchiveRecords = async (resultsBoxField, fieldSlug) => {
        await vm.scanStationService.runArchiveRecords();
        if (!$scope.$$phase) {$scope.$applyAsync();};

    }

    /**
     * Method that will check if it is in Edit Mode.
     * @returns {boolean} true for in edit mode, true for viewing mode
     */
    vm.isEditOrViewMode = function () {
        try {
            if (vm.scanStationObj.isInEditMode || vm.showMoreSingleDataBox == true) {
                return true;
            }

            return false;

        } catch (e) {
            console.log(e);
        }
    }


    /**
     * This checks if a file name is an image type or not
     *
     * @param row
     * @param field
     */
    vm.isFileNameImageType = (fileName) => {

        let isImage = false;
        let fileTypes = ['.tif', '.tiff', '.bmp', '.jpg', '.jpeg', '.gif', '.png', '.eps', '.raw', '.nef', 'heic'];

        // for N/A Cases and cases that we can have row[field.field_slug] == undefined
        if (fileName == 'N/A' || fileName == false || fileName == '' || fileName == null || fileName == 'null') {
            return false;
        }

        // transforming into lower case
        fileName = fileName.toLowerCase();

        for (let index = 0; index < fileTypes.length - 1; index++) {
            if (fileName.includes(fileTypes[index])) {
                isImage = true;
                break;
            }
        }

        return isImage;
    }

    /**
     * This function hides the data results under certain conditions based on the bloc setting
     * "In Full Screen mode, hide data when form is open."
     */
    vm.hideDataWhenFormIsOpenInFullScreen = () => {

        // we never hide data when not in full screen (use_customised_layout is the name of this setting, although it might seem unclear)
        if (vm.scanStationObj.stationDetails.use_customised_layout == null || vm.scanStationObj.stationDetails.use_customised_layout == 0) {
            return false;
        }

        if (vm.scanStationObj.stationDetails.use_customised_layout == 1 && vm.scanStationObj.stationDetails.hideDataWhenFormIsOpen == 1 && vm.scanStationObj.showForm == 1) {
            return true;
        }

        return false;
    }

    /**
     * It deletes a specified file from dataToAdd.
     * Removes the filename from an array if found.
     *
     * @param {string} fieldSlug - The key value in the dataToAdd object where one or more filenames (separated by commas) can be found.
     * @param {string} filename - The name of the file to be removed.
     *
     * @method vm.deleteFile
     *
     * @returns {void} This method does not return a value but alters the dataToAdd object by removing instances of the specified filename.
     **/
    vm.deleteFile = function (fieldSlug: string, filename: string): void {

        for (const key in vm.scanStationObj.dataToAdd) {

            if (Array.isArray(vm.scanStationObj.dataToAdd[key])) {
                const indexToRemove = vm.scanStationObj.dataToAdd[key].indexOf(filename);
                if (indexToRemove != -1) {
                    vm.scanStationObj.dataToAdd[key].splice(indexToRemove, 1);
                }
            }

            if (key == fieldSlug) {
                const fieldArray = vm.scanStationObj.dataToAdd[key].split(',');
                const indexToRemove = fieldArray.indexOf(filename);
                if (indexToRemove != -1) {
                    fieldArray.splice(indexToRemove, 1);
                    vm.scanStationObj.dataToAdd[key] = fieldArray.join(',');
                }
            }
        }
    }

    /**
     * This duplicates the record by scan station data id (or can be known as recordId)
     * @param recordId
     */
    vm.duplicateRecord = async (recordId) => {

        try {
            await scanStation.duplicateRecord(recordId);
            alert('The Record has now been duplicated, you will see it available in the records');

            if (vm.filterData) {
                await vm.getDataForDataFiltering('with-filter', vm.filterDataOptions);
            } else {
                await vm.getData(vm.id);
            }

        } catch (res) {
            console.log(res);
        }
    }

    // Step 1: Extract Custom Tags
    vm.extractCustomTags = function(html) {
        const regex = /<field[^>]*field-slug="([^"]+)"[^>]*><\/field>/g;
        let matches;
        const fields = [];

        while ((matches = regex.exec(html)) !== null) {
            fields.push(matches[1]); // Capture the field-slug value
        }

        return fields;
    };

    // Step 2: Fetch Field Object
    vm.getFieldBySlug = function(fieldSlug) {
        return vm.scanStationObj.scanStationFieldSlugsToField[fieldSlug];
    };

    vm.compileCustomHtml = async function() {
        const customHtml = vm.scanStationObj.stationDetails.customBlocHtml;

        // Extract custom tags
        const fields = vm.extractCustomTags(customHtml);

        // Replace custom tags with components
        let compiledHtml = customHtml;
        vm.customFields = [];
        fields.forEach(fieldSlug => {

            const componentHtml = '<scan-station-field action="fields" fc="fc" scan-station="scanStation" main="main" data="data" field="scanStation.scanStationObj.scanStationFieldSlugsToField[\'' + fieldSlug + '\']"></scan-station-field>';
            compiledHtml = compiledHtml.replace(`<field field-slug="${fieldSlug}"></field>`, componentHtml);

        });

        // Compile the HTML
        const compiledElement = $compile(compiledHtml)($scope);

        // Insert the compiled HTML into the DOM
        angular.element(document.getElementById('custom-html-element')).append(compiledElement);

        // we need to call this again
        await AngularScanStationService.callGetScanStationInformationComponentFunction(vm.scanStationObj.id, 'main-query');

    };

    /**
     * This gets logged in user field details
     * TODO to be removed when we refactor this field
     * @param field
     */
    vm.loadUserDetails = (field) => {

        if(vm.scanStationObj.stateName ==  'edit-bloc') {
            return true;
        }

        if(vm.scanStationObj.isInEditMode == true && field.preventUpdateWhenEditingRecord == '1') {
            return true;
        }

        if(field != null){
            vm.currentField = field;
        }

        vm.domIndexes[vm.currentField.field_slug] = vm.currentField.fieldIndex;
        vm.loggedInUsersName = vm.auth.userData.username;
        vm.loggedInUsersEmail= vm.auth.userData.email

        // getting the correct value from the userData object.

        switch (field.userInfoRequired) {

            case 'full-name': // this will be for the name case
                vm.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.name;
                break;

            default : // this will be for the name case
                vm.scanStationObj.dataToAdd[vm.currentField.field_slug] = vm.auth.userData.email;
                break;

        }


        if (typeof vm.getRules === 'function') {
            vm.getRules(
                'no-event',
                vm.currentField.id,
                vm.currentField.special_field_key,
                vm.scanStationObj.dataToAdd[vm.currentField.field_slug],
                vm.currentField.fieldIndex,
                vm.currentField.field_slug
            );
        }

    }


    vm.setInitialMathsFieldValue = (fieldSlug) => {
        if(vm.scanStationObj.dataToAdd[fieldSlug] == null) {
            vm.scanStationObj.dataToAdd[fieldSlug] = 0;
        }
    }
}
